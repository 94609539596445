import { VehicleListingData } from 'app/apiCalls/vehicle';
import {
  getProviderId,
  isMBFS,
  isSFS,
  isVWFS,
} from 'app/shared/components/FinancingCalculator/providers';
import { useVwfsFCEnabled } from 'app/hooks/featureToggles/vwfsFCEnabled';
import { useMbfsFCEnabled } from 'app/hooks/featureToggles/mbfsFCEnabled';
import { useSantanderFCEnabled } from 'app/hooks/featureToggles/santanderFCEnabled';

const useFinancingEnabled = (vehicle: VehicleListingData) => {
  const providerId = getProviderId(vehicle?.financingPrecalc);

  const vwfsFCEnabled = useVwfsFCEnabled() && isVWFS(providerId);
  const mbfsFCEnabled = useMbfsFCEnabled() && isMBFS(providerId);
  const santanderFCEnabled = useSantanderFCEnabled() && isSFS(providerId);

  return !!(vwfsFCEnabled || mbfsFCEnabled || santanderFCEnabled);
};

export { useFinancingEnabled };
