import { countByParams } from 'app/apiCalls/vehicle';
import { convertQueryParamsForSearchEndpoint } from 'app/containers/CLP/utils';
import { ParsedUrlQuery } from 'querystring';
import { QueryCache } from 'react-query';

interface ICountDataQueryProps {
  query: ParsedUrlQuery;
  from: string;
}

interface ICountPrefetchDataQueryProps extends ICountDataQueryProps {
  queryCache: QueryCache;
}

interface ICountDataQueryResult {
  count: number;
}

const QUERY_NAME = 'counts-data';

const DataQueryFn = async ({
  query,
  from,
}: ICountDataQueryProps): Promise<ICountDataQueryResult> => {
  const { filters } = convertQueryParamsForSearchEndpoint(query, from);
  try {
    const data = await countByParams({ page: 1, filters });
    return { count: data?.pagination?.total || 0 };
  } catch (e) {
    console.error('There was a problem in the Counts dataQuery', e);
    return { count: 0 };
  }
};

const PrefetchQueryFn = async ({
  query,
  queryCache,
  from,
}: ICountPrefetchDataQueryProps): Promise<ICountDataQueryResult> =>
  queryCache.prefetchQuery(
    [QUERY_NAME, query, from],
    () => DataQueryFn({ query, from }),
    DataQueryOptions,
    PrefetchQueryOptions || {},
  );

const DataQueryOptions = {
  staleTime: 60000,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

const PrefetchQueryOptions = {
  throwOnError: true,
};

export default {
  name: QUERY_NAME,
  options: DataQueryOptions,
  fn: DataQueryFn,
  prefetchFn: PrefetchQueryFn,
};
