import { DateTime } from 'luxon';

export function unixTimeSeconds() {
  return DateTime.now().toUnixInteger();
}

export function today() {
  return DateTime.now();
}

export function addDays(dateTime: DateTime, days: number) {
  return dateTime.plus({ days });
}
