import styled from 'styled-components';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { textInputStyle } from 'app/styles/mixins';
import { baseStyles } from 'app/shared/components/TextInput/TextInput.css';

interface IPhoneInputStProps {
  error?: Error;
  hasUnit?: boolean;
  hideArrow?: boolean;
}

export const PhoneInputSt = styled(PhoneInputWithCountry).withConfig({
  shouldForwardProp: prop => prop !== 'hideArrow',
})<IPhoneInputStProps>`
  ${textInputStyle(baseStyles)}
  --PhoneInputCountrySelectArrow-color: ${({ theme }) =>
    theme.colors.neutral800};

  padding-right: 0;

  .PhoneInputCountrySelectArrow {
    display: ${({ hideArrow }) => (hideArrow ? 'none' : null)};
  }

  .PhoneInputInput {
    background: transparent !important;
  }
`;
