import { validateLicensePlate } from 'app/utils/licensePlate';

export type QuestionIdStringMap = { [key in EnrichmentQuestionId]?: string };

export enum EnrichmentQuestionId {
  V1_WHEN_IS_IT_PLANNED_TO_BUY_THE_CAR = 'V1_WHEN_IS_IT_PLANNED_TO_BUY_THE_CAR',
  V1_INTERESTED_IN_FINANCIAL_INFO = 'V1_INTERESTED_IN_FINANCIAL_INFO',
  V1_INTERESTED_IN_SIMILAR_MODELS = 'V1_INTERESTED_IN_SIMILAR_MODELS',
  V1_INTERESTED_IN_TRADE_IN = 'V1_INTERESTED_IN_TRADE_IN',
  V1_REGISTRATION_PLATE_FOR_TRADE_IN = 'V1_REGISTRATION_PLATE_FOR_TRADE_IN',
  V2_MILEAGE_FOR_TRADE_IN = 'V2_MILEAGE_FOR_TRADE_IN',
}

export const EnrichmentQuestionEvents: QuestionIdStringMap = {
  [EnrichmentQuestionId.V1_WHEN_IS_IT_PLANNED_TO_BUY_THE_CAR]:
    'buy_vehicle_question_answer_button_click',
  [EnrichmentQuestionId.V1_INTERESTED_IN_FINANCIAL_INFO]:
    'financing_options_question_answer_button_click',
  [EnrichmentQuestionId.V1_INTERESTED_IN_SIMILAR_MODELS]:
    'interest_similar_models_question_answer_button_click',
  [EnrichmentQuestionId.V1_INTERESTED_IN_TRADE_IN]:
    'trade_vehicle_question_answer_button_click',
  [EnrichmentQuestionId.V1_REGISTRATION_PLATE_FOR_TRADE_IN]:
    'license_plate_question_answer_button_click',
  [EnrichmentQuestionId.V2_MILEAGE_FOR_TRADE_IN]:
    'mileage_question_answer_button_click',
};

export enum QuestionTypes {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  DOUBLE_TEXT = 'DOUBLE_TEXT',
}

export interface IMultipleChoiceQuestion {
  id: EnrichmentQuestionId;
  type: QuestionTypes.MULTIPLE_CHOICE;
  text: string;
  answers: string[];
  condition?: (answers: QuestionIdStringMap) => boolean;
  skipAnswer?: string;
}

export interface IValidation {
  name: string;
  fn: (value) => boolean;
  error: string;
}

interface IDoubleTextQuestionInput {
  inputLabel: string;
  validations?: Array<IValidation>;
  skipAnswer?: string;
  unit?: string;
  inputMode?: 'text' | 'numeric' | 'search' | 'none' | 'tel' | 'url';
  maxLength?: number;
  pattern?: string;
}

export interface IDoubleTextQuestion {
  id: EnrichmentQuestionId;
  secondId: EnrichmentQuestionId;
  type: QuestionTypes.DOUBLE_TEXT;
  text: string;
  firstInput: IDoubleTextQuestionInput;
  secondInput: IDoubleTextQuestionInput;
  buttonText: string;
  condition?: (answers: QuestionIdStringMap) => boolean;
}

export const ANSWERS_MULTIPLE_CHOICE = {
  thisMonth: 'lead.enrichment.answers.thisMonth',
  oneToThreeMonths: 'lead.enrichment.answers.oneToThreeMonths',
  threeToSixMonths: 'lead.enrichment.answers.threeToSixMonths',
  yes: 'lead.enrichment.answers.yes',
  no: 'lead.enrichment.answers.no',
};

export const ANSWERS_MULTIPLE_CHOICE_TRACK_VALUE = {
  [ANSWERS_MULTIPLE_CHOICE.thisMonth]: 'this_month',
  [ANSWERS_MULTIPLE_CHOICE.oneToThreeMonths]: 'btw_one_three_months',
  [ANSWERS_MULTIPLE_CHOICE.threeToSixMonths]: 'btw_three_six_months',
  [ANSWERS_MULTIPLE_CHOICE.yes]: 'yes',
  [ANSWERS_MULTIPLE_CHOICE.no]: 'no',
};

export const QUESTION_LIST: Array<
  IMultipleChoiceQuestion | IDoubleTextQuestion
> = [
  {
    id: EnrichmentQuestionId.V1_WHEN_IS_IT_PLANNED_TO_BUY_THE_CAR,
    type: QuestionTypes.MULTIPLE_CHOICE,
    text: 'lead.enrichment.questions.whenPlanBuy',
    answers: [
      ANSWERS_MULTIPLE_CHOICE.thisMonth,
      ANSWERS_MULTIPLE_CHOICE.oneToThreeMonths,
      ANSWERS_MULTIPLE_CHOICE.threeToSixMonths,
    ],
  },
  {
    id: EnrichmentQuestionId.V1_INTERESTED_IN_SIMILAR_MODELS,
    type: QuestionTypes.MULTIPLE_CHOICE,
    text: 'lead.enrichment.questions.interestedInSimilarCars',
    answers: [ANSWERS_MULTIPLE_CHOICE.yes, ANSWERS_MULTIPLE_CHOICE.no],
    skipAnswer: ANSWERS_MULTIPLE_CHOICE.yes,
  },
  {
    id: EnrichmentQuestionId.V1_INTERESTED_IN_FINANCIAL_INFO,
    type: QuestionTypes.MULTIPLE_CHOICE,
    text: 'lead.enrichment.questions.interestedInFinancing',
    answers: [ANSWERS_MULTIPLE_CHOICE.yes, ANSWERS_MULTIPLE_CHOICE.no],
    skipAnswer: ANSWERS_MULTIPLE_CHOICE.yes,
  },
  {
    id: EnrichmentQuestionId.V1_INTERESTED_IN_TRADE_IN,
    type: QuestionTypes.MULTIPLE_CHOICE,
    text: 'lead.enrichment.questions.interestedInTradeIn',
    answers: [ANSWERS_MULTIPLE_CHOICE.yes, ANSWERS_MULTIPLE_CHOICE.no],
  },
  {
    id: EnrichmentQuestionId.V1_REGISTRATION_PLATE_FOR_TRADE_IN,
    secondId: EnrichmentQuestionId.V2_MILEAGE_FOR_TRADE_IN,
    type: QuestionTypes.DOUBLE_TEXT,
    text: 'lead.enrichment.questions.plateAndMileage',
    firstInput: {
      inputLabel: 'lead.enrichment.questions.licensePlate.inputLabel',
      validations: [
        {
          name: 'required',
          fn: value => !!value,
          error: 'lead.enrichment.questions.licensePlate.requiredError',
        },
        {
          name: 'validLicensePlate',
          fn: value => validateLicensePlate(value),
          error: 'lead.enrichment.questions.licensePlate.invalidError',
        },
      ],
    },
    secondInput: {
      inputLabel: 'lead.enrichment.questions.mileage.inputLabel',
      validations: [
        {
          name: 'validMileage',
          fn: value => !value || /^[0-9]*$/.test(value.trim()),
          error: 'lead.enrichment.questions.mileage.invalidError',
        },
      ],
      unit: 'KM',
      inputMode: 'numeric',
      maxLength: 9,
      pattern: '\\d*',
    },
    buttonText: 'lead.enrichment.questions.licensePlate.buttonText',

    condition: answerMap =>
      answerMap[EnrichmentQuestionId.V1_INTERESTED_IN_TRADE_IN] ===
      ANSWERS_MULTIPLE_CHOICE.yes,
  },
];
