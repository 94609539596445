import {
  ModalSt,
  AfterLoginContainerSt,
} from 'app/shared/components/LoginRegisterModal/LoginRegisterModal.css';
import { LoginRegisterForm } from './components/LoginRegisterForm/LoginRegisterForm';

export enum AuthView {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
}

interface ILoginRegisterModalProps {
  onClose?: () => void;
  onSuccess?: (a: AuthView) => void;
  loginHeaderIcon?: JSX.Element;
  loginHeaderTitle?: JSX.Element;
  loginHeaderSubTitle?: JSX.Element;
  registerHeaderIcon?: JSX.Element;
  registerHeaderTitle?: JSX.Element;
  registerHeaderSubTitle?: JSX.Element;
  loginButtonText?: JSX.Element;
  registerButtonText?: JSX.Element;
  initialView?: AuthView;
  show?: boolean;
  buttons?: JSX.Element;
}

export const LoginRegisterModal = ({
  onClose = () => {},
  onSuccess = () => {},
  initialView = AuthView.REGISTER,
  show = true,
  buttons,
  ...props
}: ILoginRegisterModalProps) => {
  return (
    <ModalSt onClose={onClose} show={show} width={430} maxHeight={'80vh'}>
      <LoginRegisterForm
        onSuccess={onSuccess}
        initialView={initialView}
        {...props}
      />
      <AfterLoginContainerSt>{buttons}</AfterLoginContainerSt>
    </ModalSt>
  );
};
