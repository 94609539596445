import { ReactElement, useState, useEffect } from 'react';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { useListings } from 'app/providers/RecommendationsListingsProvider';
import SimpleOneClickListings, {
  IListingWithStatus,
} from 'app/shared/components/ContactForm/components/OneClickListings/SimpleOneClickListings';
import { LoadingOverlaySt } from 'app/shared/components/ContactForm/components/OneClickListings/OneClickListings.css';
import { LoadingSpinner } from 'app/shared/components/LoadingSpinner/LoadingSpinner';

interface OneClickListingsProps {
  onRequest: (listing: VehicleListingData) => Promise<VehicleListingData>;
  subHeader?: string;
  newtonRecommendations?: boolean;
}

const OneClickListings = ({
  onRequest,
  subHeader,
  newtonRecommendations,
}: OneClickListingsProps): ReactElement => {
  const [results, setResults] = useState<IListingWithStatus[]>(null);
  const { recommendedList } = useListings();
  const showRecommendations = recommendedList && recommendedList.length > 0;

  useEffect(() => {
    if (!showRecommendations) {
      return;
    }
    if (newtonRecommendations) {
      (async () => {
        const requests = recommendedList.map(listing =>
          onRequest(listing).catch(() => {
            throw listing;
          }),
        );
        const results = await Promise.allSettled(requests);
        setResults(
          results.map(r =>
            r.status === 'fulfilled'
              ? { listing: r.value, requested: true }
              : { listing: r.reason, requested: false },
          ),
        );
      })();
    } else {
      setResults(
        recommendedList.map(listing => ({
          listing,
          requested: false,
        })),
      );
    }
  }, [recommendedList?.length]);

  if (!showRecommendations) {
    return null;
  }

  return !results ? (
    <LoadingOverlaySt>
      <LoadingSpinner type="secondary" />
    </LoadingOverlaySt>
  ) : (
    <SimpleOneClickListings
      vehicles={results}
      onRequest={onRequest}
      translations={newtonRecommendations ? 'newton' : ''}
      subHeader={subHeader}
    />
  );
};

export default OneClickListings;
