import { useEffect, useState } from 'react';
import { countByParams, VehicleListingData } from 'app/apiCalls/vehicle';
import { useQuery } from 'react-query';
import { useTranslate } from 'app/shared/components/Translation/hooks/useTranslate/useTranslate';

interface IUseNotAvailableOverlayProps {
  withNumberOfSimilarCars?: boolean;
  vehicle: VehicleListingData;
}

export interface IUseNotAvailableOverlayResult {
  notAvailableText: JSX.Element | string;
  buttonText: JSX.Element | string;
  similarCarsLink: string;
  showButton: boolean;
}

const useNotAvailableOverlay = ({
  withNumberOfSimilarCars,
  vehicle,
}: IUseNotAvailableOverlayProps): IUseNotAvailableOverlayResult => {
  const translate = useTranslate();
  const [buttonText, setButtonText] = useState('');
  const [notAvailableText, setNotAvailableText] = useState('');
  const [similarCarsLink, setSimilarCarsLink] = useState('');
  const [showButton, setShowButton] = useState(false);

  const { data, isLoading } = useQuery(
    withNumberOfSimilarCars
      ? `fetchCountParamsForMediaGalleryNotAvailable-${vehicle.id}`
      : undefined,
    () =>
      countByParams({
        page: 1,
        size: 1,
        filters: {
          make: vehicle.make.id,
          model: vehicle.model.id,
          booked: false,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 600000,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    const numberOfSimilarCars = isLoading ? 0 : data?.pagination.total;
    const buttonIsShown = withNumberOfSimilarCars && numberOfSimilarCars > 0;
    setShowButton(buttonIsShown);
    setButtonText(
      translate({
        id: 'shared.components.carTileImageGalleryNotAvailable.seeSimilarCars',
        defaultMessage: `See ${numberOfSimilarCars} similar cars`,
        values: {
          numberOfSimilarCars,
        },
      }) as string,
    );
    setNotAvailableText(
      buttonIsShown
        ? (translate({
            id: 'shared.components.carTileImageGalleryNotAvailable.noLongerAvailableWithNumberOfSimilarCars',
            defaultMessage: `The vehicle is no longer available but we've got ${numberOfSimilarCars} similar cars.`,
            values: {
              numberOfSimilarCars,
            },
          }) as string)
        : (translate({
            id: 'shared.components.carTileImageGalleryNotAvailable.noLongerAvailable',
            defaultMessage: `The vehicle is no longer available`,
          }) as string),
    );
  }, [data, isLoading]);

  useEffect(() => {
    setSimilarCarsLink(
      `/coches-segunda-mano/${vehicle.make.id}/${vehicle.model.id}?booked=false`,
    );
  }, [vehicle]);

  return {
    notAvailableText,
    buttonText,
    similarCarsLink,
    showButton,
  };
};

export default useNotAvailableOverlay;
