import styled from 'styled-components';

interface IAnimatedContainerStProps {
  shown: boolean;
}

export const AnimatedContainerSt = styled.div<IAnimatedContainerStProps>`
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  transform: translateY(0);

  animation: fadeInFromNone 0.5s ease-out;

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    0.1% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
