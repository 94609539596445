import { useRouter } from 'next/router';
import { useCallback } from 'react';
import {
  updateQueryParams,
  QueryParamsMap,
  getFirstItemIfArray,
} from './utils';

import { Location } from 'app/apiCalls/location';

export const DEFAULT_RADIUS = 85;
export const MIN_LOCATION_RADIUS = 5;
export const MAX_LOCATION_RADIUS = 1600;

export interface LocationHook {
  location: Location;
  setLocation: (location: Location) => void;
  clearLocation: () => void;
  isLocationVisible: boolean;
}

export const useLocation = (): LocationHook => {
  const router = useRouter();
  const { lat, lon, place, radius }: QueryParamsMap = router.query;

  const setLocation = useCallback(
    ({ lat, lon, place, radius }) => {
      const newQueryParams: QueryParamsMap = {
        lat,
        lon,
        place,
        radius,
      };

      updateQueryParams(router, newQueryParams);
    },
    [router, router.pathname, router.query],
  );

  const clearLocation = useCallback(() => {
    updateQueryParams(router, {}, ['lat', 'lon', 'place', 'radius']);
  }, [router, router.pathname, router.query]);

  const isLocationVisible = !!lat && !!lon && !!place;

  return {
    location: {
      lat: getFirstItemIfArray(lat),
      lon: getFirstItemIfArray(lon),
      place: getFirstItemIfArray(place),
      radius: parseInt(getFirstItemIfArray(radius), 10) || DEFAULT_RADIUS,
    },
    setLocation,
    clearLocation,
    isLocationVisible,
  };
};
