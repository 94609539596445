import { FC } from 'react';
import {
  Typography,
  Variant,
} from 'app/shared/components/Typography/Typography';

const SpanTypography: FC<{
  className?: string;
  variant?: Variant;
  dataTestId?: string;
}> = ({ children, className, variant, dataTestId }) => {
  return (
    <Typography
      className={className}
      variant={variant}
      dataTestId={dataTestId}
      Component="span"
    >
      {children}
    </Typography>
  );
};

export { SpanTypography };
