import { IMAGE_PROXY_URL, PROD_BASE_URL } from 'next-app/app/config';

import { breakpoints } from 'app/styles/variables';

const DEFAULT_MIN_WIDTH = 320; // Smallest mobile screen
const DEFAULT_MAX_WIDTH = breakpoints.xxl; // Biggest desktop screen
const SIZE_INTERVAL = 200;

const RELATIVE_URL_REGEXP = /^\/.*/;

export interface ImageSrcData {
  src: string;
  srcSet?: string;
}

type ImageFilters = Record<string, string | number>;

/**
 * Create the link to cdn in the required format
 * and apply transformations (width, filters)
 */
export const generateResizedUrl = (
  url: string,
  width?: number,
  filters: ImageFilters = {
    quality: 90,
  },
): string => {
  if (!url) {
    return '';
  }

  const domain = RELATIVE_URL_REGEXP.test(url) ? PROD_BASE_URL : '';

  const widthStr = width ? `/${width}x` : '';
  const filtersArr = Object.keys(filters).map(
    filter => `${filter}(${filters[filter]})`,
  );
  const filtersStr = filters ? `/filters:${filtersArr.join(':')}` : '';

  return `${IMAGE_PROXY_URL}/unsafe${widthStr}${filtersStr}/${domain}${url}`;
};

export const generateRetinaUrl = (
  url: string,
  maxWidth = DEFAULT_MAX_WIDTH,
): ImageSrcData => {
  if (!url) {
    return {
      src: undefined,
      srcSet: undefined,
    };
  }
  const src = generateResizedUrl(url, maxWidth);
  const srcSetSizes = [
    `${generateResizedUrl(url, maxWidth)} 1x`,
    `${generateResizedUrl(url, maxWidth * 2)} 2x`,
  ];
  const srcSet = srcSetSizes.join(', ');
  return {
    src,
    srcSet,
  };
};

export const generateResponsiveUrl = (
  url: string,
  maxWidth = DEFAULT_MAX_WIDTH,
  minWidth = DEFAULT_MIN_WIDTH,
  filters?: ImageFilters,
): ImageSrcData => {
  if (!url) {
    return {
      src: undefined,
      srcSet: undefined,
    };
  }
  const srcSetSizes = [];
  const src = generateResizedUrl(url, minWidth, filters);

  // Responsive and max-width sizes
  for (let i = minWidth; i < maxWidth; i += SIZE_INTERVAL) {
    srcSetSizes.push(`${generateResizedUrl(url, i, filters)} ${i}w`);
  }
  srcSetSizes.push(
    `${generateResizedUrl(url, maxWidth, filters)} ${maxWidth}w`,
  );

  const srcSet = srcSetSizes.join(', ');
  return {
    src,
    srcSet,
  };
};
