import Translation from 'app/shared/components/Translation/Translation';

const DefaultLoginHeaderSubTitle = () => {
  return (
    <Translation
      id="auth.loginForm.loginSubtitle"
      defaultMessage="Welcome back to heycar! Please fill your details below to login."
    />
  );
};

export default DefaultLoginHeaderSubTitle;
