import PostalCodePrompterInputModal from 'app/providers/PostalCodePrompterProvider/components/PostalCodePrompterInputModal/PostalCodePrompterInputModal';
import PostalCodePrompterDecisionModal from 'app/providers/PostalCodePrompterProvider/components/PostalCodePrompterDecisionModal/PostalCodePrompterDecisionModal';
import { Status } from 'app/providers/PostalCodePrompterProvider/PostalCodePrompterProvider';

interface IPostalCodePrompterProps {
  onSavePostalCode: Function;
  onClose: () => void;
  onSaveCanaryIslandsPostalCode: Function;
  status: Status;
}

const PostalCodePrompter = ({
  onSavePostalCode,
  onClose,
  onSaveCanaryIslandsPostalCode,
  status,
}: IPostalCodePrompterProps) => {
  if (status === Status.POSTAL_CODE_PROMPTER) {
    return (
      <PostalCodePrompterInputModal
        onSavePostalCode={onSavePostalCode}
        onClose={onClose}
      />
    );
  }

  if (status === Status.POSTAL_CODE_DECISION) {
    return (
      <PostalCodePrompterDecisionModal
        onSaveCanaryIslandsPostalCode={onSaveCanaryIslandsPostalCode}
        onClose={onClose}
      />
    );
  }

  return null;
};

export default PostalCodePrompter;
