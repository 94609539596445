import { ReactElement, useCallback, useState } from 'react';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { ListingsTile } from 'app/shared/components/ContactForm/components/ListingsTile/ListingsTile';
import {
  LoadingSpinnerSt,
  VehiclesSt,
} from 'app/shared/components/ContactForm/components/MassEmailForm/MassEmailForm.css';
import {
  FormSt,
  IconWrapperSt,
  SubTitleSt,
  TitleSt,
} from 'app/shared/components/ContactForm/ContactForm.css';
import Translation from 'app/shared/components/Translation/Translation';
import { PhoneInput } from 'app/shared/components/PhoneInput/PhoneInput';
import { ExtraFormCheckBoxes } from 'app/shared/components/ContactForm/components/ExtraFormCheckBoxes/ExtraFormCheckBoxes';
import { FormSubmitButton } from 'app/shared/components/ContactForm/components/FormSubmitButton/FormSubmitButton';
import { FormProvider, useForm } from 'react-hook-form';
import { usePersonalInfo } from 'app/hooks/usePersonalInfo/usePersonalInfo';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { useStoreLastViewed } from 'app/hooks/useStoreLastViewed';
import {
  generateEventId,
  scrollToError,
} from 'app/shared/components/ContactForm/utils';
import { VALUE_OF_LEAD_IN_EUR } from 'app/shared/components/ContactForm/ContactForm';
import {
  createNewBatchLeadRequest,
  createNewLeadRequest,
  NewLeadRequest,
} from 'app/apiCalls/leads';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLeadEnrichment } from 'app/providers/LeadEnrichmentProvider';
import { SuccessView } from 'app/shared/components/ContactForm/components/SuccessView/SuccessView';
import EmailInput from 'app/shared/components/EmailInput/EmailInput';
import { generateAdditionalTrackingParams } from 'app/utils/tracking';
import SimpleOneClickListings, {
  IListingWithStatus,
} from 'app/shared/components/ContactForm/components/OneClickListings/SimpleOneClickListings';
import { NonGutterDialogContentSt } from 'app/shared/components/Modal/Modal.css';
import FirstNameInput from 'app/shared/components/FirstNameInput/FirstNameInput';
import LastNameInput from 'app/shared/components/LastNameInput/LastNameInput';
import { useTrackingPageContext } from 'app/shared/components/PageWrapper/providers/TrackingPageProvider/TrackingPageProvider';
import { useContactedVehicles } from 'app/providers/ContactedVehiclesProvider/ContactedVehiclesProvider';
import { Email } from '@heycar-uikit/icons';
import { useCheckEcommerceOrderExists } from 'app/hooks/useCheckEcommerceOrderExists';
import { useFavorites } from 'app/providers/FavoritesProvider/FavoritesProvider';

type MassEmailFormProps = {
  vehicles: VehicleListingData[];
};

const MassEmailForm = ({ vehicles }: MassEmailFormProps): ReactElement => {
  const plainTranslate = usePlainTranslate();
  const lastViewedVehicles = useStoreLastViewed(null);
  const executeRecaptcha =
    useGoogleReCaptcha().executeRecaptcha || (() => Promise.resolve(''));
  const { addLead, addLeads } = useLeadEnrichment();
  const { markAsContacted, markManyAsContacted } = useContactedVehicles();
  const {
    personalInfo: { firstName, lastName, phoneNumber, email },
    savePersonalInfo,
  } = usePersonalInfo();
  const { trackAction } = useTrackingPageContext();
  const methods = useForm();
  const {
    handleSubmit,
    formState: { isSubmitted, isSubmitting },
  } = methods;

  const [privacyConsent, setPrivacyConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [leadCreationFailed, setLeadCreationFailed] = useState(false);
  const [listingsResults, setListingsResults] =
    useState<IListingWithStatus[]>(null);
  const [formRawData, setFormRawData] = useState(null);

  const { favoritesData } = useFavorites();

  const prefilledTextMessage = plainTranslate({
    id: 'shared.components.contactForm.message.defaultMessage',
  });

  const onSubmit = useCallback(
    async rawData => {
      setLeadCreationFailed(false);
      if (!privacyConsent) {
        scrollToError('privacy-consent');
        return;
      }
      setFormRawData(rawData);
      try {
        await createLeads(rawData);
        setListingsResults(
          vehicles.map(v => ({ listing: v, requested: true })),
        );
      } catch (_) {
        setLeadCreationFailed(true);
      }
    },
    [privacyConsent, vehicles, executeRecaptcha, addLead],
  );

  const onRetryRequest = useCallback(
    async (listing: VehicleListingData) => {
      const eventId = generateEventId();
      const recaptchaToken = await executeRecaptcha('mass_email_form');
      await createLead(formRawData, listing, recaptchaToken, eventId);
      return listing;
    },
    [formRawData, executeRecaptcha],
  );

  return listingsResults ? success() : form();

  async function createLeads(data) {
    const eventId = generateEventId();
    const recaptchaToken = await executeRecaptcha('mass_email_form');
    const formData = vehicles.map(vehicle =>
      generateFormData(data, eventId, vehicle),
    );
    return createNewBatchLeadRequest(formData, recaptchaToken).then(results => {
      vehicles.forEach(vehicle => trackLeadSuccess(vehicle, data));
      addLeads(results.map(lead => lead.leadId));
      markManyAsContacted(vehicles.map(vehicle => vehicle.id));
    });
  }

  async function createLead(
    data,
    vehicle: VehicleListingData,
    recaptchaToken?,
    eventId?,
  ) {
    const formData = generateFormData(data, eventId, vehicle);
    await useCheckEcommerceOrderExists(
      vehicle,
      formData.email,
      executeRecaptcha,
    );
    trackLeadClick(eventId, vehicle);
    return createNewLeadRequest(formData, recaptchaToken).then(lead => {
      trackLeadSuccess(vehicle, data);
      addLead(lead.leadId);
      markAsContacted(vehicle.id);
    });
  }

  function generateFormData(
    data,
    eventId,
    vehicle: VehicleListingData,
  ): NewLeadRequest {
    const formData = {
      ...data,
      message: prefilledTextMessage,
      appointmentDate: new Date().toISOString(),
      vehicleListingId: vehicle.id,
      marketingConsent: marketingConsent,
      optedPrivacyPolicy: privacyConsent,
      leadCreationConsent: false,
    };

    savePersonalInfo({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
    });

    return {
      type: 'email',
      eventId,
      lastViewedVehicles,
      favouriteVehicles: favoritesData,
      ...formData,
    };
  }

  function trackLeadClick(eventId, vehicle) {
    trackAction(
      'send-mail-modal-dealer_button_click',
      {
        label: 'one_click_favourite_flow',
        description: 'User submits message dealer form',
        value: VALUE_OF_LEAD_IN_EUR,
      },
      generateAdditionalTrackingParams(vehicle, { eventId }),
    );
  }

  function trackLeadSuccess(vehicle, formData) {
    trackAction(
      'sent_email_form_success',
      {
        label: 'one_click_favourite_flow',
        description: 'Form has been submitted successfully',
        privacyPolicy: privacyConsent,
        mktCommunication: marketingConsent,
        privacyPolicyTimestamp: new Date().toISOString(),
        mktCommunicationTimestamp: new Date().toISOString(),
      },
      generateAdditionalTrackingParams(vehicle),
      formData,
    );
  }

  function success() {
    return (
      <SuccessView
        qaId="contactform-email-success"
        title={<Translation id="lead.creation.massEmail.success.title" />}
        subtitle={<Translation id="lead.creation.massEmail.success.subtitle" />}
        newtonRecommendations={false}
        wrapChildren={false}
      >
        <NonGutterDialogContentSt>
          <SimpleOneClickListings
            vehicles={listingsResults}
            onRequest={onRetryRequest}
            translations="favorites"
          />
        </NonGutterDialogContentSt>
      </SuccessView>
    );
  }

  function form() {
    return (
      <div>
        <TitleSt
          data-qa="contactform-mass-email-title"
          data-testid="contactform-mass-email-title"
        >
          <IconWrapperSt>
            <Email />
          </IconWrapperSt>
          <Translation id="lead.creation.massEmail.title" />
        </TitleSt>
        <SubTitleSt>
          <Translation id="lead.creation.massEmail.subTitle" />
        </SubTitleSt>
        <VehiclesSt>
          {vehicles ? (
            vehicles.map(vehicle => (
              <ListingsTile
                key={vehicle.id}
                vehicle={vehicle}
                showAdditionalInfo={false}
                imageWidth={56}
                isOnlineReservationTile
                showMonthlyInstalmentRate
              />
            ))
          ) : (
            <LoadingSpinnerSt />
          )}
        </VehiclesSt>
        <FormProvider {...methods}>
          <FormSt onSubmit={handleSubmit(onSubmit)}>
            <FirstNameInput
              defaultValue={firstName}
              rules={{ required: true }}
            />
            <LastNameInput defaultValue={lastName} rules={{ required: true }} />
            <EmailInput defaultValue={email} rules={{ required: true }} />
            <PhoneInput defaultValue={phoneNumber} rules={{ required: true }} />
            <ExtraFormCheckBoxes
              interestedInFinancing={false}
              showFinancingCheckbox={false}
              privacyConsent={privacyConsent}
              marketingConsent={marketingConsent}
              handleSetPrivacyConsent={val => setPrivacyConsent(val)}
              handleSetMarketingConsent={val => setMarketingConsent(val)}
              highlightPrivacyLink={isSubmitted && !privacyConsent}
            />
            <FormSubmitButton
              isSubmitting={isSubmitting}
              ctaButtonText={
                <Translation id="lead.creation.massEmail.submit" />
              }
              hasErrors={leadCreationFailed}
            />
          </FormSt>
        </FormProvider>
      </div>
    );
  }
};

export { MassEmailForm };
