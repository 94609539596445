import { ReactElement } from 'react';
import Translation from 'app/shared/components/Translation/Translation';
import { HeaderSt, SubheaderSt } from './SimpleOneClickListings.css';

interface ILeadSuccessHeader {
  translations: 'newton' | 'favorites' | '';
  subHeader?: string;
}

const LeadSuccessHeader = ({
  translations,
  subHeader,
}: ILeadSuccessHeader): ReactElement => {
  const translationPart = translations ? `${translations}.` : '';
  const subHeaderPart = subHeader ? `${subHeader}.` : '';
  return (
    <>
      <HeaderSt>
        <Translation
          id={`shared.components.oneClickListings.${translationPart}header`}
        />
      </HeaderSt>
      <SubheaderSt>
        <Translation
          id={`shared.components.oneClickListings.${translationPart}${subHeaderPart}subheader`}
        />
      </SubheaderSt>
    </>
  );
};

export { LeadSuccessHeader };
