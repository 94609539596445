import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';
import { IconWrapper } from 'app/shared/components/IconWrapper/IconWrapper';
import { Typography } from 'app/shared/components/Typography/Typography';

interface IInfoPopupStProps {
  renderTextAsHtml?: boolean;
  specialFilters?: boolean;
  leadForm?: boolean;
}

export const CloseButtonSt = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  display: inline-flex;
`;

export const TextArea = styled(Typography).attrs({
  variant: 'caption5',
  Component: 'div',
})`
  line-height: 1.5;
  text-align: start;
`;

const shadow = `
0 9px 12px rgba(0, 0, 0, 0.14), 
0 3px 16px rgba(0, 0, 0, 0.12),
0 5px 6px rgba(0, 0, 0, 0.2)
`;

export const InfoPopupContainerSt = styled.div<IInfoPopupStProps>`
  display: ${({ renderTextAsHtml }) =>
    renderTextAsHtml ? 'inline-grid' : 'flex'};
  position: absolute;
  right: ${({ specialFilters }) => !specialFilters && '-5rem'};
  top: ${({ specialFilters }) => !specialFilters && '2.25rem'};
  font-weight: normal;
  cursor: auto;
  padding: ${({ specialFilters }) =>
    specialFilters ? '0.5rem 1.5rem 0.5rem 1rem' : '0.5rem 2rem 0.5rem 1rem'};
  background-color: white;
  z-index: ${({ specialFilters }) => (specialFilters ? '10' : '10000')};
  color: ${({ theme }) => theme.colors.neutral800};
  box-shadow: ${shadow};
  max-width: 19rem;
  left: ${({ specialFilters }) => (specialFilters ? '2.5rem' : '1rem')};
  max-height: ${({ specialFilters }) => !specialFilters && '20rem'};
  max-width: ${({ specialFilters }) => specialFilters && '19rem'};
  overflow: auto;
  ${media.sm(css<{ specialFilters?: boolean }>`
    max-width: 19rem;
    right: ${({ specialFilters }) => !specialFilters && 'unset'};
    left: ${({ specialFilters }) => !specialFilters && '0'};
  `)}
  ${media.lg(css<{ specialFilters?: boolean; leadForm?: boolean }>`
    left: ${({ specialFilters, leadForm }) =>
      specialFilters ? (leadForm ? 'unset' : '0.815rem') : '-9rem'};
    max-width: ${({ specialFilters }) => (specialFilters ? '18rem' : '22rem')};
    right: ${({ leadForm }) => leadForm && '3rem'};
  `)}
`;

export const ContainerSt = styled.div`
  display: flex;
`;

export const IconContainerSt = styled(IconWrapper)`
  display: flex;
  margin-left: 0.5rem;
`;
