import { Header } from '@hey-car/gds';

import styles from './NewHeader.module.css';

const nav = [
  { label: 'Opiniones sobre coches', href: '/es/opiniones' },
  { label: 'Últimas noticias motor', href: '/es/noticias' },
  { label: 'Guías sobre coches', href: '/es/guias' },
];

const lang = {
  currentLang: 'es-ES',
  options: [
    {
      langCode: 'es-ES',
      label: 'Español',
      shortName: 'ES',
      href: '/',
    },
    {
      langCode: 'en-GB',
      label: 'English',
      shortName: 'ENG',
      href: 'https://heycar.co.uk',
    },
    {
      langCode: 'fr-FR',
      label: 'Français',
      shortName: 'FR',
      href: 'https://heycar.fr',
    },
    {
      langCode: 'de-DE',
      label: 'Deutsch',
      shortName: 'DE',
      href: 'https://hey.car',
    },
  ],
};

export const NewHeader = () => {
  return (
    <div className={styles.wrapper}>
      <Header logoHref="/" navigation={nav} langItemConfig={lang} />;
    </div>
  );
};
