import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';
import { Container } from 'app/shared/components/Container/Container';
import { SpanTypography } from 'app/shared/components/Typography/SpanTypography';

export const FooterSt = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const FooterWrapperSt = styled.div`
  margin-top: 48px;
`;

export const ContainerSt = styled(Container)`
  padding-top: 2rem;

  p {
    padding: 0;
    margin: 0;
  }

  a {
    display: inline-block;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.p2};
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 0.5rem;
  }

  a:active,
  a:visited {
    color: ${({ theme }) => theme.colors.white};
  }

  a:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const FooterCopySt = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.875rem;

  .trustpilot-widget {
    grid-column: 1 / span 3;
    ${media.lg(css`
      grid-column: 3;
    `)}
  }

  padding-bottom: 5rem;
  ${media.sm(css`
    padding-top: 1rem;
  `)}
`;

export const CopyrightTextSt = styled(SpanTypography).attrs({
  variant: 'caption2',
})`
  color: ${({ theme }) => theme.colors.white};
  grid-column: 1 / span 3;
`;

export const FooterListSt = styled.ul`
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  padding: 0;
  list-style: none;

  ${media.md(css`
    grid-auto-flow: column;
  `)};
`;

export const FooterListItemSt = styled.li`
  & > div {
    display: flex;
  }

  & figure {
    margin: 0;
    margin-right: 1rem;
    width: 25px;
  }

  & figure img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(236deg)
      brightness(102%) contrast(103%);
  }
`;
