import { TextInput } from 'app/shared/components/TextInput/TextInput';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import POSTAL_CODE_VALIDATION_REGEXP from './PostalCodeInput.validator';
import { useMemo } from 'react';
import provinces from 'app/utils/provinces';
import { Location } from '@heycar-uikit/icons';

type PostalCodeInputRegisterOptions = RegisterOptions & {
  available?: boolean;
};

interface IPostalCodeInputProps {
  name?: string;
  defaultValue?: string;
  rules?: PostalCodeInputRegisterOptions;
}

const PostalCodeInput = ({
  name = 'postalCode',
  defaultValue = '',
  rules = {},
}: IPostalCodeInputProps) => {
  const plainTranslate = usePlainTranslate();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const rulesInt = {
    ...rules,
    disabled: undefined,
    onChange: rules.onChange ? e => rules.onChange(e.target.value) : null,
    validate: val => validatePostalCode(val),
    required: {
      value: !!rules.required,
      message: plainTranslate({
        id: 'shared.components.contactForm.zipcode.error.empty',
      }),
    },
    pattern: {
      value: POSTAL_CODE_VALIDATION_REGEXP,
      message: plainTranslate({
        id: 'shared.components.contactForm.zipcode.error.invalid',
      }),
    },
  };

  const label = useMemo(
    () =>
      `${plainTranslate({
        id: 'shared.components.contactForm.zipCode',
      })} ${rules.required ? '*' : ''}`,
    [rules],
  );

  return (
    <TextInput
      name={name}
      value={defaultValue}
      qaID={`contactform-input-${name}`}
      label={label}
      error={errors[name]}
      register={register(name, rulesInt)}
      maxLength={5}
      disabled={rules?.disabled}
      unit={<Location color="primary" />}
    />
  );

  function validatePostalCode(postalCode: string): string | boolean {
    if (!rules?.available || isAvailablePostalCode(postalCode)) {
      return true;
    }
    return plainTranslate({
      id: 'shared.components.contactForm.zipcode.error.unavailable',
    });
  }

  function isAvailablePostalCode(postalCode: string): boolean {
    if (!postalCode) {
      return true;
    }
    const provinceCode = postalCode.substring(0, 2);
    const province = provinces.find(
      ({ postalCode }) => postalCode === provinceCode,
    );
    return !!province;
  }
};

export default PostalCodeInput;
