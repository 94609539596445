import { ReactElement } from 'react';
import { AnswerButtonSt } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentAnswer/EnrichmentAnswer.css';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { Tick } from '@heycar-uikit/icons';

export type AnswerStatus = 'IDLE' | 'SENT' | 'SENDING';

export interface IWrappedAnswer {
  text: string;
  status: AnswerStatus;
  sendAnswer: () => Promise<void>;
}

interface IEnrichmentAnswerProps {
  disabled: boolean;
  answer: IWrappedAnswer;
}

const EnrichmentAnswer = ({
  disabled,
  answer,
}: IEnrichmentAnswerProps): ReactElement => {
  const plainTranslate = usePlainTranslate();
  const isSent = answer.status === 'SENT';
  const isSending = answer.status === 'SENDING';
  const buttonIcon = isSent ? <Tick fontSize={20} /> : null;
  return (
    <AnswerButtonSt
      type="button"
      icon={buttonIcon}
      buttonText={plainTranslate({ id: answer.text })}
      disabled={disabled}
      isSpinning={isSending}
      handleClick={() => answer.sendAnswer()}
    />
  );
};

export { EnrichmentAnswer };
