import styled from 'styled-components';
import SpinnerButton from 'app/shared/components/SpinnerButton/SpinnerButton';

export const AnswerButtonSt = styled(SpinnerButton).attrs({
  fullWidth: true,
  spinnerPosition: 'left',
  spinnerSize: 18,
  spinnerItemSize: 3,
  spinnerMargin: '0.5rem',
  spinnerType: 'neutral400',
  size: 'small',
})`
  margin: 0.5rem 0;
`;
