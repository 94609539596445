import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ParagraphSt } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentForm.css';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { scrollIntoView } from 'app/utils/utils';

const ErrorSt = styled(ParagraphSt)`
  margin: 0.75rem auto 0 auto;
  color: ${({ theme }) => theme.colors.error500};
`;

const useError = () => {
  const plainTranslate = usePlainTranslate();
  const [errorMessage, setError] = useState<string>(null);
  useEffect(scrollToError, [errorMessage]);

  return {
    setError,
    error: errorMessage ? (
      <ErrorSt id="error-message">
        {plainTranslate({ id: errorMessage })}
      </ErrorSt>
    ) : null,
  };

  function scrollToError() {
    if (errorMessage) {
      scrollIntoView('error-message');
    }
  }
};

export { useError };
