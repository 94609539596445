import { TextInput } from 'app/shared/components/TextInput/TextInput';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';

interface IPasswordInputProps {
  defaultValue?: string;
  name?: string;
  label?: string;
}

const PasswordInput = ({
  defaultValue = '',
  label = '',
  name = 'password',
}: IPasswordInputProps) => {
  const plainTranslate = usePlainTranslate();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const rulesInt = {
    required: {
      value: true,
      message: plainTranslate({
        id: 'auth.loginForm.password.error.empty',
        defaultMessage: "Password can't be empty",
      }),
    },
    minLength: {
      value: 8,
      message: plainTranslate({
        id: 'auth.loginForm.password.error.minLength',
        defaultMessage: 'Password needs to be at least 8 characters long.',
      }),
    },
  };

  const labelInt = useMemo(
    () =>
      `${
        label ||
        plainTranslate({
          id: 'auth.loginForm.password',
          defaultMessage: 'Password',
        })
      } *`,
    [label],
  );

  return (
    <TextInput
      name={name}
      qaID={`login-form-input-${name}`}
      value={defaultValue}
      label={labelInt}
      register={register(name, rulesInt)}
      error={errors[name]}
      type="password"
    />
  );
};

export default PasswordInput;
