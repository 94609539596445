import get from 'lodash.get';
import { useTranslate } from 'app/shared/components/Translation/hooks/useTranslate/useTranslate';
import { formatFirstRegistrationDate } from 'app/utils/formatFirstRegistrationDate';
import { lowerCasedProp } from 'app/containers/PDP/components/Panels/utils';
import { ShortAddressSt, LocationSt } from './ListingsTile.css';

export const getAdditionalInfo = (
  vehicle,
  showAdditionalInfo = true,
  isD2C,
  isOnlineReservationTile = false,
) => {
  const translate = useTranslate();

  if (!showAdditionalInfo) {
    return [];
  }
  const firstRegistrationDateText = formatFirstRegistrationDate(
    vehicle.firstRegistrationDate,
  );

  const fuelTypeText = translate({
    id: `shared.components.filter.mappings.fuelType.${lowerCasedProp(
      vehicle.fuelType,
    )}`,
    defaultMessage: '',
  });

  const mileage = vehicle.mileage + 'km';
  const shortAddress = (
    <ShortAddressSt>
      {isOnlineReservationTile && <LocationSt fontSize={16} />}{' '}
      {isD2C
        ? translate({
            id: `shared.components.vehicle.infoBanner.location`,
            defaultMessage: `Delivery`,
          })
        : get(vehicle, 'dealer.location.nuts3', '')}
    </ShortAddressSt>
  );

  const oneClickAdditionalInfo = (
    <>
      {shortAddress}, {mileage}, {firstRegistrationDateText}, {fuelTypeText}
    </>
  );

  const returnAdditionalInfo = isOnlineReservationTile
    ? [shortAddress, firstRegistrationDateText, mileage, fuelTypeText]
    : [oneClickAdditionalInfo];

  return returnAdditionalInfo.filter(info => !!info);
};
