import { ReactElement } from 'react';
import { FormErrorsSt } from 'app/shared/components/ContactForm/components/FormErrors/FormErrors.css';
import { useTranslate } from 'app/shared/components/Translation/hooks/useTranslate/useTranslate';

interface IFormErrorsProps {
  formErrors?: string | ReactElement;
}

const FormErrors = ({ formErrors }: IFormErrorsProps): ReactElement => {
  const translate = useTranslate();
  const defaultError = translate({
    id: `lead.creation.defaultErrorMessage`,
    defaultMessage: `We've failed to send your request. Please try again later.`,
  });
  return <FormErrorsSt>{formErrors || defaultError}</FormErrorsSt>;
};

export { FormErrors };
