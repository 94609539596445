import { Helmet } from 'react-helmet';

interface IHtmlHeaderTitleProps {
  title: string;
}

const HtmlHeaderTitle = ({ title }: IHtmlHeaderTitleProps) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default HtmlHeaderTitle;
