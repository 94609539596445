import {
  useState,
  useCallback,
  useEffect,
  ReactNode,
  ReactElement,
} from 'react';
import { InputSt } from './TextInput.css';
import { UseFormRegisterReturn } from 'react-hook-form';
import Field from 'app/shared/components/Field/Field';

export interface ITextInputProps {
  autoComplete?: 'off' | 'on';
  type?: string;
  className?: string;
  label?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  error?: Error;
  unit?: string | ReactNode;
  onChange?: (value: string | number) => void;
  onBlur?: (value: string | number) => void;
  onEnter?: (value: string | number) => void;
  qaID?: string;
  onClick?: () => void;
  readOnly?: boolean;
  dataPolyfill?: string;
  dataFormat?: string;
  disabled?: boolean;
  min?: string | number;
  max?: string | number;
  step?: number;
  maxLength?: number;
  pattern?: string;
  inputMode?: 'text' | 'numeric' | 'search' | 'none' | 'tel' | 'url';
  inputComponent?: ReactElement;
  register: UseFormRegisterReturn;
  testid?: string;
}

const TextInput = ({
  autoComplete,
  type = 'text',
  className,
  label,
  name,
  value,
  placeholder,
  error,
  unit,
  onChange,
  onBlur = () => {},
  onEnter = () => {},
  qaID,
  onClick,
  readOnly,
  dataPolyfill,
  dataFormat,
  disabled,
  step,
  min = 0,
  max,
  maxLength,
  pattern,
  inputMode,
  register,
  inputComponent,
  testid,
}: ITextInputProps | any) => {
  const [val, setVal] = useState(value);

  const handleBlur = useCallback(() => {
    onBlur(val);
  }, [onBlur, val]);

  const handleChange = useCallback(
    e => {
      const val = e.target.value;
      setVal(val);
      onChange(val);
    },
    [onChange],
  );

  const handleEnter = useCallback(
    e => {
      if (e.keyCode === 13) {
        onChange(val);
        onEnter(val);
      }
    },
    [val, onEnter, onChange],
  );

  useEffect(() => {
    setVal(value);
  }, [value]);

  // if onChange is not passed it means the input is registered and handled in react-hook-form
  const Input = onChange ? (
    <InputSt
      autoComplete={autoComplete}
      type={type}
      name={name}
      value={val}
      id={name}
      placeholder={placeholder || label}
      error={error}
      hasUnit={!!unit}
      onChange={handleChange}
      onClick={onClick}
      onBlur={handleBlur}
      onKeyUp={handleEnter}
      data-qa={qaID}
      min={min}
      max={max}
      readOnly={readOnly}
      data-polyfill={dataPolyfill}
      data-format={dataFormat}
      disabled={disabled}
      step={step}
      pattern={pattern}
      inputMode={inputMode}
      maxLength={maxLength}
      data-testid={testid}
    />
  ) : (
    <InputSt
      autoComplete={autoComplete}
      type={type}
      name={name}
      defaultValue={value}
      id={name}
      placeholder={placeholder || label}
      data-qa={qaID}
      error={error}
      onClick={onClick}
      step={step}
      readOnly={readOnly}
      data-polyfill={dataPolyfill}
      data-format={dataFormat}
      disabled={disabled}
      pattern={pattern}
      inputMode={inputMode}
      maxLength={maxLength}
      min={min}
      max={max}
      data-testid={testid}
      {...register}
    />
  );

  return (
    <Field
      name={name}
      className={className}
      error={error}
      unit={unit}
      label={label}
    >
      {!!inputComponent ? inputComponent : Input}
    </Field>
  );
};

export { TextInput };
