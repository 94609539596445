import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { AnimatedContainerSt } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentAnimatedContainer/EnrichmentAnimatedContainer.css';

interface IEnrichmentAnimatedContainerProps {
  id?: string;
  children: ReactNode;
}

const EnrichmentAnimatedContainer = ({
  id,
  children,
}: IEnrichmentAnimatedContainerProps): ReactElement => {
  const [shown, setShown] = useState(false);
  useEffect(() => setShown(true), []);

  return (
    <AnimatedContainerSt id={id} shown={shown}>
      {children}
    </AnimatedContainerSt>
  );
};

export { EnrichmentAnimatedContainer };
