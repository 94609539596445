import styled from 'styled-components';

export const LoadingOverlaySt = styled.div`
  position: absolute;
  top: 1;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
