import {
  DealerNameTitleSt,
  SuccessSecondaryTextSt,
  SuccessTextSt,
} from 'app/shared/components/ContactForm/ContactForm.css';
import OneClickListings from 'app/shared/components/ContactForm/components/OneClickListings/OneClickListings';
import { ReactElement, useCallback, useState } from 'react';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { NonGutterDialogContentSt } from 'app/shared/components/Modal/Modal.css';
import { EnrichmentForm } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentForm';
import {
  WrapperSt,
  LottieAnimationSt,
} from 'app/shared/components/ContactForm/components/SuccessView/SuccessView.css';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { useTrackingPageContext } from 'app/shared/components/PageWrapper/providers/TrackingPageProvider/TrackingPageProvider';
import { Button } from 'app/shared/components/Button/Button';

type SuccessViewProps = {
  children?: JSX.Element | JSX.Element[];
  qaId: string;
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  oneCLickSubHeader?: string;
  onOneClickRequest?: (
    listing: VehicleListingData,
  ) => Promise<VehicleListingData>;
  vehicle?: VehicleListingData;
  showDealerInfo?: boolean;
  newtonRecommendations?: boolean;
  wrapChildren?: boolean;
};

const SuccessView = ({
  children,
  qaId,
  title,
  subtitle,
  oneCLickSubHeader,
  onOneClickRequest,
  vehicle,
  showDealerInfo = true,
  newtonRecommendations,
  wrapChildren = true,
}: SuccessViewProps): ReactElement => {
  const plainTranslate = usePlainTranslate();
  const { trackAction } = useTrackingPageContext();

  const [showEnrichmentQuestions, setShowEnrichmentQuestions] = useState(false);

  const onContinueEnrichmentButtonClicked = useCallback(() => {
    trackAction('continue_enrichment_formbutton_click');
    setShowEnrichmentQuestions(true);
  }, [trackAction]);

  return showEnrichmentQuestions ? <EnrichmentForm /> : getSuccessStepJsx();

  function getSuccessStepJsx() {
    return (
      <>
        <WrapperSt data-qa={qaId}>
          <LottieAnimationSt
            url="/assets/icons/checked-icon.json"
            height={40}
            width={40}
          />
          <SuccessTextSt>{title}</SuccessTextSt>
          <SuccessSecondaryTextSt>{subtitle}</SuccessSecondaryTextSt>

          {showDealerInfo && (
            <DealerNameTitleSt>{vehicle?.dealer?.name}</DealerNameTitleSt>
          )}
          {wrapChildren && children}
        </WrapperSt>
        {!wrapChildren && children}
        <NonGutterDialogContentSt>
          <OneClickListings
            onRequest={onOneClickRequest}
            subHeader={oneCLickSubHeader}
            newtonRecommendations={newtonRecommendations}
          />
        </NonGutterDialogContentSt>
        <Button
          qaID="lead-success-continue"
          alwaysFullWidth
          onClick={onContinueEnrichmentButtonClicked}
        >
          {plainTranslate({ id: 'lead.creation.success.continue' })}
        </Button>
      </>
    );
  }
};

export { SuccessView };
