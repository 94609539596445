import styled from 'styled-components';
import { Typography } from 'app/shared/components/Typography/Typography';
import Link from 'app/shared/components/Link/Link';

export const LinkSt = styled(Link)`
  margin: 1rem 0;
`;

export const TextSt = styled(Typography).attrs({ variant: 'body4' })`
  margin: 0;
  text-align: center;
`;
