import { PhoneInputSt } from './PhoneInput.css';
import {
  isPossiblePhoneNumber,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input/mobile';
import enCountryCodeLocales from 'react-phone-number-input/locale/en.json';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { ReactElement, useMemo } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import Field from '../Field/Field';

export type PhoneInputRulesOptions = RegisterOptions & {
  countries?: string[];
};

interface IPhoneInputProps {
  name?: string;
  defaultValue?: string;
  label?: string | JSX.Element;
  rules?: PhoneInputRulesOptions;
}

export const PhoneInput = ({
  name = 'phoneNumber',
  defaultValue = '',
  label,
  rules = {},
}: IPhoneInputProps): ReactElement => {
  const plainTranslate = usePlainTranslate();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rulesInt = {
    ...rules,
    validate: rules.validate || defaultValidate,
    required: {
      value: !!rules.required,
      message: getRequiredMessage(),
    },
  };

  const labelInt = useMemo(
    () => `${label || getDefaultLabel()}`,
    [rules, label],
  );

  const labelsWithCountryCode = useMemo(() => {
    return getCountries().reduce((acc, country) => {
      const countryLocale = enCountryCodeLocales[country];
      if (countryLocale) {
        acc[country] = `${countryLocale} (+${getCountryCallingCode(country)})`;
      }
      return acc;
    }, {});
  }, []);

  const selectableCountries = useMemo(() => {
    return rules?.countries || getCountries();
  }, [rules?.countries]);

  const defaultValueInt = useMemo(() => {
    if (defaultValue) {
      const phoneNumber = parsePhoneNumber(defaultValue);
      if (!!phoneNumber) {
        return selectableCountries.indexOf(phoneNumber.country) >= 0
          ? defaultValue
          : '';
      }
      return defaultValue;
    }
    return defaultValue;
  }, [defaultValue, selectableCountries]);

  const disableCountrySelect = useMemo(() => {
    return selectableCountries.length <= 1;
  }, [selectableCountries]);

  return (
    <Field name={name} label={labelInt} error={errors[name]}>
      <PhoneInputSt
        international
        defaultCountry="ES"
        addInternationalOption={false}
        countryOptionsOrder={['ES', '|']}
        countryCallingCodeEditable={false}
        countrySelectProps={{
          disabled: disableCountrySelect,
        }}
        disabled={rulesInt.disabled}
        limitMaxLength
        countries={selectableCountries}
        labels={labelsWithCountryCode}
        control={control}
        error={errors[name]}
        hideArrow={disableCountrySelect}
        name={name}
        id={name}
        data-qa={`contactform-input-${name}`}
        defaultValue={defaultValueInt}
        rules={rulesInt}
      />
    </Field>
  );

  function getRequiredMessage() {
    const { required } = rules;
    return typeof required === 'boolean'
      ? plainTranslate({
          id: 'shared.components.contactForm.phone.error.empty',
        })
      : required;
  }

  function getDefaultLabel(): string {
    return `${plainTranslate({
      id: 'shared.components.contactForm.phone',
    })} ${rules.required ? '*' : ''}`;
  }

  function defaultValidate(val: string) {
    if (rules?.required || val) {
      return (
        isPossiblePhoneNumber(val) ||
        plainTranslate({
          id: 'shared.components.contactForm.phoneNumber.error.invalid',
        })
      );
    }
    return true;
  }
};
