export default [
  { id: 'alava', postalCode: '01', value: 'Álava' },
  { id: 'albacete', postalCode: '02', value: 'Albacete' },
  { id: 'alicante', postalCode: '03', value: 'Alicante' },
  { id: 'almeria', postalCode: '04', value: 'Almería' },
  { id: 'avila', postalCode: '05', value: 'Ávila' },
  { id: 'badajoz', postalCode: '06', value: 'Badajoz' },
  {
    id: 'islas baleares',
    postalCode: '07',
    value: 'Islas Baleares',
  },
  { id: 'barcelona', postalCode: '08', value: 'Barcelona' },
  { id: 'burgos', postalCode: '09', value: 'Burgos' },
  { id: 'caceres', postalCode: '10', value: 'Cáceres' },
  { id: 'cadiz', postalCode: '11', value: 'Cádiz' },
  { id: 'castellon', postalCode: '12', value: 'Castellón' },
  { id: 'ciudad real', postalCode: '13', value: 'Ciudad Real' },
  { id: 'cordoba', postalCode: '14', value: 'Córdoba' },
  { id: 'a coruna', postalCode: '15', value: 'A Coruña' },
  { id: 'cuenca', postalCode: '16', value: 'Cuenca' },
  { id: 'gerona', postalCode: '17', value: 'Gerona' },
  { id: 'granada', postalCode: '18', value: 'Granada' },
  { id: 'guadalajara', postalCode: '19', value: 'Guadalajara' },
  { id: 'guipuzcoa', postalCode: '20', value: 'Guipúzcoa' },
  { id: 'huelva', postalCode: '21', value: 'Huelva' },
  { id: 'huesca', postalCode: '22', value: 'Huesca' },
  { id: 'jaen', postalCode: '23', value: 'Jaén' },
  { id: 'leon', postalCode: '24', value: 'León' },
  { id: 'lerida', postalCode: '25', value: 'Lérida' },
  { id: 'la rioja', postalCode: '26', value: 'La Rioja' },
  { id: 'lugo', postalCode: '27', value: 'Lugo' },
  { id: 'madrid', postalCode: '28', value: 'Madrid' },
  { id: 'malaga', postalCode: '29', value: 'Málaga' },
  { id: 'murcia', postalCode: '30', value: 'Murcia' },
  { id: 'navarra', postalCode: '31', value: 'Navarra' },
  { id: 'orense', postalCode: '32', value: 'Orense' },
  { id: 'asturias', postalCode: '33', value: 'Asturias' },
  { id: 'palencia', postalCode: '34', value: 'Palencia' },
  { id: 'pontevedra', postalCode: '36', value: 'Pontevedra' },
  { id: 'salamanca', postalCode: '37', value: 'Salamanca' },
  { id: 'cantabria', postalCode: '39', value: 'Cantabria' },
  { id: 'segovia', postalCode: '40', value: 'Segovia' },
  { id: 'sevilla', postalCode: '41', value: 'Sevilla' },
  { id: 'soria', postalCode: '42', value: 'Soria' },
  { id: 'tarragona', postalCode: '43', value: 'Tarragona' },
  { id: 'teruel', postalCode: '44', value: 'Teruel' },
  { id: 'toledo', postalCode: '45', value: 'Toledo' },
  { id: 'valencia', postalCode: '46', value: 'Valencia' },
  { id: 'valladolid', postalCode: '47', value: 'Valladolid' },
  { id: 'vizcaya', postalCode: '48', value: 'Vizcaya' },
  { id: 'zamora', postalCode: '49', value: 'Zamora' },
  { id: 'zaragoza', postalCode: '50', value: 'Zaragoza' },
  { id: 'ceuta', postalCode: '51', value: 'Ceuta' },
  { id: 'melilla', postalCode: '52', value: 'Melilla' },
  { id: 'las palmas', postalCode: '35', value: 'Las Palmas' },
  { id: 'tenerife', postalCode: '38', value: 'Tenerife' },
];
