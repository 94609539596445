import styled from 'styled-components';
import { ParagraphSt } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentForm.css';
import { EnrichmentAnimatedContainer } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentAnimatedContainer/EnrichmentAnimatedContainer';

interface IQuestionStProps {
  disabled?: boolean;
}

export const ProgressSt = styled.div<IQuestionStProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 1.5rem 0 0 0;
  color: ${({ theme }) => theme.colors.neutral600};
`;

export const QuestionSt = styled(ParagraphSt)<IQuestionStProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  font-size: 1rem;
  margin: 0 auto 0.75rem auto;
`;

export const ChoiceQuestionWrapperSt = styled(EnrichmentAnimatedContainer)`
  margin-bottom: 2rem;
`;
