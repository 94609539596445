import apiClient from '../utils/apiClient';
import { COMMON_API_URL } from 'next-app/app/config';
import {
  mapLocationsToSuggestions,
  removeDuplicates,
} from 'app/utils/location/location';
import { DEFAULT_RADIUS } from 'app/hooks/filters/location';

export interface Location {
  lat?: string;
  lon?: string;
  radius: number;
  place?: string;
}

export interface LocationSearch {
  placeName: string; // city,
  postcode: string;
  location: Location;
}

export interface LocationSuggestion {
  label: string;
  value: {
    postCode: string;
    placeName: string;
    location: Location;
  };
}

export const DefaultSuggestionList: LocationSuggestion[] = [
  {
    label: `28001 Madrid`,
    value: {
      postCode: '28001',
      placeName: 'Madrid',
      location: {
        lat: '40.4165',
        lon: '-3.7026',
        radius: 100,
        place: 'Madrid',
      },
    },
  },
  {
    label: `08001 Barcelona`,
    value: {
      postCode: '08001',
      placeName: 'Barcelona',
      location: {
        lat: '41.3888',
        lon: '2.159',
        radius: 100,
        place: 'Barcelona',
      },
    },
  },
  {
    label: `41001 Sevilla`,
    value: {
      postCode: '41001',
      placeName: 'Sevilla',
      location: {
        lat: '37.3824',
        lon: '-5.9761',
        radius: 100,
        place: 'Sevilla',
      },
    },
  },
  {
    label: `46001 Valencia`,
    value: {
      postCode: '46001',
      placeName: 'Valencia',
      location: {
        lat: '39.4698',
        lon: '-0.3774',
        radius: 100,
        place: 'Valencia',
      },
    },
  },
  {
    label: `29001 Malaga`,
    value: {
      postCode: '29001',
      placeName: 'Malaga',
      location: {
        lat: '36.7202',
        lon: '-4.4203',
        radius: 100,
        place: 'Malaga',
      },
    },
  },
];

export const getLocationSuggestionsByZipCode = async (
  zipCode: string,
  size = 5,
) => {
  const data = await getLocationsByZipCode(zipCode, size);
  return mapSuggestionsAndRemoveDuplicates(data);
};

export const getLocationSuggestionsByZipCodeOrCity = async (
  zipCodeOrCity: string,
) => {
  const data = await getLocationsByZipCodeOrCity(zipCodeOrCity);
  return mapSuggestionsAndRemoveDuplicates(data);
};

export const getLocationSuggestionByZipCode = async (
  zipCode: string,
): Promise<Location> => {
  const data = await getLocationSuggestionsByZipCode(zipCode, 1);
  return {
    ...data[0].value.location,
    place: `${data[0].value.postCode} ${data[0].value.placeName}`,
    radius: DEFAULT_RADIUS,
  };
};

function mapSuggestionsAndRemoveDuplicates(data) {
  const suggestions = mapLocationsToSuggestions(data || []);
  return removeDuplicates(suggestions, 'label');
}

const apiUrl = COMMON_API_URL;

const getLocationsByZipCode = async (
  zipCode: string,
  size = 5,
): Promise<LocationSearch[]> => {
  const { content } = await apiClient({
    apiUrl,
    path: '/location/search',
    params: {
      q: zipCode,
      size,
    },
    method: 'GET',
    data: null,
  });

  return content as LocationSearch[];
};

const getLocationsByZipCodeOrCity = async (
  zipCodeOrCity: string,
  size = 5,
): Promise<LocationSearch[]> => {
  const { content } = await apiClient({
    apiUrl,
    path: '/location/search',
    params: {
      q: zipCodeOrCity,
      size,
    },
    method: 'GET',
    data: null,
  });

  return content as LocationSearch[];
};
