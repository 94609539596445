import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';
import LottieAnimation from 'app/shared/components/LottieAnimation/LottieAnimation';
import { ParagraphSt } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentForm.css';

export const MessageSt = styled(ParagraphSt)`
  font-size: 1.125rem;
  font-weight: 300;
  margin: 1rem auto 0 auto;

  ${media.sm(css`
    font-size: 1.25rem;
  `)};
`;

export const LottieAnimationSt = styled(LottieAnimation)`
  margin: 0 auto;
`;
