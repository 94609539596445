import {
  BlueSt,
  SwitchWrapperSt,
  SecondaryTextSt,
} from 'app/shared/components/LoginRegisterModal/LoginRegisterModal.css';
import Translation from 'app/shared/components/Translation/Translation';

interface ISwitchToRegisterProps {
  onClick: (e) => void;
}

export const SwitchToRegister = ({ onClick }: ISwitchToRegisterProps) => {
  return (
    <SwitchWrapperSt onClick={onClick}>
      <SecondaryTextSt>
        <BlueSt>
          <Translation
            id="auth.loginForm.registerSwitch"
            defaultMessage="Register"
          />
        </BlueSt>{' '}
        <Translation
          id="auth.loginForm.ifYouDontHaveAccount"
          defaultMessage="if you don’t have an account yet"
        />
      </SecondaryTextSt>
    </SwitchWrapperSt>
  );
};
