import { IOperatingTimesData } from 'app/apiCalls/dealer';
import { v4 as uuid } from 'uuid';
import { scrollIntoView } from 'app/utils/utils';

export interface OpeningTimesDataItem {
  dayOfWeek?: number;
  times?: Partial<IOperatingTimesData>[];
}

export const generateOpeningTimesData = (
  openingHours?: IOperatingTimesData[],
): OpeningTimesDataItem[] => {
  if (!openingHours || !openingHours.length) {
    return [];
  }

  return openingHours.reduce(
    (
      arr: OpeningTimesDataItem[],
      { openFrom, openTo, dayOfWeek }: IOperatingTimesData,
    ) => {
      const previousItem: OpeningTimesDataItem = arr[arr.length - 1] || {};
      const previousDayOfWeek = dayOfWeek - 1;

      // if opening hours are present add them to the times array if not leave times array empty
      const times: Partial<IOperatingTimesData>[] =
        openFrom && openTo
          ? [
              {
                openFrom,
                openTo,
              },
            ]
          : [];

      // check whether the previous array item is the same day of week
      if (previousItem.dayOfWeek === previousDayOfWeek) {
        // if so, do not create a new array but only add the opening hours to the times array
        // to the existing array
        previousItem.times.push(times[0]);
      } else {
        // if not, create a data item and the opening hours to its times array
        arr.push({ times, dayOfWeek: previousDayOfWeek });
      }
      return arr;
    },
    [],
  );
};

const TIME_SLOT = 30; // in minutes

const pad = (str = '') => {
  const hours = +str.split(':')[0];
  return hours > 9 ? str : `0${str}`;
};

const getUTCTime = (date: string, time: string) =>
  new Date(date + 'T' + pad(time)).getTime();

export const createSlots = timeArr => {
  const date = new Date().toISOString().substring(0, 10);
  const slotsArray = [];

  for (let i = 0; i < timeArr.length; i++) {
    let slotStartTime = getUTCTime(date, timeArr[i].openFrom);
    const timeArrEnd = getUTCTime(date, timeArr[i].openTo);

    while (new Date(slotStartTime).getTime() < new Date(timeArrEnd).getTime()) {
      const endSlot = new Date(slotStartTime);
      const startSlot = new Date(slotStartTime);

      slotStartTime = endSlot.setHours(parseInt('' + endSlot.getHours()));
      slotStartTime = endSlot.setMinutes(
        parseInt('' + endSlot.getMinutes()) + TIME_SLOT,
      );

      if (new Date(slotStartTime).getTime() <= new Date(timeArrEnd).getTime()) {
        slotsArray.push({
          openFrom: new Date(startSlot).toLocaleTimeString('es-ES', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          }),
          openTo: endSlot.toLocaleTimeString('es-ES', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          }),
        });
      }
    }
  }

  return slotsArray;
};

const splitTimeSlots = timeSlots => {
  const res = [];
  for (const slot of timeSlots) {
    res.push({
      dayOfWeek: slot.dayOfWeek,
      times: createSlots(slot.times),
    });
  }
  return res;
};

export const getTimesByDayOfWeek = (openingTimesData, dayOfWeek: number) =>
  openingTimesData.find(item => item.dayOfWeek === dayOfWeek);

const SLOT_DELIMITER = ' - ';

export const getHoursByDay = (
  openingHours: IOperatingTimesData[],
  dayOfWeek: number,
) => {
  if (dayOfWeek === -1) {
    return [];
  }

  const openingTimesData = splitTimeSlots(
    generateOpeningTimesData(openingHours),
  );

  const timeData = getTimesByDayOfWeek(openingTimesData, dayOfWeek);

  if (!timeData) {
    return [];
  }
  const { times } = timeData;
  return times.map(time => `${time.openFrom}${SLOT_DELIMITER}${time.openTo}`);
};

export const getWeekDay = value => {
  if (!value) {
    return -1;
  }

  const day = new Date(value).getDay();

  // 0 represents Sunday:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
  if (day === 0) {
    return 6;
  }

  // all weekdays should point to Monday as opening times are stored for Monday, Saturday and Sunday only
  if (day < 6) {
    return 0;
  }

  return 5; // Saturday
};

export const getAppointmentDate = (callDate: string, callTime: string) => {
  const [year, month, day] = callDate.split('-');
  const [hour, minutes] = callTime
    .substring(0, callTime.indexOf(' '))
    .split(':');
  const userTzDateTime = new Date(+year, +month - 1, +day, +hour, +minutes);
  const spainDateTime = changeTimezone(userTzDateTime, 'Europe/Madrid');
  return spainDateTime.toISOString();
};

// we should think about introducing a robust library like luxon https://moment.github.io/luxon/index.html#/
function changeTimezone(date, targetTz) {
  const dateTimeInTargetTz = date.toLocaleString('en-US', {
    timeZone: targetTz,
  });
  const shiftedDateTime = new Date(dateTimeInTargetTz);
  const diff = shiftedDateTime.getTime() - date.getTime();
  return new Date(date.getTime() - diff);
}

export const filterTimeSlotsByDate = (
  timeSlots: string[],
  selectedDate: string,
  now: Date,
): string[] => {
  const todayDate = now.toISOString().split('T')[0];

  if (todayDate !== selectedDate) {
    return timeSlots;
  }

  const filteredTimeSlots = [];

  for (const slot of timeSlots) {
    const slotStart = slot.split(SLOT_DELIMITER)[0];
    if (getUTCTime(todayDate, slotStart) > now.getTime()) {
      filteredTimeSlots.push(slot);
    }
  }

  return filteredTimeSlots;
};

// removes hyphens from UUID to not
// exceed event ID's max character length (32)
export const generateEventId = () => uuid().replace(/-/g, '');

export const getCheckedServices = (
  interestedInFinancing?: boolean,
  interestedInNewtonRecom?: boolean,
): string[] => {
  //### tracking services
  const financingOfferTrackingText = interestedInFinancing
    ? 'interest_financing_offer'
    : '';
  const newtonRecomTrackingText = interestedInNewtonRecom
    ? 'interest_newton_recom'
    : '';
  const interestIn = [financingOfferTrackingText, newtonRecomTrackingText];
  const interestedServices = interestIn.filter(item => item);
  return interestedServices;
};

export const callDateValidation = (
  val: string,
  openingHours: IOperatingTimesData[],
  setCallDay: (n: -1) => void,
  errMsg: string,
): Error | null => {
  const availableWorkingDays = openingHours.map(({ dayOfWeek }) => dayOfWeek);

  let selectedDay = new Date(val).getDay();
  if (selectedDay === 0) {
    selectedDay = 7;
  }
  if (selectedDay < 6) {
    selectedDay = 1;
  }
  const dealerWorkingHours = getHoursByDay(openingHours, selectedDay - 1);
  const dealerTimeSlots = filterTimeSlotsByDate(
    dealerWorkingHours,
    val,
    new Date(),
  );

  if (
    !dealerTimeSlots.length ||
    availableWorkingDays.findIndex(day => day === selectedDay) === -1
  ) {
    setCallDay(-1);
    return {
      name: 'out of working hours',
      message: errMsg,
    };
  }
  return null;
};

export const scrollToError = (element: string) => {
  scrollIntoView(`error-${element}`);
};

export const reAssignMultipleAppointments = appointmentsWithRangeInHours => {
  return appointmentsWithRangeInHours.map(
    ({ appointmentStartsAt, startHour, endHour }) => {
      const startHourMiliseconds =
        new Date(`${appointmentStartsAt}T${startHour}`).getTime() / (60 * 1000);
      const endHourMiliseconds =
        new Date(`${appointmentStartsAt}T${endHour}`).getTime() / (60 * 1000);
      const rangeInHours = (endHourMiliseconds - startHourMiliseconds) / 60;

      return {
        appointmentStartsAt: changeTimezone(
          new Date(`${appointmentStartsAt}T${startHour}`),
          'Europe/Madrid',
        ).toISOString(),
        rangeInHours: rangeInHours === 0 ? 0.5 : rangeInHours,
      };
    },
  );
};
