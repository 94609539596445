import {
  useRef,
  useEffect,
  ReactNode,
  Fragment,
  useState,
  ReactElement,
} from 'react';
import {
  CloseButtonSt,
  InfoPopupContainerSt,
  TextArea,
  ContainerSt,
  IconContainerSt,
} from './InfoPopup.css';
import { Close, Info } from '@heycar-uikit/icons';
import { colors, colorValue } from 'app/styles/colors';

interface IInfoPopupProps {
  icon?: ReactElement;
  handleClose?: (e) => void;
  isVisible?: boolean;
  text: string | ReactNode | ReactNode[];
  theme?: IInfoPopupThemeProps;
  specialFilters?: boolean;
  handleOpenInfoPopup?: (e?) => void;
  leadForm?: boolean;
}

interface IInfoPopupThemeProps {
  icon?: colorValue;
}

const InfoPopup = ({
  icon,
  handleClose,
  isVisible,
  text,
  theme,
  specialFilters = false,
  handleOpenInfoPopup,
  leadForm = false,
}: IInfoPopupProps) => {
  const [visible, setVisible] = useState(false);

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(true);
    if (handleOpenInfoPopup) {
      handleOpenInfoPopup();
    }
  };

  const handleInfoClose = e => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(false);
  };

  const closePopup = specialFilters ? handleInfoClose : handleClose;
  const handlePopupOpen = specialFilters ? handleClick : handleOpenInfoPopup;
  const handleVisibility = specialFilters ? visible : isVisible;

  const ref = useRef(null);
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      closePopup(event);
    }
  };
  const renderTextAsHtml = Array.isArray(text);

  useEffect(() => {
    specialFilters
      ? document.addEventListener('click', handleClickOutside, true)
      : document.addEventListener('click', handleClickOutside);
    return () => {
      specialFilters
        ? document.addEventListener('click', handleClickOutside, true)
        : document.addEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <ContainerSt onClick={handlePopupOpen}>
      <IconContainerSt color={theme?.icon || colors.neutral700}>
        {icon || <Info fontSize={19} />}
      </IconContainerSt>
      {handleVisibility && (
        <InfoPopupContainerSt
          ref={ref}
          renderTextAsHtml={renderTextAsHtml}
          specialFilters={specialFilters}
          leadForm={leadForm}
        >
          {renderTextAsHtml &&
            specialFilters &&
            (text as ReactNode[]).map((item, index) => (
              <Fragment key={`${index}_${item}`}>
                {item}
                <br />
              </Fragment>
            ))}
          {!renderTextAsHtml && <TextArea>{text}</TextArea>}
          <CloseButtonSt onClick={closePopup} data-qa="popup-close-btn">
            <Close fontSize={14} />
          </CloseButtonSt>
        </InfoPopupContainerSt>
      )}
    </ContainerSt>
  );
};

export { InfoPopup };
