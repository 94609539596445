import { createContext, useContext, ReactNode, ReactElement } from 'react';

const footerData = [
  // '<p><a href="/es/sobre-heycar" title="Sobre heycar">Sobre heycar</a></p>',
  // '<p><a href="/es/coches-segunda-mano" title="Coches de segunda mano">Coches de segunda mano</a></p>',
  // '<p><a href="/es/coches-km0" title="Coches de km0">Coches de km0</a></p>',
  // '<p><a href="/es/coches-segunda-mano/compra-online" title="Coches de segunda mano online">Coches de segunda mano online</a></p>',
  // '<p><a href="/es/promociones" title="Promociones">Promociones</a></p>',
  '<p><a href="/es/garantias-certificaciones-oficiales" title="Garantías y certificaciones oficiales de marca">Garantías y certificaciones oficiales de marca</a></p>',
  // '<p><a href="/es/nuestra-promesa" title="Nuestra promesa">Nuestra promesa</a></p>',
  '<p><a href="/es/noticias" title="Últimas noticias del motor">Últimas noticias del motor</a></p>',
  '<p><a href="/es/opiniones">Opiniones sobre coches</a></p>',
  '<p><a href="/es/guias" title="Guías sobre coches">Guías sobre coches</a></p>',
  '<p><a href="/es/prensa" title="Prensa">Prensa</a>&nbsp;</p>',
  // '<p><a href="https://heycargroup.com/es/careers" title="Trabaja con nosotros">Trabaja con nosotros</a></p>',
  // '<p><a href="/es/unirse" title="Únete a heycar como concesionario">Únete a heycar como concesionario</a></p>',
  // '<p><a href="/es/preguntas-frecuentes" title="Preguntas frecuentes">Preguntas frecuentes</a></p>',
  // '<p><a href="/es/financiacion" title="Financia tu coche">Financia tu coche</a></p>',
  '<p><a href="/es/heycargroup" title="Sobre heycar Group">Sobre heycar Group</a></p>',
  '<p><a href="/es/cookie-dashboard" title="Uso de Cookies">Uso de Cookies</a></p>',
  '<p><a href="/es/terms" title="Condiciones Generales de Uso">Condiciones Generales de Uso</a></p>',
  // '<p><a href="/es/condiciones-generales-reserva-compra" title="Condiciones Generales de Reserva y Compra">Condiciones Generales de Reserva y Compra</a></p>',
  '<p><a href="/es/terminos-condiciones-de-uso" title="Términos y Condiciones Generales de Uso">Términos y Condiciones Generales de Uso</a></p>',
  '<p><a href="/es/privacy" title="Política de Privacidad">Política de Privacidad</a></p>',
  '<p><a href="/es/politica-de-cookies" title="Política de Cookies">Política de Cookies</a></p>',
  // '<figure data-asset-id="04662796-087b-4852-a64e-44b4efd9a86d" data-image-id="04662796-087b-4852-a64e-44b4efd9a86d"><img src="https://assets-eu-01.kc-usercontent.com:443/8752b7b2-a446-0114-99c2-d99a2e62714d/62c1936b-eab1-4923-af34-17dcbd588428/Instagram.svg" data-asset-id="04662796-087b-4852-a64e-44b4efd9a86d" data-image-id="04662796-087b-4852-a64e-44b4efd9a86d" alt=""></figure>\n<p><a href="https://www.instagram.com/heycar_es/?hl=en" data-new-window="true" title="heycar_es" target="_blank" rel="noopener noreferrer">heycar_es</a></p>',
  // '<figure data-asset-id="0ae446bf-7bee-411e-b22b-478fc161db7b" data-image-id="0ae446bf-7bee-411e-b22b-478fc161db7b"><img src="https://assets-eu-01.kc-usercontent.com:443/8752b7b2-a446-0114-99c2-d99a2e62714d/5caac54a-cb95-4b35-80d2-70f2b16a4590/FB.svg" data-asset-id="0ae446bf-7bee-411e-b22b-478fc161db7b" data-image-id="0ae446bf-7bee-411e-b22b-478fc161db7b" alt=""></figure>\n<p><a href="https://www.facebook.com/heycar.spain/" data-new-window="true" title="heycar España" target="_blank" rel="noopener noreferrer">heycar España</a></p>',
  // '<figure data-asset-id="1c5cfe2f-ef31-4bca-a5e6-31c654bd8471" data-image-id="1c5cfe2f-ef31-4bca-a5e6-31c654bd8471"><img src="https://assets-eu-01.kc-usercontent.com:443/8752b7b2-a446-0114-99c2-d99a2e62714d/6d4db717-c711-4181-81fe-eb99355387a6/tiktok.svg" data-asset-id="1c5cfe2f-ef31-4bca-a5e6-31c654bd8471" data-image-id="1c5cfe2f-ef31-4bca-a5e6-31c654bd8471" alt=""></figure>\n<p><a href="https://www.tiktok.com/@heycar_es" data-new-window="true" title="@heycar_es" target="_blank" rel="noopener noreferrer">@heycar_es</a></p>',
  // '<figure data-asset-id="b1ff21c7-12da-4b23-ba24-14c5874b596d" data-image-id="b1ff21c7-12da-4b23-ba24-14c5874b596d"><img src="https://assets-eu-01.kc-usercontent.com:443/8752b7b2-a446-0114-99c2-d99a2e62714d/70036cfc-1958-406c-b247-105a7cc82685/Youtube.svg" data-asset-id="b1ff21c7-12da-4b23-ba24-14c5874b596d" data-image-id="b1ff21c7-12da-4b23-ba24-14c5874b596d" alt=""></figure>\n<p><a href="https://www.youtube.com/channel/UCwxO7XMmuQS9dcTxdZjl6uA" data-new-window="true" title="heycar España" target="_blank" rel="noopener noreferrer">heycar España</a></p>',
  // '<figure data-asset-id="b8435698-de50-4213-a6e4-cb2106991fdf" data-image-id="b8435698-de50-4213-a6e4-cb2106991fdf"><img src="https://assets-eu-01.kc-usercontent.com:443/8752b7b2-a446-0114-99c2-d99a2e62714d/884178d5-4fe1-4740-aa39-a5a7e70d9818/App%20Store%20%281%29.webp" data-asset-id="b8435698-de50-4213-a6e4-cb2106991fdf" data-image-id="b8435698-de50-4213-a6e4-cb2106991fdf" alt="disponible en la app store"></figure>\n<p><a href="https://apps.apple.com/es/app/heycar-coches-de-segunda-mano/id1490142063">¡Descarga la App en App Store!</a></p>',
  // '<figure data-asset-id="a447de6b-ecda-4c1d-83b9-ab34f010b0ca" data-image-id="a447de6b-ecda-4c1d-83b9-ab34f010b0ca"><img src="https://assets-eu-01.kc-usercontent.com:443/8752b7b2-a446-0114-99c2-d99a2e62714d/f7a72843-21ed-430f-a64d-ac0cba3f7b32/Google%20Play%20%281%29.webp" data-asset-id="a447de6b-ecda-4c1d-83b9-ab34f010b0ca" data-image-id="a447de6b-ecda-4c1d-83b9-ab34f010b0ca" alt="disponible en google play"></figure>\n<p><a href="https://play.google.com/store/apps/details?id=com.mobility_trader_gmbh.heycar&amp;hl=es&amp;gl=US&amp;pli=1">¡Descarga la App en Google Play!</a></p>',
];

interface IFooterContextProviderProps {
  children: ReactNode;
}

interface IFooterData {
  footerData: string[];
}

const FooterContext = createContext<IFooterData>({
  footerData: [],
});

const FooterContextProvider = ({
  children,
}: IFooterContextProviderProps): ReactElement => {
  return (
    <FooterContext.Provider value={{ footerData }}>
      {children}
    </FooterContext.Provider>
  );
};

const useFooterContext = () => useContext(FooterContext);

export { FooterContextProvider, useFooterContext };
