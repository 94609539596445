import { ReactElement } from 'react';
import {
  ChoiceQuestionWrapperSt,
  ProgressSt,
  QuestionSt,
} from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentChoiceQuestion/EnrichmentChoiceQuestion.css';
import {
  EnrichmentAnswer,
  IWrappedAnswer,
} from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentAnswer/EnrichmentAnswer';
import { useError } from 'app/shared/components/ContactForm/components/EnrichmentForm/useError';
import { useSendAnswer } from 'app/shared/components/ContactForm/components/EnrichmentForm/useSendAnswer';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';

interface IEnrichmentChoiceQuestionProps {
  id: string;
  progress: string;
  question: string;
  answers: string[];
  onSelectedAnswer: (a: string) => Promise<void>;
}

const EnrichmentChoiceQuestion = ({
  id,
  progress,
  question,
  answers,
  onSelectedAnswer,
}: IEnrichmentChoiceQuestionProps): ReactElement => {
  const plainTranslate = usePlainTranslate();
  const { error, setError } = useError();
  const wrappedAnswers = wrapAnswers(answers, setError);
  const anyAnswerIsSent = wrappedAnswers.some(w => w.status === 'SENT');
  const anyAnswerIsSending = wrappedAnswers.some(w => w.status === 'SENDING');

  return (
    <ChoiceQuestionWrapperSt id={id}>
      {progress && (
        <ProgressSt disabled={anyAnswerIsSent}>{progress}</ProgressSt>
      )}
      <QuestionSt disabled={anyAnswerIsSent}>
        {plainTranslate({ id: question })}
      </QuestionSt>
      {wrappedAnswers.map(answer => (
        <EnrichmentAnswer
          key={answer.text}
          answer={answer}
          disabled={anyAnswerIsSending || anyAnswerIsSent}
        />
      ))}
      {error}
    </ChoiceQuestionWrapperSt>
  );

  function wrapAnswers(answers: string[], setError): IWrappedAnswer[] {
    return answers.map(answer => {
      const { status, sendAnswer } = useSendAnswer({
        sendAnswer: onSelectedAnswer,
        setError,
      });
      return {
        status,
        text: answer,
        sendAnswer: () => sendAnswer(answer),
      };
    });
  }
};

export { EnrichmentChoiceQuestion };
