import {
  useContext,
  createContext,
  ReactNode,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import PostalCodePrompter from './components/PostalCodePrompter/PostalCodePrompter';
import { wrapLocalStorage } from 'app/utils/localStorage';
import { isVehicleFromCanaryIslands } from 'app/utils/vehicle';
import { getLocationSuggestionByZipCode } from 'app/apiCalls/location';
import { useProvince } from 'app/hooks/filters/provinces';

interface IPostalCodePrompterContext {
  showPostalCodePrompter: Function;
  showPostalCodeDecision: Function;
  clearLastVehicle: Function;
  data: IPostalCodePrompterStorage;
}

export const PostalCodePrompterContext =
  createContext<IPostalCodePrompterContext>({
    showPostalCodePrompter: () => {},
    showPostalCodeDecision: () => {},
    clearLastVehicle: () => {},
    data: {},
  });

export enum Status {
  POSTAL_CODE_PROMPTER = 'POSTAL_CODE_PROMPTER',
  POSTAL_CODE_DECISION = 'POSTAL_CODE_DECISION',
}

interface IPostalCodePrompterStorage {
  postalCode?: string;
  forceSearchCanaryIslands?: boolean;
  alreadyAnswered?: boolean;
  lastVehicleId?: string;
  postalCodeRequired?: boolean;
}

const storage = wrapLocalStorage<IPostalCodePrompterStorage>(
  'persist:postalCodePrompter',
);

interface IPostalCodePrompterProviderProps {
  children?: ReactNode;
}

const PostalCodePrompterProvider = ({
  children,
}: IPostalCodePrompterProviderProps): ReactElement => {
  const { provinceList } = useProvince();

  const [status, setStatus] = useState(null);
  const [data, setData] = useState<IPostalCodePrompterStorage>();

  const [preventedAction, setPreventedAction] = useState<{ fn: Function }>({
    fn: () => {},
  });

  useEffect(() => {
    setData(storage.init({}));
    const listener = () => setData(storage.get());
    return storage.addStorageEventListener(listener);
  }, []);

  const showPostalCodePrompter = (
    onClose,
    { required = false, onlyOnce = false } = {},
  ) => {
    updateData({
      postalCodeRequired: required,
    });

    if (onlyOnce && data?.alreadyAnswered) {
      return onClose();
    }

    setPreventedAction({
      fn: props => {
        updateData({
          postalCodeRequired: false,
          alreadyAnswered: onlyOnce ? true : data?.alreadyAnswered,
        });
        onClose(props);
      },
    });

    setStatus(Status.POSTAL_CODE_PROMPTER);
  };

  const showPostalCodeDecision = (vehicle, onClose) => {
    const isFromCanaryIslands = isVehicleFromCanaryIslands(vehicle);
    const canaryIslandsProvincesSelected = areCanaryIslandsProvincesSelected();

    if (!isFromCanaryIslands || canaryIslandsProvincesSelected) {
      return onClose();
    }

    setPreventedAction({
      fn: decision => {
        if (decision) {
          updateData({ lastVehicleId: vehicle?.id });
        }
        onClose(decision);
      },
    });

    setStatus(Status.POSTAL_CODE_DECISION);
  };

  const onSavePostalCode = async (postalCode: string) => {
    if (!postalCode) {
      return performPreventedActionAndCloseWithData();
    }

    updateData({ postalCode });

    return performPreventedActionAndCloseWithData(
      await getLocationSuggestionByZipCode(postalCode),
    );
  };

  const onSaveCanaryIslandsPostalCode = () => {
    updateData({ forceSearchCanaryIslands: true });

    performPreventedActionAndCloseWithData(true);
  };

  const onClose = () => performPreventedActionAndCloseWithData();

  const clearLastVehicle = () => {
    updateData({ lastVehicleId: null });
  };

  return (
    <PostalCodePrompterContext.Provider
      value={{
        showPostalCodePrompter,
        showPostalCodeDecision,
        data,
        clearLastVehicle,
      }}
    >
      {children}
      <PostalCodePrompter
        onClose={onClose}
        onSavePostalCode={onSavePostalCode}
        onSaveCanaryIslandsPostalCode={onSaveCanaryIslandsPostalCode}
        status={status}
      />
    </PostalCodePrompterContext.Provider>
  );

  function updateData(newData: Partial<IPostalCodePrompterStorage>): void {
    storage.patch({ ...newData });
    setData(data => ({ ...data, ...newData }));
  }

  function performPreventedActionAndCloseWithData(data?: any) {
    preventedAction.fn(data);
    setStatus(null);
  }

  function areCanaryIslandsProvincesSelected(): boolean {
    return (
      provinceList?.length > 0 &&
      provinceList.every(item => ['Las Palmas', 'Tenerife'].includes(item))
    );
  }
};

const usePostalCodePrompterContext = () =>
  useContext(PostalCodePrompterContext);

export { PostalCodePrompterProvider, usePostalCodePrompterContext };
