import { ReactElement } from 'react';
import {
  ErrorMessageSt,
  FieldSt,
  LabelSt,
  LabelTextSt,
  UnitSt,
} from './Field.css';

interface IFieldProps {
  name?: string;
  unit?: string | JSX.Element;
  label?: string;
  className?: string;
  children?: ReactElement | ReactElement[];
  error?: Error;
}

const Field = ({
  name,
  unit,
  label,
  className,
  error,
  children,
}: IFieldProps) => {
  className = className ? `${className} field` : 'field';
  return (
    <FieldSt className={className} error={error}>
      {error && (
        <ErrorMessageSt data-qa={`contactform-input-${name}-error`}>
          {error.message}
        </ErrorMessageSt>
      )}
      {children}
      <LabelSt error={error} htmlFor={name}>
        <LabelTextSt>{label}</LabelTextSt>
      </LabelSt>
      <UnitSt>{unit}</UnitSt>
    </FieldSt>
  );
};

export default Field;
