import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react';
import { IProviderComponent } from 'app/providers/provider.interface';

interface ILeadEnrichmentContext {
  createdLeads: string[];
  addLead: (leadId: string, isFromOneClick?: boolean) => void;
  addLeads: (leadIds: string[]) => void;
  oneClickWasUsed: boolean;
}

const LeadEnrichmentContext = createContext<ILeadEnrichmentContext>({
  createdLeads: [],
  addLead: () => {},
  addLeads: () => {},
  oneClickWasUsed: false,
});

const LeadEnrichmentProvider = ({
  children,
}: IProviderComponent): ReactElement => {
  const [oneClickWasUsed, setOneClickWasUsed] = useState<boolean>(false);
  const [createdLeads, setCreatedLeads] = useState<string[]>([]);
  const addLead = useCallback(
    (leadId: string, isFromOneClick: boolean = false) => {
      if (isFromOneClick) {
        setOneClickWasUsed(true);
      }
      setCreatedLeads(oldValue => [...oldValue, leadId]);
    },
    [],
  );
  const addLeads = useCallback((leadIds: string[]) => {
    setCreatedLeads(oldValue => [...oldValue, ...leadIds]);
  }, []);
  return (
    <LeadEnrichmentContext.Provider
      value={{
        createdLeads,
        addLead,
        addLeads,
        oneClickWasUsed,
      }}
    >
      {children}
    </LeadEnrichmentContext.Provider>
  );
};

const useLeadEnrichment = () => useContext(LeadEnrichmentContext);

export { LeadEnrichmentProvider, useLeadEnrichment };
