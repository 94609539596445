import { InfoPopup } from 'app/shared/components/InfoPopup/InfoPopup';
import styled, { css } from 'styled-components';
import { Image } from 'app/shared/components/Image/Image';
import { media } from 'app/styles/variables';
import {
  GutterDialogContentSt,
  NonGutterDialogContentSt,
} from 'app/shared/components/Modal/Modal.css';
import Checkbox from 'app/shared/components/Checkbox/Checkbox';

interface ExtraFormCheckBoxesStProps {
  isFromOnlineReservationForm?: boolean;
}

export const ExtraFormCheckBoxesSt = styled(
  NonGutterDialogContentSt,
)<ExtraFormCheckBoxesStProps>`
  padding-top: 0.75rem;
  ${media.sm(css`
    padding-top: 1rem;
  `)}
`;

export const PrivacyPolicyLinkSt = styled.span`
  display: block;
`;

export const SpecialsFilterCheckboxLabelSt = styled.span`
  display: flex;
  align-items: center;
  line-height: 1rem;
`;

export const SpecialsFilterInfoIconSt = styled(InfoPopup)`
  display: flex;
  margin-right: 1rem;
`;

export const ImageSt = styled(Image)`
  margin-right: 0.25rem;
`;

export const LinksContainerSt = styled(GutterDialogContentSt)<{
  backgroundType?: string;
}>`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: ${({ backgroundType, theme }) => {
    return backgroundType === 'sunbeam'
      ? theme.colors.tertiary100
      : backgroundType === 'mint3'
      ? theme.colors.secondary50
      : '';
  }};

  ${({ backgroundType }) =>
    backgroundType &&
    `
   margin-bottom: 0.5rem;
  `}
`;

export const LinksContainerCheckboxSt = styled(LinksContainerSt)<{
  isFromOnlineReservationForm?: boolean;
}>`
  padding-bottom: ${({ isFromOnlineReservationForm }) =>
    isFromOnlineReservationForm ? '0' : '.5rem'};
  padding-top: ${({ isFromOnlineReservationForm }) =>
    isFromOnlineReservationForm ? '0' : '.5rem'};
`;

export const FinancingDetailsWrapperSt = styled.div<{
  interestedInFinancing?: boolean;
  element?: HTMLDivElement;
}>`
  transition: height 600ms;
  height: ${({ interestedInFinancing, element }) =>
    interestedInFinancing ? `${element?.scrollHeight}px` : 0};
  overflow: hidden;
`;

export const Divider = styled.div`
  height: 0.5rem;
  left: 0;
  margin: 0.75rem 0;
  background: ${({ theme }) => theme.colors.neutral50};
  ${media.sm(css`
    margin: 1rem 0;
  `)}
`;

export const PopupHeaderTextSt = styled.p`
  margin: 0.5rem 0;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: left;
`;

export const PopupSubHeaderTextSt = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.5rem;
`;

export const SpecialsFilterTextSt = styled.div`
  max-width: 20rem;
`;
export const FinancialPopupDisclaimerSt = styled.div`
  margin-bottom: 0.5rem;
`;

export const FinancialPopupContentSt = styled.div`
  padding-left: 0.5rem;
`;

interface ICheckboxStProps {
  isFromOnlineReservationForm?: boolean;
  isLeadForm?: boolean;
}

export const CheckboxSt = styled(Checkbox)<ICheckboxStProps>`
  & > .label {
    line-height: ${({ isFromOnlineReservationForm }) =>
      isFromOnlineReservationForm && '1.2rem'};
    flex-grow: ${({ isLeadForm }) => isLeadForm && '1'};
  }
`;
