import styled, { css } from 'styled-components';
import { media, breakpoints } from 'app/styles/variables';

export const ContainerSt = styled.div`
  width: 100%;
  max-width: ${breakpoints.xxl}px;
  padding: 0 1rem;

  ${media.sm(css`
    padding: 0 2rem;
  `)};

  ${media.lg(
    css`
      margin: 0 auto;
      padding: 0 4rem;
    `,
  )};
`;
