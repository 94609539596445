import { Helmet } from 'react-helmet';

const HtmlHeaderScripts = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
      {
        "@context" : "https://schema.org",
        "@type" : "Organization",
        "url" : "https://heycar.com",
        "brand" : "heycar",
        "address" : {
          "@type" : "PostalAddress",
          "addressLocality" : "Barcelona",
          "addressCountry" : "ES",
          "postalCode" : "08018",
          "streetAddress" : "Roc Boronat 147"
        },
        "logo" : "https://heycar.com/assets/pwa/ms-icon-310x310.png"
      }`}
      </script>
      <script type="application/ld+json">
        {`
      {
        "@context" : "https://schema.org",
        "@type" : "WebSite",
        "url" : "https://heycar.com",
        "potentialAction" : {
          "@type" : "SearchAction",
          "target" : "https://heycar.com/coches-segunda-mano?page=0&q={search_term_string}",
          "query-input" : "required name=search_term_string"
        }
      }
      `}
      </script>
    </Helmet>
  );
};

export default HtmlHeaderScripts;
