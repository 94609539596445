import get from 'lodash.get';
import { useFeatureToggle } from 'app/providers/FeatureToggleProvider/FeatureToggleProvider';

export const useFlagValue = (flagId: number): string => {
  const { flagMap, forcedFlagMap } = useFeatureToggle();
  const flagValueFromMap = get(flagMap, `[${flagId}].flagToken`);
  const flagValueFromQuery = get(forcedFlagMap, `[${flagId}]`);

  return typeof flagValueFromQuery !== 'undefined'
    ? flagValueFromQuery
    : flagValueFromMap;
};
