import { Modal } from 'app/shared/components/Modal/Modal';
import LottieAnimation from 'app/shared/components/LottieAnimation/LottieAnimation';
import {
  ButtonSt,
  ContentSt,
  PopupHeadingSt,
  TitleSt,
} from 'app/shared/components/FavoritesOneClickPopup/FavoritesOneClickPopup.css';
import Translation from 'app/shared/components/Translation/Translation';
import { useFavorites } from 'app/providers/FavoritesProvider/FavoritesProvider';
import { useEffect } from 'react';
import { useTrackingPageContext } from 'app/shared/components/PageWrapper/providers/TrackingPageProvider/TrackingPageProvider';

interface IFavoritesOneClickPopupProps {
  onClose: () => void;
  show: boolean;
}

const FavoritesOneClickPopup = ({
  onClose,
  show,
}: IFavoritesOneClickPopupProps) => {
  const { oneClickFavoritesIds, showOneClickModal } = useFavorites();
  const { trackAction } = useTrackingPageContext();

  useEffect(() => {
    if (show) {
      trackAction('open_favorites_contact_dealers_popup');
    }
  }, [show, trackAction]);

  const handleButtonClick = () => {
    trackAction('favorites_contact_dealers_button_click');
    showOneClickModal();
    onClose();
  };

  return (
    <Modal show={show} onClose={onClose} direction="bottom" animated>
      <PopupHeadingSt data-qa="favorites-popup-heading">
        <LottieAnimation
          url="/assets/icons/checked-icon.json"
          height={24}
          width={24}
        />
        <TitleSt>
          <Translation
            id="shared.components.favorites.contactPopup.title"
            values={{ numberOfCars: oneClickFavoritesIds.length }}
          />
        </TitleSt>
      </PopupHeadingSt>

      <ContentSt>
        <Translation id="shared.components.favorites.contactPopup.content" />
      </ContentSt>
      <ButtonSt onClick={handleButtonClick} qaID="favorites-popup-button">
        <Translation id="shared.components.favorites.contactPopup.button" />
      </ButtonSt>
    </Modal>
  );
};

export { FavoritesOneClickPopup };
