import styled from 'styled-components';
import { ParagraphSt } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentForm.css';
import { TextInput } from 'app/shared/components/TextInput/TextInput';
import { Button } from 'app/shared/components/Button/Button';

interface IQuestionStProps {
  disabled?: boolean;
}

export const QuestionSt = styled(ParagraphSt)<IQuestionStProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  line-height: 1.5rem;
  margin: 2rem auto 0;
`;

export const TextInputSt = styled(TextInput)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  text-align: left;
  margin-bottom: 1rem;
`;

export const ButtonSt = styled(Button)`
  margin-top: 1rem;
`;
