import { LoadingPlaceHolder } from 'app/shared/components/LoadingPlaceHolder/LoadingPlaceHolder';
import styled from 'styled-components';

export const ContentLoadingWrapperSt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
`;

export const ContentTitlePlaceholderSt = styled(LoadingPlaceHolder)`
  height: 3rem;
  width: 100%;
`;

export const ContentPlaceholderSt = styled(LoadingPlaceHolder)`
  height: 20rem;
  width: 100%;
`;

export const ContentButtonPlaceholderSt = styled(LoadingPlaceHolder)`
  height: 3rem;
  width: 100%;
`;
