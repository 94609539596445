import styled from 'styled-components';
import { LoadingSpinner } from 'app/shared/components/LoadingSpinner/LoadingSpinner';

export const VehiclesSt = styled.div`
  margin: 1.5rem 0;
`;

export const LoadingSpinnerSt = styled(LoadingSpinner).attrs({
  type: 'neutral500',
})`
  margin: 0 auto;
`;
