import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { get, set } from 'js-cookie';
import { COOKIE_PREFERENCE_EXPIRES } from 'next-app/app/config';
import { useCookiesSettings } from 'app/providers/CookiesSettingsProvider';

interface IPersonalInfo {
  firstName?: string;
  lastName?: string;
  secondLastName?: string;
  email?: string;
  phoneNumber?: string;
  phoneAreaCode?: string;
  message?: string;
  zipCode?: string;
  birthdate?: string;
  birthcountry?: string;
  hasBirthCountryNationality?: string;
  nationality?: string;
  gender?: string;
  civilStatus?: string;
  municipality?: string;
  streetType?: string;
  house?: string;
  street?: string;
  residenceType?: string;
  howManyMembersInFamily?: string;
  landLinePhone?: string;
  employmentType?: string;
  employmentStatus?: string;
  laboralCategory?: string;
  laboralContractType?: string;
  documentType?: string;
  documentNumber?: string;
  documentValidityDate?: string;
  monthlyNetIncome?: string;
  companyName?: string;
  workingSinceDate?: string;
  livingHereSinceDate?: string;
  floor?: string;
  door?: string;
  province?: string;
  companySize?: string;
  companyActivity?: string;
  monthlyExpenses?: string;
  mobilePhone?: string;
  billingModality?: string;
  socialSecurityRegistrationDate?: string;
  antiquityResidence?: string;
  residenceCardType?: string;
  annualNetIncome?: string;
  retiredIncomeType?: string;
  unemployedIncomeType?: string;
  retiredSinceDate?: string;
  noWorkingSinceDate?: string;
}

export interface IUsePersonalInfoResult {
  personalInfo?: IPersonalInfo;
  savePersonalInfo?: Dispatch<SetStateAction<IPersonalInfo>>;
}

const COOKIE_NAME = 'personalInfo';

const usePersonalInfo = (): IUsePersonalInfoResult => {
  const { personal } = useCookiesSettings();

  const [personalInfo, setPersonalInfo] = useState(
    getPersonalInfoFromCookies(),
  );

  const savePersonalInfo = useCallback(
    data => {
      if (!personal) {
        return;
      }
      setPersonalInfo(value => ({
        ...value,
        ...data,
      }));
    },
    [setPersonalInfo, personal],
  );

  useEffect(() => {
    set(COOKIE_NAME, JSON.stringify(personalInfo), {
      expires: COOKIE_PREFERENCE_EXPIRES,
    });
  }, [get, personalInfo]);

  return { personalInfo, savePersonalInfo };

  function getPersonalInfoFromCookies() {
    try {
      return JSON.parse(get(COOKIE_NAME) || '{}');
    } catch (error) {
      return {
        firstName: '',
        lastName: '',
        secondLastName: '',
        email: '',
        phoneNumber: '',
        zipCode: '',
        birthdate: '',
        birthcountry: '',
        hasBirthCountryNationality: '',
        nationality: '',
        gender: '',
        civilStatus: '',
        municipality: '',
        streetType: '',
        house: '',
        street: '',
        residenceType: '',
        howManyMembersInFamily: '',
        landLinePhone: '',
        mobilePhone: '',
        employmentType: '',
        employmentStatus: '',
        laboralCategory: '',
        laboralContractType: '',
        documentType: '',
        documentNumber: '',
        documentValidityDate: '',
        monthlyNetIncome: '',
        companyName: '',
        workingSinceDate: '',
        livingHereSinceDate: '',
        floor: '',
        door: '',
        province: '',
      };
    }
  }
};

export { usePersonalInfo };
