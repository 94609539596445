import { useState } from 'react';

export type AnswerStatus = 'IDLE' | 'SENT' | 'SENDING';

interface IUseAnswerStatusProps {
  sendAnswer: (...answers: string[]) => Promise<void>;
  setError: (err: string) => void;
}

const useSendAnswer = ({ sendAnswer, setError }: IUseAnswerStatusProps) => {
  const [status, setStatus] = useState<AnswerStatus>('IDLE');

  const wrappedSendAnswer = (...answers: string[]) => {
    setError(null);
    setStatus('SENDING');
    return sendAnswer(...answers)
      .then(() => setStatus('SENT'))
      .catch(err => {
        setStatus('IDLE');
        setError(err);
      });
  };

  return {
    status,
    sendAnswer: wrappedSendAnswer,
  };
};

export { useSendAnswer };
