import { ReactElement, useState } from 'react';
import {
  ButtonSt,
  QuestionSt,
  TextInputSt,
} from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentDoubleQuestion/EnrichmentDoubleQuestion.css';
import {
  IDoubleTextQuestion,
  IValidation,
} from 'app/shared/components/ContactForm/components/EnrichmentForm/enrichment-questions';
import { useError } from 'app/shared/components/ContactForm/components/EnrichmentForm/useError';
import { useSendAnswer } from 'app/shared/components/ContactForm/components/EnrichmentForm/useSendAnswer';
import { EnrichmentAnimatedContainer } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentAnimatedContainer/EnrichmentAnimatedContainer';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';

interface IEnrichmentDoubleQuestionProps {
  id: string;
  question: IDoubleTextQuestion;
  onAnswerSubmitted: (firstAnswer, secondAnswer) => Promise<void>;
}

const EnrichmentDoubleQuestion = ({
  id,
  question,
  onAnswerSubmitted,
}: IEnrichmentDoubleQuestionProps): ReactElement => {
  const plainTranslate = usePlainTranslate();
  const [firstAnswer, setFirstAnswer] = useState<string>('');
  const [firstAnswerError, setFirstAnswerError] = useState<Error>(null);
  const [secondAnswer, setSecondAnswer] = useState<string>('');
  const [secondAnswerError, setSecondAnswerError] = useState<Error>(null);
  const { error: submitError, setError: setSubmitError } = useError();
  const { status, sendAnswer } = useSendAnswer({
    sendAnswer: onAnswerSubmitted,
    setError: setSubmitError,
  });
  const isSent = status === 'SENT';
  const isSending = status === 'SENDING';

  return (
    <EnrichmentAnimatedContainer id={id}>
      <QuestionSt disabled={isSent}>
        {plainTranslate({ id: question.text })}
      </QuestionSt>
      <TextInputSt
        value={firstAnswer}
        name="first-answer"
        disabled={isSent || isSending}
        onChange={answer => {
          setFirstAnswerError(null);
          setFirstAnswer(answer + '');
        }}
        label={plainTranslate({ id: question.firstInput.inputLabel })}
        unit={question.firstInput.unit}
        error={firstAnswerError}
        inputMode={question.firstInput.inputMode}
        maxLength={question.firstInput.maxLength}
        pattern={question.firstInput.pattern}
      />
      <TextInputSt
        value={secondAnswer}
        name="second-answer"
        disabled={isSent || isSending}
        onChange={answer => {
          setSecondAnswerError(null);
          setSecondAnswer(answer + '');
        }}
        label={plainTranslate({ id: question.secondInput.inputLabel })}
        unit={question.secondInput.unit}
        error={secondAnswerError}
        inputMode={question.secondInput.inputMode}
        maxLength={question.secondInput.maxLength}
        pattern={question.secondInput.pattern}
      />
      <ButtonSt
        qaID={`submit-${question.id}`}
        disabled={isSent || isSending}
        onClick={() => answerSubmitHandler()}
        alwaysFullWidth
        size="small"
      >
        {plainTranslate({ id: question.buttonText, defaultMessage: 'OK' })}
      </ButtonSt>
      {submitError}
    </EnrichmentAnimatedContainer>
  );

  function answerSubmitHandler() {
    if (!validateAnswers()) {
      return;
    }
    return sendAnswer(firstAnswer.trim(), secondAnswer.trim());
  }

  function validateAnswers() {
    const firstInputError = applyValidations(
      firstAnswer,
      question.firstInput.validations,
    );
    const secondInputError = applyValidations(
      secondAnswer,
      question.secondInput.validations,
    );
    setFirstAnswerError(firstInputError);
    setSecondAnswerError(secondInputError);
    return !firstInputError && !secondInputError;
  }

  function applyValidations(
    answer: string,
    validations: Array<IValidation>,
  ): Error {
    validations = validations || [];
    const failedValidation = validations.find(
      validation => !validation.fn(answer),
    );
    return failedValidation
      ? {
          name: failedValidation.name,
          message: plainTranslate({ id: failedValidation.error }),
        }
      : null;
  }
};

export { EnrichmentDoubleQuestion };
