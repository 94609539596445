import styled, { css, keyframes } from 'styled-components';
import { LoadingSpinnerProps } from './LoadingSpinner';

const fadeDelay = keyframes`
  0%,
  39%,
  100% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }
`;

const fadeAnimation = css`
  animation: ${fadeDelay} 1.2s infinite ease-in-out both;
`;

export const LoadingSpinnerSt = styled.div<LoadingSpinnerProps>`
  position: relative;
  ${({ size }) =>
    `height: ${size}px;
        width: ${size}px;`};

  & > .sc {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    &::before {
      display: block;
      background-color: ${({ type, theme }) =>
        type === 'default' ? theme.colors.white : theme.colors[type]};
      border-radius: 50%;
      content: '';
      ${({ itemSize }) =>
        `height: ${itemSize}px;
        width: ${itemSize}px;`};
      margin: 0 auto;
      ${fadeAnimation};
    }
  }

  & > .sc2 {
    transform: rotate(30deg);

    &::before {
      animation-delay: -1.1s;
    }
  }

  & > .sc3 {
    transform: rotate(60deg);

    &::before {
      animation-delay: -1s;
    }
  }

  & > .sc4 {
    transform: rotate(90deg);

    &::before {
      animation-delay: -0.9s;
    }
  }

  & > .sc5 {
    transform: rotate(120deg);

    &::before {
      animation-delay: -0.8s;
    }
  }

  & > .sc6 {
    transform: rotate(150deg);

    &::before {
      animation-delay: -0.7s;
    }
  }

  & > .sc7 {
    transform: rotate(180deg);

    &::before {
      animation-delay: -0.6s;
    }
  }

  & > .sc8 {
    transform: rotate(210deg);

    &::before {
      animation-delay: -0.5s;
    }
  }

  & > .sc9 {
    transform: rotate(240deg);

    &::before {
      animation-delay: -0.4s;
    }
  }

  & > .sc10 {
    transform: rotate(270deg);

    &::before {
      animation-delay: -0.3s;
    }
  }

  & > .sc11 {
    transform: rotate(300deg);

    &::before {
      animation-delay: -0.2s;
    }
  }

  & > .sc12 {
    transform: rotate(330deg);

    &::before {
      animation-delay: -0.1s;
    }
  }
`;
