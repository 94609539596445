import styled, { css } from 'styled-components';
import { textInputStyle } from 'app/styles/mixins';

interface BaseStylesProps {
  error?: Error;
  hasUnit?: boolean;
}

export const baseStyles = css<BaseStylesProps>`
  padding: 0.25rem 1rem 0.5625rem 1rem;
  padding-right: ${({ hasUnit }) => (hasUnit ? '1.5rem' : '1rem')};
  border: 0;
  margin-top: 0.125rem;
  border-bottom-width: ${({ error }) => (error ? '0.125rem' : '1px')};
  border-bottom-style: solid;
  border-bottom-color: ${({ error, theme }) =>
    error ? theme.colors.error500 : theme.colors.neutral200};
  touch-action: manipulation;
  color: ${({ theme }) => theme.colors.neutral900};
  background: none;
  font-size: 1rem;

  ::placeholder {
    opacity: 0;
    transition: inherit;
  }

  &:focus,
  &:focus-within {
    padding-bottom: 0.5rem;
    border-bottom-width: 0.125rem;
    border-bottom-color: ${({ error, theme }) =>
      error ? theme.colors.error500 : theme.colors.secondary};
  }
`;

interface Props {
  error?: Error;
  hasUnit?: boolean;
  readOnly?: boolean;
}

export const InputSt = styled.input<Props>`
  ${textInputStyle(baseStyles)}
`;
