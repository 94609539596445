import styled from 'styled-components';
import { SpanTypography } from 'app/shared/components/Typography/SpanTypography';
import { Typography } from 'app/shared/components/Typography/Typography';

interface IMonthlyPriceLinkStProps {
  underline?: boolean;
}

export const MonthlyPriceLinkSt = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: baseline;
  cursor: pointer;
`;

export const MonthlyRateAmountSt = styled(Typography).attrs({
  variant: 'h4',
  Component: 'span',
})<IMonthlyPriceLinkStProps>`
  color: ${({ theme }) => theme.colors.primary500};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;

export const MonthlyRateMonthSt = styled(SpanTypography).attrs({
  variant: 'caption5',
})`
  color: ${({ theme }) => theme.colors.neutral500};
  margin-left: 0.2rem;
`;

export const MonthlyRateMonthInnerSt = styled.span`
  margin-left: 2px;
  display: inline-block;
`;
