import { ReactElement } from 'react';
import { LoadingSpinnerSt } from './LoadingSpinner.css';

export interface LoadingSpinnerProps {
  className?: string;
  type?: string;
  itemSize?: number;
  size?: number;
}

export const LoadingSpinner = ({
  className = '',
  type = 'default',
  size = 32,
  itemSize = 4,
}: LoadingSpinnerProps): ReactElement => {
  const styleProps = {
    type,
    size,
    itemSize,
  };
  const classNames = [className, 'sk-fading-circle'].join(' ');

  return (
    <LoadingSpinnerSt className={classNames} {...styleProps}>
      <div className="sc1 sc" />
      <div className="sc2 sc" />
      <div className="sc3 sc" />
      <div className="sc4 sc" />
      <div className="sc5 sc" />
      <div className="sc6 sc" />
      <div className="sc7 sc" />
      <div className="sc8 sc" />
      <div className="sc9 sc" />
      <div className="sc10 sc" />
      <div className="sc11 sc" />
      <div className="sc12 sc" />
    </LoadingSpinnerSt>
  );
};
