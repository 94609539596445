import styled from 'styled-components';
import { Modal } from 'app/shared/components/Modal/Modal';
import { Typography } from 'app/shared/components/Typography/Typography';

export const ModalSt = styled(Modal)`
  .modal-content {
    justify-content: center;
    align-items: center;
  }
`;

export const AfterLoginContainerSt = styled.div`
  margin-top: 1rem;
`;

export const LogoWrapperSt = styled.div`
  padding-top: 10px;
  svg {
    max-height: 40px;
  }
`;

export const SecondaryTextSt = styled(Typography).attrs({ variant: 'body4' })`
  margin: 0;
  text-align: center;
`;

export const SwitchWrapperSt = styled.div`
  user-select: none;
  cursor: pointer;
  margin-bottom: 2rem;
`;

export const BlueSt = styled.span`
  color: ${({ theme }) => theme.colors.primary500};
`;
