import { addDays, today } from 'app/utils/dates';

export interface ILocalStorage<T> {
  init: (value: T) => T;
  set: (value: T) => void;
  get: () => T;
  remove: () => void;
  patch: (value: Partial<T>) => void;
  addStorageEventListener: (
    listener: (event: StorageEvent) => void,
  ) => () => void;
}

export const wrapLocalStorage = <T>(
  id: string,
  expireDays?: number,
): ILocalStorage<T> => {
  return {
    init(initialValue) {
      const currentStorage = this.get();
      const value = currentStorage || initialValue;
      this.set(value); // extends expiry
      return value;
    },
    set(value) {
      const wrappedValue = { value };
      addExpiryDateIfNeeded(wrappedValue, expireDays);
      _set(wrappedValue);
    },
    get() {
      const wrappedValue = _get();
      if (
        wrappedValue === null ||
        wrappedValue === undefined ||
        isExpired(wrappedValue)
      ) {
        this.remove();
        return null;
      } else {
        return wrappedValue.value;
      }
    },
    remove() {
      localStorage.removeItem(id);
    },
    patch(value) {
      const currentStorage = _get();
      const newValue: T = {
        ...(currentStorage && currentStorage.value),
        ...value,
      };
      this.set(newValue);
    },
    addStorageEventListener(listener) {
      const wrappedListener = (event: StorageEvent) => {
        if (event.key === id) {
          listener(event);
        }
      };
      window.addEventListener('storage', wrappedListener);
      return () => window.removeEventListener('storage', wrappedListener);
    },
  };

  function _get() {
    return JSON.parse(localStorage.getItem(id));
  }

  function _set(value) {
    localStorage.setItem(id, JSON.stringify(value));
  }
};

function addExpiryDateIfNeeded(wrappedValue, expireDays?) {
  if (expireDays) {
    wrappedValue.expiryDate = addDays(today(), expireDays).toMillis();
  }
  return wrappedValue;
}

function isExpired(wrappedValue) {
  return (
    wrappedValue.expiryDate && wrappedValue.expiryDate < today().toMillis()
  );
}
