import { AuthView } from 'app/shared/components/LoginRegisterModal/LoginRegisterModal';
import { IAM_URL } from 'next-app/app/config';
import Translation from 'app/shared/components/Translation/Translation';
import {
  TextSt,
  LinkSt,
} from 'app/shared/components/LoginRegisterModal/components/LoginRegisterForm/components/ForgotPassword/ForgotPassword.css';

interface IForgotPasswordProps {
  view: AuthView;
}

const ForgotPassword = ({ view }: IForgotPasswordProps) => {
  const forgotPasswordUrl = `${IAM_URL}/auth/realms/heycar-es/login-actions/reset-credentials`;

  if (view !== AuthView.LOGIN) {
    return null;
  }

  return (
    <LinkSt href={forgotPasswordUrl} blank>
      <TextSt>
        <Translation
          id="auth.loginForm.forgotYourPassword"
          defaultMessage="Forgot your password?"
        />
      </TextSt>
    </LinkSt>
  );
};

export default ForgotPassword;
