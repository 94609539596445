import styled, { css } from 'styled-components';
import { Button } from 'app/shared/components/Button/Button';
import { media } from 'app/styles/variables';

export const PopupHeadingSt = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1.125rem;

  ${media.sm(css`
    margin-top: 0;
  `)}
`;

export const TitleSt = styled.h6`
  font-size: ${({ theme }) => theme.fontSizes.h6};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 2;
  margin-left: 5px;
`;

export const ContentSt = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.p2};
  line-height: 1.125;
  margin: 0 0 1rem 0;
  text-align: center;
  ${media.sm(css`
    max-width: 25rem;
  `)}
`;

export const ButtonSt = styled(Button)`
  margin-bottom: 1rem;
  ${media.sm(css`
    margin-bottom: 0.5rem;
  `)}
`;
