import Translation from 'app/shared/components/Translation/Translation';

const DefaultRegisterButtonText = () => {
  return (
    <Translation
      id="auth.loginForm.registerButton"
      defaultMessage="Create user account"
    />
  );
};

export default DefaultRegisterButtonText;
