import { COMMON_API_URL } from 'next-app/app/config';
import authClient from 'app/utils/authClient';
import apiClient from 'app/utils/apiClient';

interface IUserProfileDataProps {
  email: string;
  firstName: string;
  lastName: string;
  secondLastName?: string;
  phoneNumber?: string;
}

interface ICreateProfileRequest {
  email: string;
  password: string;
}

export async function createProfile(request: ICreateProfileRequest) {
  return apiClient({
    apiUrl: COMMON_API_URL,
    path: '/profiles/create',
    method: 'POST',
    data: request,
  });
}

export async function updateProfile({
  firstName,
  lastName,
  secondLastName,
  email,
  phoneNumber,
}: IUserProfileDataProps) {
  return authClient({
    apiUrl: COMMON_API_URL,
    method: 'POST',
    path: '/profiles/me',
    data: {
      firstName,
      lastName,
      secondLastName,
      email,
      phoneNumber,
    },
  });
}

export async function getProfile() {
  return authClient({
    apiUrl: COMMON_API_URL,
    method: 'GET',
    path: '/profiles/me',
  });
}
