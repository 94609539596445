import Script from 'next/script';
import { useEffect, useRef } from 'react';

export const TrustpilotWidget = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <>
      <Script
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="afterInteractive"
      />
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="es-ES"
        data-template-id="5419b732fbfb950b10de65e5"
        data-businessunit-id="603cc4f7cc01ad00013553a5"
        data-style-height="24px"
        data-style-width="275px"
        data-theme="dark"
      >
        <a
          href="https://es.trustpilot.com/review/heycar.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
};
