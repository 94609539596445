import provinceCodes from './licensePlateProvinceCodes.json';

const anyProvinceCode = `(${provinceCodes.join('|')})`;
const newProvinceCodes = `(${provinceCodes
  .filter(c => c.length !== 3)
  .join('|')})`;
const alphabetChar = '[A-PR-Z]'; // All but => Q Ñ
const newAlphabetChar = '[B-DF-HJ-NPR-TV-Z]'; // All but => A E I O U Q Ñ
const separator = '[-| ]';

const veryOldPlateRegexp = new RegExp(
  `^${anyProvinceCode}${separator}\\d{1,6}$`,
  'i',
);
const oldPlateRegexp = new RegExp(
  `^${newProvinceCodes}${separator}\\d{4}${separator}${alphabetChar}{1,2}$`,
  'i',
);
const plateRegexp = new RegExp(`^\\d{4} ?${newAlphabetChar}{3}$`, 'i');

export const validateLicensePlate = (license: string): boolean => {
  license = license.trim();
  return (
    veryOldPlateRegexp.test(license) ||
    oldPlateRegexp.test(license) ||
    plateRegexp.test(license)
  );
};
