import styled from 'styled-components';
import { Typography } from 'app/shared/components/Typography/Typography';

export const FormSt = styled.form`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;

  .field {
    margin-bottom: 1rem;
  }
`;

export const OrSt = styled(Typography).attrs({
  variant: 'body4',
  Component: 'div',
})`
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;

  ::before,
  ::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral200};
    max-width: 40px;
    margin-bottom: -0.25rem;
  }

  ::before {
    margin-right: 0.5rem;
  }

  ::after {
    margin-left: 0.5rem;
  }
`;

export const TitleSt = styled(Typography).attrs({ variant: 'h5' })`
  margin: 0.5rem 0;
  text-align: center;
`;

export const InputContainerSt = styled.div`
  text-align: initial;
`;
