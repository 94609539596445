import { LocationSearch, LocationSuggestion } from 'app/apiCalls/location';

export const mapLocationsToSuggestions = (
  locations: LocationSearch[],
): LocationSuggestion[] =>
  locations.map(({ placeName, postcode: postCode, location }) => ({
    label: `${postCode} ${placeName}`,
    value: {
      postCode,
      placeName,
      location,
    },
  }));

export const removeDuplicates = <T>(array: T[], prop: keyof T) =>
  array.filter(
    (obj, pos) => array.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos,
  );
