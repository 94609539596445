import { VehicleListingData } from 'app/apiCalls/vehicle';

export enum PROMISE {
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}
type PromiseStatus = PROMISE.FULFILLED | PROMISE.REJECTED;
export interface IListingsTile {
  vehicle: VehicleListingData;
  onRequest?: (vehicle: VehicleListingData) => Promise<VehicleListingData>;
  status?: PromiseStatus;
  index?: number;
  monthlyRate?: number;
  isFromOnlineReservationForm?: boolean;
  showMonthlyInstalmentRate?: boolean;
  showOneClickButton?: boolean;
  showAdditionalInfo?: boolean;
  imageWidth?: number;
  isOnlineReservationTile?: boolean;
}
