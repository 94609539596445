import { useEffect, useState } from 'react';
import { get, set } from 'js-cookie';
import { useRouter } from 'next/router';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { COOKIE_PREFERENCE_EXPIRES } from 'next-app/app/config';
import { VehicleInfo } from 'app/apiCalls/leads';
import { useCookiesSettings } from 'app/providers/CookiesSettingsProvider';

const MAX_STORED = 4;
const gearBoxMap = new Map<string, string>()
  .set('automatic_gear', 'Automático')
  .set('manual_gear', 'Manual')
  .set('semiautomatic_gear', 'Semiautomático');

const loadLastViewedVehicles = () => {
  try {
    return JSON.parse(get('lastViewed') || '[]');
  } catch (e) {
    // just in case json string is corrupted
    return [];
  }
};

const saveLastViewedVehicles = (vehicleInfos: VehicleInfo[]) =>
  set('lastViewed', vehicleInfos, {
    expires: COOKIE_PREFERENCE_EXPIRES,
  });

const useStoreLastViewed = (data: VehicleListingData) => {
  const { personal } = useCookiesSettings();
  const { events } = useRouter();
  const [lastViewedVehicles, setLastViewedVehicles] = useState(
    loadLastViewedVehicles,
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    const handleRouteChange = () => {
      const {
        id,
        price,
        mileage,
        year,
        gearBox,
        make: { displayName: make },
        model: { displayName: model },
      } = data;

      try {
        const vehicleInfos: VehicleInfo[] = loadLastViewedVehicles().filter(
          ({ id: vehicleId }) => vehicleId !== id,
        );

        while (vehicleInfos.length >= MAX_STORED) {
          vehicleInfos.pop();
        }

        vehicleInfos.unshift({
          id,
          make,
          model,
          price: price.toString(),
          mileage: mileage.toString(),
          year: year.toString(),
          gearBox: gearBoxMap.get(gearBox) || gearBox,
        });

        if (personal) {
          saveLastViewedVehicles(vehicleInfos);
        }
        setLastViewedVehicles(vehicleInfos);
      } catch (error) {
        console.error(error);
      }
    };

    events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [data?.id]);

  return lastViewedVehicles;
};

export { useStoreLastViewed };
