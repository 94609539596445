import { ReactElement, useEffect } from 'react';
import { SubmitFormStickyWrapperSt } from 'app/shared/components/ContactForm/ContactForm.css';
import { RecaptchaDisclaimer } from 'app/shared/components/RecaptchaDisclaimer/RecaptchaDisclaimer';
import SpinnerButton from 'app/shared/components/SpinnerButton/SpinnerButton';
import { FormErrorsWrapperSt } from 'app/shared/components/ContactForm/components/FormSubmitButton/FormSubmitButton.css';
import { FormErrors } from 'app/shared/components/ContactForm/components/FormErrors/FormErrors';
import { GutterDialogContentSt } from 'app/shared/components/Modal/Modal.css';
import { scrollIntoView } from 'app/utils/utils';

interface IFormSubmitButtonProps {
  isSubmitting: boolean;
  ctaButtonText: string | JSX.Element;
  hasErrors?: boolean;
  formErrors?: string | ReactElement;
  showRecaptchaDisclaimer?: boolean;
}

const FormSubmitButton = ({
  isSubmitting,
  ctaButtonText,
  hasErrors,
  formErrors,
  showRecaptchaDisclaimer = true,
}: IFormSubmitButtonProps): ReactElement => {
  useEffect(() => {
    if (hasErrors) {
      scrollIntoView('form-errors', { behavior: 'smooth' });
    }
  }, [hasErrors]);

  return (
    <>
      {showRecaptchaDisclaimer && <RecaptchaDisclaimer />}
      {hasErrors && (
        <FormErrorsWrapperSt id="form-errors">
          <FormErrors formErrors={formErrors} />
        </FormErrorsWrapperSt>
      )}
      <SubmitFormStickyWrapperSt>
        <GutterDialogContentSt>
          <SpinnerButton
            qaID="contactform-submit-btn"
            type="submit"
            isSpinning={isSubmitting}
            buttonText={ctaButtonText}
            fullWidth
          />
        </GutterDialogContentSt>
      </SubmitFormStickyWrapperSt>
    </>
  );
};

export { FormSubmitButton };
