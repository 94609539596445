import apiClient from '../utils/apiClient';
import { EnrichmentQuestionId } from 'app/shared/components/ContactForm/components/EnrichmentForm/enrichment-questions';
import { COMMON_API_URL } from 'next-app/app/config';

const apiUrl = COMMON_API_URL;

export const enrichLeads = (
  leadIds: string[],
  questionId: EnrichmentQuestionId,
  answer: string,
): Promise<void> =>
  apiClient({
    apiUrl,
    path: '/leads/enrichment',
    data: {
      leadIds,
      questionId,
      answer,
    },
  });
