import {
  FormSt,
  OrSt,
  TitleSt,
  InputContainerSt,
} from './LoginRegisterForm.css';
import EmailInput from 'app/shared/components/EmailInput/EmailInput';
import PasswordInput from 'app/shared/components/PasswordInput/PasswordInput';
import { FormProvider, useForm } from 'react-hook-form';
import Translation from 'app/shared/components/Translation/Translation';
import { SwitchToRegister } from 'app/shared/components/LoginRegisterModal/components/SwitchToRegister';
import { SwitchToLogin } from 'app/shared/components/LoginRegisterModal/components/SwitchToLogin';
import { FormSubmitButton } from 'app/shared/components/ContactForm/components/FormSubmitButton/FormSubmitButton';
import { AuthView } from 'app/shared/components/LoginRegisterModal/LoginRegisterModal';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { useState } from 'react';
import { useAuthContext } from 'app/shared/components/PageWrapper/providers/AuthProvider/AuthProvider';
import ForgotPassword from 'app/shared/components/LoginRegisterModal/components/LoginRegisterForm/components/ForgotPassword/ForgotPassword';
import { SecondaryTextSt } from 'app/shared/components/LoginRegisterModal/LoginRegisterModal.css';
import { DefaultLoginRegisterLogo } from 'app/shared/components/LoginRegisterModal/components/DefaultLoginRegisterLogo';
import DefaultLoginHeaderSubTitle from 'app/shared/components/LoginRegisterModal/components/DefaultLoginHeaderSubTitle';
import DefaultRegisterHeaderSubTitle from 'app/shared/components/LoginRegisterModal/components/DefaultRegisterHeaderSubTitle';
import DefaultLoginButtonText from 'app/shared/components/LoginRegisterModal/components/DefaultLoginButtonText';
import DefaultRegisterButtonText from 'app/shared/components/LoginRegisterModal/components/DefaultRegisterButtonText';

interface ILoginRegisterFormProps {
  onSuccess?: (a: AuthView) => void;
  loginHeaderIcon?: JSX.Element;
  loginHeaderTitle?: JSX.Element;
  loginHeaderSubTitle?: JSX.Element;
  registerHeaderIcon?: JSX.Element;
  registerHeaderTitle?: JSX.Element;
  registerHeaderSubTitle?: JSX.Element;
  loginButtonText?: JSX.Element;
  registerButtonText?: JSX.Element;
  initialView?: AuthView;
}

const LoginRegisterForm = ({
  onSuccess,
  initialView = AuthView.REGISTER,
  loginHeaderIcon = <DefaultLoginRegisterLogo />,
  loginHeaderTitle,
  loginHeaderSubTitle = <DefaultLoginHeaderSubTitle />,
  registerHeaderIcon = <DefaultLoginRegisterLogo />,
  registerHeaderTitle,
  registerHeaderSubTitle = <DefaultRegisterHeaderSubTitle />,
  loginButtonText = <DefaultLoginButtonText />,
  registerButtonText = <DefaultRegisterButtonText />,
}: ILoginRegisterFormProps) => {
  const plainTranslate = usePlainTranslate();
  const [view, setView] = useState<AuthView>(initialView);
  const [formError, setFormError] = useState<string>(null);
  const methods = useForm();
  const { handleSubmit, formState } = methods;
  const { login, register } = useAuthContext();

  return (
    <>
      <Header
        view={view}
        loginHeaderIcon={loginHeaderIcon}
        loginHeaderTitle={loginHeaderTitle}
        loginHeaderSubTitle={loginHeaderSubTitle}
        registerHeaderIcon={registerHeaderIcon}
        registerHeaderTitle={registerHeaderTitle}
        registerHeaderSubTitle={registerHeaderSubTitle}
      />
      <FormProvider {...methods}>
        <FormSt onSubmit={handleSubmit(submit)}>
          <InputContainerSt>
            <EmailInput rules={{ required: true }} />
            <PasswordInput />
          </InputContainerSt>
          <OrSeparator />
          <Switcher view={view} setView={setView} />
          <ForgotPassword view={view} />
          <SubmitButton
            view={view}
            isSubmitting={formState.isSubmitting}
            formError={formError}
            loginButtonText={loginButtonText}
            registerButtonText={registerButtonText}
          />
        </FormSt>
      </FormProvider>
    </>
  );

  async function submit(formData) {
    setFormError(null);
    const { email, password } = formData;
    try {
      if (view === AuthView.LOGIN) {
        await login(email, password);
        await onSuccess(AuthView.LOGIN);
      } else if (view === AuthView.REGISTER) {
        await register(email, password);
        await onSuccess(AuthView.REGISTER);
      }
    } catch (e) {
      let errorMessage = plainTranslate({
        id: 'auth.loginForm.defaultErrorMessage',
        defaultMessage: 'Something went wrong, please try again later.',
      });

      if (e?.response?.status === 401) {
        errorMessage = plainTranslate({
          id: 'auth.loginForm.unauthorizedErrorMessage',
          defaultMessage: 'Email and password combination is incorrect',
        });
      }

      setFormError(errorMessage);
    }
  }
};

interface IHeaderProps {
  view: AuthView;
  loginHeaderIcon?: JSX.Element;
  loginHeaderTitle?: JSX.Element;
  loginHeaderSubTitle?: JSX.Element;
  registerHeaderIcon?: JSX.Element;
  registerHeaderTitle?: JSX.Element;
  registerHeaderSubTitle?: JSX.Element;
}

const Header = ({
  view,
  loginHeaderIcon,
  loginHeaderTitle,
  loginHeaderSubTitle,
  registerHeaderIcon,
  registerHeaderTitle,
  registerHeaderSubTitle,
}: IHeaderProps) => {
  if (view === AuthView.LOGIN) {
    return (
      <LoginRegisterHeader
        icon={loginHeaderIcon}
        title={loginHeaderTitle}
        subTitle={loginHeaderSubTitle}
      />
    );
  }

  if (view === AuthView.REGISTER) {
    return (
      <LoginRegisterHeader
        icon={registerHeaderIcon}
        title={registerHeaderTitle}
        subTitle={registerHeaderSubTitle}
      />
    );
  }

  return null;
};

const LoginRegisterHeader = ({ icon, title, subTitle }) => {
  return (
    <>
      {icon}
      {title && <TitleSt>{title}</TitleSt>}
      {subTitle && <SecondaryTextSt>{subTitle}</SecondaryTextSt>}
    </>
  );
};

const OrSeparator = () => {
  return (
    <OrSt>
      <Translation id="auth.loginForm.or" defaultMessage="or" />
    </OrSt>
  );
};

interface ISwitcherProps {
  view: AuthView;
  setView: (v: AuthView) => void;
}

const Switcher = ({ view, setView }: ISwitcherProps) => {
  return (
    <>
      {view === AuthView.LOGIN && (
        <SwitchToRegister onClick={() => setView(AuthView.REGISTER)} />
      )}
      {view === AuthView.REGISTER && (
        <SwitchToLogin onClick={() => setView(AuthView.LOGIN)} />
      )}
    </>
  );
};

interface ISubmitButtonProps {
  view: AuthView;
  formError: string;
  isSubmitting: boolean;
  loginButtonText?: JSX.Element;
  registerButtonText?: JSX.Element;
}

const SubmitButton = ({
  view,
  formError,
  isSubmitting,
  loginButtonText,
  registerButtonText,
}: ISubmitButtonProps) => {
  const ctaText =
    view === AuthView.LOGIN ? loginButtonText : registerButtonText;
  return (
    <FormSubmitButton
      isSubmitting={isSubmitting}
      ctaButtonText={ctaText}
      hasErrors={!!formError}
      formErrors={formError}
      showRecaptchaDisclaimer={false}
    />
  );
};

export { LoginRegisterForm };
