import { useRouter } from 'next/router';
import { ReactElement, useCallback } from 'react';
import { FormattedNumber } from 'react-intl';
import {
  MonthlyPriceLinkSt,
  MonthlyRateAmountSt,
  MonthlyRateMonthSt,
  MonthlyRateMonthInnerSt,
} from './MonthlyRateLink.css';
import { useServices } from 'app/providers/ServicesProvider';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import Translation from 'app/shared/components/Translation/Translation';
import { useFinancingEnabled } from 'app/hooks/useFinancingEnabled';

interface IMonthlyRateLinkProps {
  vehicle?: VehicleListingData;
  value?: number;
  href?: string;
  onClick?: () => void;
  underline?: boolean;
}

const MonthlyRateLink = ({
  vehicle,
  value,
  href = '',
  onClick = () => {},
  underline,
}: IMonthlyRateLinkProps): ReactElement => {
  if (!value) {
    return null;
  }

  const router = useRouter();
  const financingEnabled = useFinancingEnabled(vehicle);
  const { handleInterestedInFinancing } = useServices();

  const handleOnClick = useCallback(
    event => {
      if (onClick) {
        onClick();
      }
      if (href.includes('/vehicle')) {
        event.preventDefault();
        router.push(href);
      }
      handleInterestedInFinancing(true, vehicle);
    },
    [onClick, router],
  );

  return (
    financingEnabled && (
      <MonthlyPriceLinkSt
        onClick={handleOnClick}
        data-testid="monthly-rate-link"
        className="monthly-price-link"
      >
        <MonthlyRateAmountSt
          underline={underline}
          className="monthly-price-link-text"
        >
          <FormattedNumber
            value={value}
            style="currency"
            currency="EUR"
            useGrouping
          />
        </MonthlyRateAmountSt>
        <MonthlyRateMonthSt>
          /
          <MonthlyRateMonthInnerSt>
            <Translation
              id="shared.components.financingCalculator.summaryBox.month"
              defaultMessage="month"
            />
          </MonthlyRateMonthInnerSt>
          *
        </MonthlyRateMonthSt>
      </MonthlyPriceLinkSt>
    )
  );
};
export { MonthlyRateLink };
