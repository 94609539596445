import { ReactNode } from 'react';

export const lowerCasedProp = (prop?: string): string =>
  (prop || '').toLowerCase();

export const isNotEmpty = (value?: string | number | ReactNode): boolean =>
  !!(value || '').toString().trim();

export const isNotEmptyRow = ({
  value,
}: {
  value?: string | number | ReactNode;
}): boolean => isNotEmpty(value);
