import { ReactElement } from 'react';
import { Button, ButtonType } from 'app/shared/components/Button/Button';

export interface ISpinnerButtonProps {
  spinnerPosition?: 'left' | 'right';
  spinnerSize?: number;
  spinnerItemSize?: number;
  spinnerMargin?: string;
  spinnerType?: string;
  isSpinning?: boolean;
  type: ButtonType;
  qaID?: string;
  buttonText: ReactElement | string;
  fullWidth?: boolean;
  icon?: ReactElement;
  disabled?: boolean;
  handleClick?: () => void;
  className?: string;
  color?: 'primary' | 'tertiary' | 'whatsapp';
  size?: 'small' | 'large';
}

const SpinnerButton = ({
  isSpinning = false,
  type,
  qaID,
  buttonText,
  fullWidth = false,
  disabled = false,
  handleClick = () => {},
  className,
  icon,
  color,
  size,
}: ISpinnerButtonProps) => {
  return (
    <Button
      type={type}
      qaID={qaID}
      leftIcon={icon}
      disabled={disabled || isSpinning}
      onClick={handleClick}
      alwaysFullWidth={fullWidth}
      className={className}
      loading={isSpinning}
      color={color}
      size={size}
    >
      {buttonText}
    </Button>
  );
};

export default SpinnerButton;
