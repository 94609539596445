import { ILocalStorage, wrapLocalStorage } from 'app/utils/localStorage';

interface IUseLocalStorageProps {
  id: string;
}

export function useLocalStorage<T>({
  id,
}: IUseLocalStorageProps): ILocalStorage<T> {
  return wrapLocalStorage<T>(id);
}
