import Link from 'app/shared/components/Link/Link';
import {
  ButtonSt,
  ContainerWrapperSt,
  NotAvailableOverlaySt,
  ImageSt,
  TextSt,
} from './NotAvailableOverlay.css';
import useNotAvailableOverlay from './hooks/useNotAvailableOverlay/useNotAvailableOverlay';
import { VehicleListingData } from 'app/apiCalls/vehicle';

interface INotAvailableOverlay {
  isOneClickListingTile?: boolean;
  vehicle: VehicleListingData;
  withNumberOfSimilarCars?: boolean;
}

const NotAvailableOverlay = ({
  isOneClickListingTile = false,
  vehicle,
  withNumberOfSimilarCars = false,
}: INotAvailableOverlay) => {
  const { notAvailableText, showButton, buttonText, similarCarsLink } =
    useNotAvailableOverlay({
      withNumberOfSimilarCars,
      vehicle,
    });

  return (
    <NotAvailableOverlaySt data-testid="not-available-overlay">
      <ContainerWrapperSt isOneClickListingTile={isOneClickListingTile}>
        <ImageSt
          isOneClickListingTile={isOneClickListingTile}
          src="/assets/story-icons/not-available-car.svg"
          alt="not available car"
        />
        <TextSt isOneClickListingTile={isOneClickListingTile}>
          {notAvailableText}
        </TextSt>
        {showButton && (
          <Link href={similarCarsLink}>
            <ButtonSt>{buttonText}</ButtonSt>
          </Link>
        )}
      </ContainerWrapperSt>
    </NotAvailableOverlaySt>
  );
};

export default NotAvailableOverlay;
