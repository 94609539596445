import { ReactElement } from 'react';
import {
  LottieAnimationSt,
  MessageSt,
} from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentHeader/EnrichmentHeader.css';
import { usePersonalInfo } from 'app/hooks/usePersonalInfo/usePersonalInfo';
import { capitalize } from 'app/utils/utils';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';

const EnrichmentHeader = (): ReactElement => {
  const plainTranslate = usePlainTranslate();
  const {
    personalInfo: { firstName },
  } = usePersonalInfo();
  return (
    <>
      <LottieAnimationSt
        url="/assets/icons/search-car.json"
        height={75}
        width={100}
        loop
      />
      <MessageSt>
        {plainTranslate({
          id: 'lead.enrichment.welcome.message',
          values: { userFirstName: capitalize(firstName) },
        })}
      </MessageSt>
    </>
  );
};

export { EnrichmentHeader };
