import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { wrapLocalStorage } from 'app/utils/localStorage';
import { IProviderComponent } from 'app/providers/provider.interface';
import { upsertItem } from 'app/hooks/filters/utils';

interface IContactedVehicle {
  vehicleId: string;
  contactedOn: number;
}

interface IContactedVehiclesStorage {
  contactedVehicles: IContactedVehicle[];
}

export interface IContactedVehiclesContextProps {
  contactedVehicles: string[];
  hasBeenContacted: (vehicleId: string) => boolean;
  getContactedOn: (vehicleId: string) => Date;
  markAsContacted: (vehicleId: string, contactedOn?: number) => void;
  markManyAsContacted: (vehicleIds: string[]) => void;
}

export const initialContext = {
  contactedVehicles: [],
  hasBeenContacted: () => false,
  getContactedOn: () => new Date(),
  markAsContacted: () => {},
  markManyAsContacted: () => {},
};

const ContactedVehiclesContext =
  createContext<IContactedVehiclesContextProps>(initialContext);

const storage = wrapLocalStorage<IContactedVehiclesStorage>(
  'persist:contactedVehicles',
);

const emptyValue: IContactedVehiclesStorage = {
  contactedVehicles: [],
};

const ContactedVehiclesProvider = ({
  children,
}: IProviderComponent): ReactElement => {
  const [contactedVehiclesState, setContactedVehiclesState] =
    useState<IContactedVehiclesStorage>(emptyValue);

  useEffect(() => {
    setContactedVehiclesState(storage.init(emptyValue));
    const listener = () =>
      setContactedVehiclesState(storage.get() || emptyValue);
    return storage.addStorageEventListener(listener);
  }, []);

  const contactedVehicles = contactedVehiclesState.contactedVehicles.map(
    v => v.vehicleId,
  );

  return (
    <ContactedVehiclesContext.Provider
      value={{
        contactedVehicles,
        hasBeenContacted,
        getContactedOn,
        markAsContacted,
        markManyAsContacted,
      }}
    >
      {children}
    </ContactedVehiclesContext.Provider>
  );

  function hasBeenContacted(vehicleId: string) {
    return !!findVehicle(vehicleId);
  }

  function getContactedOn(vehicleId: string) {
    return hasBeenContacted(vehicleId)
      ? new Date(findVehicle(vehicleId).contactedOn)
      : null;
  }

  function markAsContacted(vehicleId: string, contactedOn?: number) {
    upsertVehicle(
      newContactedVehicle(vehicleId, contactedOn || currentMillis()),
    );
  }

  function markManyAsContacted(vehicleIds: string[]) {
    upsertVehicles(
      vehicleIds.map(vehicleId =>
        newContactedVehicle(vehicleId, currentMillis()),
      ),
    );
  }

  function findVehicle(vehicleId: string) {
    return contactedVehiclesState.contactedVehicles.find(
      f => f.vehicleId === vehicleId,
    );
  }

  function newContactedVehicle(
    vehicleId: string,
    contactedOn: number,
  ): IContactedVehicle {
    return { vehicleId, contactedOn };
  }

  function currentMillis() {
    return new Date().getTime();
  }

  function upsertVehicle(vehicle: IContactedVehicle) {
    setContactedVehiclesState(oldState => {
      const contactedVehicles = upsertItem(
        oldState.contactedVehicles,
        vehicle,
        (a, b) => a.vehicleId === b.vehicleId,
      );
      storage.patch({ contactedVehicles });
      return { ...oldState, contactedVehicles };
    });
  }
  function upsertVehicles(vehicles: IContactedVehicle[]) {
    setContactedVehiclesState(oldState => {
      const contactedVehicles = vehicles.reduce(
        (result, vehicle) => upsertItem(result, vehicle),
        oldState.contactedVehicles,
      );
      storage.patch({ contactedVehicles });
      return { ...oldState, contactedVehicles };
    });
  }
};

const useContactedVehicles = () => useContext(ContactedVehiclesContext);

export { ContactedVehiclesProvider, useContactedVehicles };
