import styled, { css } from 'styled-components';
import LottieAnimation from 'app/shared/components/LottieAnimation/LottieAnimation';
import { media } from 'app/styles/variables';

export const WrapperSt = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LottieAnimationSt = styled(LottieAnimation)`
  margin: 1.5rem 0 0.5rem 0;
  ${media.sm(css`
    margin: 0;
  `)};
`;
