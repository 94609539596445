import styled, { css } from 'styled-components';
import { breakpoints, media } from 'app/styles/variables';

export const ContentWrapperSt = styled.div`
  display: flex;
  width: 100%;
  max-width: ${breakpoints.xxl}px;
  flex: 1;
  flex-direction: column;
  padding: 0 1rem;

  ${media.sm(css`
    padding: 0 2rem;
  `)};

  ${media.lg(
    css`
      margin: 0 auto;
      padding: 0 4rem;
    `,
  )};
`;
