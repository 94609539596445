export const formatFirstRegistrationDate = dateString => {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${year}`;
};
