import { IProviderComponent } from 'app/providers/provider.interface';
import { createContext, ReactElement, useContext } from 'react';
import { useQuery } from 'react-query';
import countDataQuery from 'app/shared/components/CLPPage/providers/CountProvider/CountProvider.dataQuery';
import { useRouter } from 'next/router';

interface ICountsProviderProps extends IProviderComponent {}

interface ICountContextProps {
  count?: number;
}

export const CountContext = createContext<ICountContextProps>({
  count: null,
});

const { name, fn, options } = countDataQuery;

const CountProvider = ({ children }: ICountsProviderProps): ReactElement => {
  const { query, pathname } = useRouter();

  const { data } = useQuery(
    [name, query, pathname],
    () =>
      fn({
        query,
        from: pathname,
      }),
    options,
  );

  return (
    <CountContext.Provider value={{ count: data?.count }}>
      {children}
    </CountContext.Provider>
  );
};

const useCountContext = () => useContext(CountContext);

export { CountProvider, useCountContext };
