import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FAVORITES_EXPIRES } from 'next-app/app/config';
import { wrapLocalStorage } from 'app/utils/localStorage';
import { toggleListItemSelection } from 'app/hooks/filters/utils';
import { searchByIds, VehicleListingData } from 'app/apiCalls/vehicle';
import { FavoritesOneClickPopup } from 'app/shared/components/FavoritesOneClickPopup/FavoritesOneClickPopup';
import { FavoritesOneClickModal } from 'app/shared/components/FavoritesOneClickModal/FavoritesOneClickModal';
import { getStatus } from 'app/utils/vehicle';
import { IProviderComponent } from 'app/providers/provider.interface';
import { useContactedVehicles } from 'app/providers/ContactedVehiclesProvider/ContactedVehiclesProvider';
import gearBoxes from 'app/containers/CLP/components/SideBarFilters/GearBoxFilter/gearBoxes.json';
import { useTranslate } from 'app/shared/components/Translation/hooks/useTranslate/useTranslate';

interface IFavorite extends IFavoriteData {
  vehicleId: string;
  isD2C: boolean;
}

export interface IFavoriteData {
  make?: string;
  model?: string;
  price?: string;
  mileage?: string;
  year?: string;
  gearBox?: string;
}

interface IFavoriteStorage {
  favorites: IFavorite[];
  popupShownOn: number;
}

export type HandleFavoritesFn = (
  vehicle: VehicleListingData,
  isD2C: boolean,
) => void;

export interface IFavoritesContextProps {
  favoritesIds: Array<string>;
  favoritesData: Array<IFavoriteData>;
  handleFavorites: HandleFavoritesFn;
  showOneClickModal: () => void;
  oneClickFavoritesIds: Array<string>;
}

export const FAVORITES_AMOUNT = {
  SHOW_POPUP: 2,
  MIN_BATCH_SIZE: 1,
  MAX_BATCH_SIZE: 4,
};

export const initialContext = {
  favoritesIds: [],
  favoritesData: [],
  handleFavorites: () => {},
  showOneClickModal: () => {},
  oneClickFavoritesIds: [],
};
const FavoritesProviderContext =
  createContext<IFavoritesContextProps>(initialContext);

const favoriteStorage = wrapLocalStorage<IFavoriteStorage>(
  'persist:favorites',
  FAVORITES_EXPIRES,
);

enum LastAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

const emptyStorage: IFavoriteStorage = { favorites: [], popupShownOn: null };

const FavoritesProvider = ({ children }: IProviderComponent): ReactElement => {
  const translate = useTranslate();
  const { getContactedOn, hasBeenContacted, contactedVehicles } =
    useContactedVehicles();

  const [oneClickModalShown, setOneClickModalShown] = useState(false);
  const [popupShown, setPopupShown] = useState(false);
  const [lastAction, setLastAction] = useState<LastAction>(null);
  const [favoriteState, setFavoriteState] = useState(emptyStorage);

  const gearBoxDisplayNames = useMemo(
    () =>
      gearBoxes.reduce((acc, item) => {
        acc[item.key] = translate({
          id: `shared.components.filter.mappings.gearbox.${item?.displayName}`,
        });
        return acc;
      }, {}),
    [],
  );

  const { oneClickFavoritesIds, favoritesIds, favoritesData } = useMemo(() => {
    const favorites = sortedFavorites();

    const oneClickFavoritesIds = favorites
      .filter(notContactedClassified)
      .map(favorite => favorite.vehicleId);
    const favoritesIds = favorites.map(favorite => favorite.vehicleId);
    const favoritesData = favorites
      .filter(
        favorite =>
          favorite.vehicleId &&
          favorite.make &&
          favorite.model &&
          favorite.price &&
          favorite.mileage &&
          favorite.year &&
          favorite.gearBox,
      )
      .map(favorite => ({
        id: favorite.vehicleId,
        make: favorite.make,
        model: favorite.model,
        price: favorite.price,
        mileage: favorite.mileage,
        year: favorite.year,
        gearBox: favorite.gearBox,
      }));

    return {
      oneClickFavoritesIds,
      favoritesIds,
      favoritesData,
    };
  }, [favoriteState]);

  useEffect(() => {
    loadInitialState();
    const listener = () =>
      setFavoriteState(favoriteStorage.get() || emptyStorage);
    return favoriteStorage.addStorageEventListener(listener);
  }, []);

  useEffect(() => checkClearPopupShown(), [contactedVehicles.length]);
  useEffect(() => checkShowPopup(), [favoriteState.favorites.length]);

  const handleFavorites = (vehicle: VehicleListingData, isD2C: boolean) => {
    toggleFavorite(vehicle, isD2C);
  };

  const showOneClickModal = () => {
    setOneClickModalShown(true);
  };

  const hideOneClickModal = () => {
    setOneClickModalShown(false);
  };

  const hideOneClickPopup = () => {
    setPopupShown(false);
  };

  return (
    <FavoritesProviderContext.Provider
      value={{
        favoritesIds,
        favoritesData,
        handleFavorites,
        showOneClickModal,
        oneClickFavoritesIds,
      }}
    >
      {children}
      <FavoritesOneClickPopup onClose={hideOneClickPopup} show={popupShown} />
      {oneClickModalShown && (
        <FavoritesOneClickModal onClose={hideOneClickModal} />
      )}
    </FavoritesProviderContext.Provider>
  );

  function toggleFavorite(vehicle, isD2C) {
    const result = toggleListItemSelection(
      {
        vehicleId: vehicle?.id,
        make: vehicle?.make?.displayName,
        model: vehicle?.model?.displayName,
        price: vehicle?.price?.toString(),
        mileage: vehicle?.mileage?.toString(),
        year: vehicle?.year?.toString(),
        gearBox: gearBoxDisplayNames[vehicle?.gearBox] || vehicle?.gearBox,
        isD2C,
      },
      favoriteState.favorites,
      (a, b) => a.vehicleId === b.vehicleId,
    );
    setLastAction(
      favoriteState.favorites.length > result.length
        ? LastAction.REMOVE
        : LastAction.ADD,
    );
    updateFavorites(result);
  }

  function updateFavorites(result: IFavorite[]) {
    favoriteStorage.patch({ favorites: result });
    setFavoriteState(oldState => ({ ...oldState, favorites: result }));
  }

  function checkShowPopup() {
    if (shouldLikelyShowPopup()) {
      shouldShowPopup().then(should => {
        if (should) {
          const popupShownOn = currentMillis();
          setPopupShown(true);
          updatePopupShownOn(popupShownOn);
        }
      });
    }
  }

  function updatePopupShownOn(popupShownOn: number) {
    favoriteStorage.patch({ popupShownOn });
    setFavoriteState(oldState => ({ ...oldState, popupShownOn }));
  }

  function shouldLikelyShowPopup() {
    const { popupShownOn } = favoriteState;
    return lastActionIsAdd() && enoughCarsForPopup() && !popupShownOn;
  }

  function shouldShowPopup() {
    return countAvailableFavorites(oneClickFavoritesIds)
      .then(
        availableFavorites => availableFavorites >= FAVORITES_AMOUNT.SHOW_POPUP,
      )
      .catch(() => true);
  }

  function countAvailableFavorites(ids) {
    return searchByIds(ids).then(
      vehicles => vehicles.filter(v => getStatus(v).available).length,
    );
  }

  function lastActionIsAdd() {
    return lastAction === LastAction.ADD;
  }

  function enoughCarsForPopup() {
    const { favorites } = favoriteState;
    return (
      favorites.filter(notContactedClassified).length >=
      FAVORITES_AMOUNT.SHOW_POPUP
    );
  }

  function checkClearPopupShown() {
    if (!favoriteState.popupShownOn) {
      return;
    }
    const favIds = favoriteState.favorites
      .filter(notContactedClassified)
      .map(f => f.vehicleId);
    countAvailableFavorites(favIds).then(availableFavorites => {
      if (availableFavorites === 0) {
        updatePopupShownOn(null);
      }
    });
  }

  function sortedFavorites() {
    const sortedByAdd = favoriteState.favorites.slice().reverse();
    const notContactedCars = sortedByAdd.filter(notContacted);
    const contactedCars = sortedByAdd.filter(contacted).sort(byContactedOn);

    return [...notContactedCars, ...contactedCars];
  }

  function notContacted(favorite: IFavorite) {
    return !contacted(favorite);
  }

  function notContactedClassified(favorite: IFavorite) {
    return notContacted(favorite) && !favorite.isD2C;
  }

  function contacted(favorite: IFavorite) {
    return hasBeenContacted(favorite.vehicleId);
  }

  function byContactedOn(a: IFavorite, b: IFavorite) {
    return (
      getContactedOn(a.vehicleId).getTime() -
      getContactedOn(b.vehicleId).getTime()
    );
  }

  function currentMillis() {
    return new Date().getTime();
  }

  function loadInitialState() {
    const storedFavoriteState = favoriteStorage.init(emptyStorage);
    setFavoriteState(storedFavoriteState);
  }
};

const useFavorites = () => useContext(FavoritesProviderContext);

export { FavoritesProvider, useFavorites };
