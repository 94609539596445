import {
  ContentButtonPlaceholderSt,
  ContentLoadingWrapperSt,
  ContentPlaceholderSt,
  ContentTitlePlaceholderSt,
} from 'app/shared/components/PageWrapper/components/PageTemplate/components/Content/components/ContentLoading/ContentLoading.css';

const ContentLoading = () => {
  return (
    <ContentLoadingWrapperSt>
      <ContentTitlePlaceholderSt />
      <ContentPlaceholderSt />
      <ContentPlaceholderSt />
      <ContentButtonPlaceholderSt />
    </ContentLoadingWrapperSt>
  );
};

export { ContentLoading };
