import { TextInput } from 'app/shared/components/TextInput/TextInput';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useMemo } from 'react';

interface IFirstNameInputProps {
  defaultValue?: string;
  name?: string;
  label?: string;
  rules?: RegisterOptions;
}

const FirstNameInput = ({
  defaultValue = '',
  label = '',
  name = 'firstName',
  rules = {},
}: IFirstNameInputProps) => {
  const plainTranslate = usePlainTranslate();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const rulesInt = useMemo(
    () => ({
      ...rules,
      required: {
        value: !!rules.required,
        message: plainTranslate({
          id: 'shared.components.contactForm.name.error.empty',
        }),
      },
    }),
    [rules],
  );

  const labelInt = useMemo(
    () =>
      `${
        label ||
        plainTranslate({
          id: 'shared.components.contactForm.name',
        })
      } ${rules.required ? '*' : ''}`,
    [label, rules],
  );

  return (
    <TextInput
      name={name}
      qaID={`contactform-input-${name}`}
      value={defaultValue}
      label={labelInt}
      register={register(name, rulesInt)}
      error={errors[name]}
      maxLength={50}
    />
  );
};

export default FirstNameInput;
