import { Container } from './LottieAnimation.css';
import { Player } from '@lottiefiles/react-lottie-player';

interface Props {
  url: string;
  height: number;
  width: number;
  loop?: boolean;
  className?: string;
}

const LottieAnimation = ({
  url,
  height,
  width,
  loop = false,
  className,
}: Props) => {
  return (
    <Container height={height} width={width} className={className}>
      <Player
        autoplay
        keepLastFrame
        loop={loop}
        src={url}
        style={{ height: height, width: width }}
      />
    </Container>
  );
};

export default LottieAnimation;
