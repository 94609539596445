import {
  BlueSt,
  SecondaryTextSt,
  SwitchWrapperSt,
} from 'app/shared/components/LoginRegisterModal/LoginRegisterModal.css';
import Translation from 'app/shared/components/Translation/Translation';

interface ISwitchToLoginProps {
  onClick: (e) => void;
}

export const SwitchToLogin = ({ onClick }: ISwitchToLoginProps) => {
  return (
    <SwitchWrapperSt onClick={onClick}>
      <SecondaryTextSt>
        <BlueSt>
          <Translation id="auth.loginForm.loginSwitch" defaultMessage="Login" />
        </BlueSt>{' '}
        <Translation
          id="auth.loginForm.ifAlreadyHaveAccount"
          defaultMessage="if you already have an account"
        />
      </SecondaryTextSt>
    </SwitchWrapperSt>
  );
};
