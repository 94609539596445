import {
  ContainerSt,
  CopyrightTextSt,
  FooterCopySt,
  FooterListItemSt,
  FooterListSt,
  FooterSt,
  FooterWrapperSt,
} from './Footer.css';
import Translation from 'app/shared/components/Translation/Translation';
import { ReactElement } from 'react';
import { TrustpilotWidget } from 'app/shared/components/PageWrapper/components/PageTemplate/components/Footer/TrustpilotWidget';
import {
  FooterContextProvider,
  useFooterContext,
} from 'app/providers/FooterProvider/FooterProvider';
import { DangerousElement } from 'app/shared/components/DangerousElement/DangerousElement';

const copyrightValues = {
  year: new Date().getFullYear(),
  companyName: 'Mobility Trader Spain S.L.',
};

interface IFooterProps {
  preFooterComponents?: ReactElement;
}

const Footer = ({ preFooterComponents }: IFooterProps) => {
  return (
    <FooterWrapperSt>
      {preFooterComponents}
      <FooterSt>
        <ContainerSt>
          <FooterList />
        </ContainerSt>
        <FooterCopySt>
          <TrustpilotWidget />
          <CopyrightTextSt>
            <Translation
              id="shared.components.footer.copyright"
              defaultMessage="© Copyright {year} {companyName} All rights reserved."
              values={copyrightValues}
            />
          </CopyrightTextSt>
        </FooterCopySt>
      </FooterSt>
    </FooterWrapperSt>
  );
};

const FooterList = () => {
  const { footerData } = useFooterContext();

  if (!footerData) {
    return null;
  }

  return (
    <FooterListSt>
      {footerData?.map(item => (
        <FooterListItemSt key={item}>
          <DangerousElement html={item} />
        </FooterListItemSt>
      ))}
    </FooterListSt>
  );
};

const FooterWithProviders = props => {
  return (
    <FooterContextProvider>
      <Footer {...props} />
    </FooterContextProvider>
  );
};

export { FooterWithProviders as Footer };
