import { useFilterList } from 'app/hooks/filters/filterList';
import { getAllProvinces } from 'app/utils/getProvinces';

interface ProvincesHook {
  provinceList: string[];
  setProvinceList: (province: string) => void;
  clearProvinceList: () => void;
  isProvinceListVisible: boolean;
}

const provinceList = getAllProvinces();

const filterName = 'provincia';
const canonicalPositionName = 'province';

export const useProvince = (isOptimistic = false): ProvincesHook => {
  const toItem = (urlItem: string) =>
    provinceList.find(province => province.key === urlItem)?.value;
  const toUrlItem = (province: string) =>
    provinceList.find(a => a.value === province)?.key || province;
  const { filterList, clearFilterList, setFilterList, isFilterListVisible } =
    useFilterList(
      filterName,
      isOptimistic,
      [],
      canonicalPositionName,
      toItem,
      toUrlItem,
    );

  return {
    provinceList: filterList,
    setProvinceList: setFilterList,
    clearProvinceList: clearFilterList,
    isProvinceListVisible: isFilterListVisible,
  };
};
