import { RecaptchaDisclaimerWrapperSt } from './RecaptchaDisclaimer.css';
import Translation from 'app/shared/components/Translation/Translation';

interface IRecaptchaDisclaimerProps {
  className?: string;
}

export const RecaptchaDisclaimer = ({
  className,
}: IRecaptchaDisclaimerProps) => {
  return (
    <RecaptchaDisclaimerWrapperSt className={className}>
      <Translation
        id="shared.components.recaptchaDisclaimer"
        defaultMessage="Este sitio web está protegido por RECAPTCHA y se aplican Google- {googlePrivacyLink} y - {googleTermsLink}."
      />
    </RecaptchaDisclaimerWrapperSt>
  );
};
