import { AnimatedBgSt } from 'app/shared/components/LoadingPlaceHolder/LoadingPlaceHolder.css';

interface ILoadingPlaceHolderProps {
  className?: string;
}

const LoadingPlaceHolder = ({ className }: ILoadingPlaceHolderProps) => {
  return <AnimatedBgSt className={className} />;
};

export { LoadingPlaceHolder };
