import {
  useState,
  createContext,
  useContext,
  ReactNode,
  ReactElement,
} from 'react';
import { VehicleListingData } from 'app/apiCalls/vehicle';

interface IRecommendationsProviderContextProps {
  recommendedList: VehicleListingData[];
  setRecommendedList: (list: VehicleListingData[]) => void;
}

const RecommendationsProviderContext =
  createContext<IRecommendationsProviderContextProps>({
    recommendedList: null,
    setRecommendedList: () => {},
  });

const ListingsProvider = ({
  children,
  defaultnewtonRecommendation,
}: {
  children: ReactNode;
  defaultnewtonRecommendation?: VehicleListingData[];
}): ReactElement => {
  const [recommendedList, setRecommendedList] = useState(
    defaultnewtonRecommendation,
  );

  return (
    <RecommendationsProviderContext.Provider
      value={{ recommendedList, setRecommendedList }}
    >
      {children}
    </RecommendationsProviderContext.Provider>
  );
};

const useListings = () => useContext(RecommendationsProviderContext);

export { ListingsProvider, useListings };
