export const PrimaryType = 'primary';
export const TertiaryType = 'tertiary';
export const WhatsappType = 'whatsapp';

export const LinkType = 'link';
export const ContainedType = 'contained';
export const OutlinedType = 'outlined';

export type ButtonColor =
  | typeof PrimaryType
  | typeof TertiaryType
  | typeof WhatsappType;

export type ButtonVariant =
  | typeof LinkType
  | typeof ContainedType
  | typeof OutlinedType;
