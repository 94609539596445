import HeycarLogo from '@heycar-uikit/logo';
import { LogoWrapperSt } from 'app/shared/components/LoginRegisterModal/LoginRegisterModal.css';

export const DefaultLoginRegisterLogo = () => {
  return (
    <LogoWrapperSt>
      <HeycarLogo color="secondary" fontSize={120} />
    </LogoWrapperSt>
  );
};
