import { Modal } from 'app/shared/components/Modal/Modal';
import { LeadEnrichmentProvider } from 'app/providers/LeadEnrichmentProvider';
import { MassEmailForm } from 'app/shared/components/ContactForm/components/MassEmailForm/MassEmailForm';
import { VehicleListingData } from 'app/apiCalls/vehicle';

interface IMassEmailDealerModalProps {
  isVisible: boolean;
  onClose: (e) => void;
  vehicles: VehicleListingData[];
  onRequestSent?: (vehicleIds: string[]) => void;
}

const MassEmailDealerModal = ({
  isVisible,
  onClose,
  vehicles,
}: IMassEmailDealerModalProps) => {
  return (
    <Modal
      show={isVisible}
      onClose={onClose}
      minHeight="45vh"
      maxHeight="80vh"
      width={554}
    >
      <LeadEnrichmentProvider>
        <MassEmailForm vehicles={vehicles} />
      </LeadEnrichmentProvider>
    </Modal>
  );
};

export default MassEmailDealerModal;
