import PostalCodePrompterModal from 'app/providers/PostalCodePrompterProvider/components/PostalCodePrompterModal/PostalCodePrompterModal';
import Translation from 'app/shared/components/Translation/Translation';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import PostalCodeInput from 'app/shared/components/PostalCodeInput/PostalCodeInput';
import { Button } from 'app/shared/components/Button/Button';
import { usePostalCodePrompterContext } from 'app/providers/PostalCodePrompterProvider/PostalCodePrompterProvider';
import { TitleSt } from './PostalCodePrompterInputModal.css';

interface IPostalCodePrompterInputModalProps {
  onSavePostalCode: Function;
  onClose: () => void;
}

const PostalCodePrompterInputModal = ({
  onSavePostalCode,
  onClose,
}: IPostalCodePrompterInputModalProps) => {
  const methods = useForm();

  const onSearchButtonClick = () => {
    methods.handleSubmit(data => triggerSavePostalCode(data))();
  };

  const onSubmit = data => {
    triggerSavePostalCode(data);
  };

  return (
    <PostalCodePrompterModal
      width={330}
      onClose={onClose}
      title={
        <TitleSt>
          <Translation
            id="postalCodePrompter.input.title"
            defaultMessage="Add your postal code to search vehicles next to you"
          />
        </TitleSt>
      }
      content={<PostalCodeForm methods={methods} onSubmit={onSubmit} />}
      buttons={
        <SearchVehiclesButton type="submit" onClick={onSearchButtonClick} />
      }
    />
  );

  function triggerSavePostalCode(data) {
    onSavePostalCode(data.postalCode);
  }
};

interface IPostalCodeFormProps {
  methods: UseFormReturn;
  onSubmit: (data) => void;
}

const PostalCodeForm = ({ methods, onSubmit }: IPostalCodeFormProps) => {
  const { data } = usePostalCodePrompterContext();
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PostalCodeInput rules={{ required: data?.postalCodeRequired }} />
      </form>
    </FormProvider>
  );
};

const SearchVehiclesButton = props => {
  return (
    <Button {...props} alwaysFullWidth>
      <Translation
        id="postalCodePrompter.input.button"
        defaultMessage="Search vehicles"
      />
    </Button>
  );
};

export default PostalCodePrompterInputModal;
