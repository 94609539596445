import {
  getOneClickRecommendations,
  VehicleListingData,
} from 'app/apiCalls/vehicle';
import { useQuery } from 'react-query';

const QUERY_NAME = 'fetchOneClickListings';

const options = {
  refetchOnWindowFocus: false,
};

export const useOneClickListings = (
  vehicle?: VehicleListingData,
  enabled = true,
  disableWiderRange?: boolean,
) => {
  const vehicleId = vehicle?.id;
  return useQuery(
    [QUERY_NAME, vehicleId],
    () =>
      getOneClickRecommendations({
        listingId: vehicleId,
        disableWiderRange,
      }),
    {
      ...options,
      enabled: vehicleId && enabled,
    },
  );
};
