import { ReactElement, useEffect, useState } from 'react';
import { EnrichmentHeader } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentHeader/EnrichmentHeader';
import { EnrichmentChoiceQuestion } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentChoiceQuestion/EnrichmentChoiceQuestion';
import { WrapperSt } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentForm.css';
import {
  ANSWERS_MULTIPLE_CHOICE_TRACK_VALUE,
  EnrichmentQuestionEvents,
  EnrichmentQuestionId,
  IDoubleTextQuestion,
  IMultipleChoiceQuestion,
  QUESTION_LIST,
  QuestionIdStringMap,
  QuestionTypes,
} from './enrichment-questions';
import { EnrichmentThankMessage } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentThankMessage/EnrichmentThankMessage';
import { EnrichmentDoubleQuestion } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentDoubleQuestion/EnrichmentDoubleQuestion';
import { enrichLeads } from 'app/apiCalls/enrichment';
import { useLeadEnrichment } from 'app/providers/LeadEnrichmentProvider';
import { useServices } from 'app/providers/ServicesProvider';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { useTrackingPageContext } from 'app/shared/components/PageWrapper/providers/TrackingPageProvider/TrackingPageProvider';
import { scrollIntoView } from 'app/utils/utils';

const EnrichmentForm = (): ReactElement => {
  const plainTranslate = usePlainTranslate();
  const { trackAction } = useTrackingPageContext();
  const { createdLeads, interestedInFinancing, oneClickWasUsed } =
    useProviders();
  const { answers, setAnswers, progress, setProgress } = createState();
  const { displayedQuestions, skippedQuestions, finished } =
    getQuestions(progress);

  useEffect(scrollToNextElement, [progress, finished]);
  useEffect(automaticSendQuestionsToSkip, []);

  return (
    <WrapperSt>
      <EnrichmentHeader />
      {displayedQuestions}
      {finished && <EnrichmentThankMessage />}
    </WrapperSt>
  );

  function useProviders() {
    return { ...useLeadEnrichment(), ...useServices() };
  }

  function createState() {
    const [answers, setAnswers] = useState<QuestionIdStringMap>({});
    const [progress, setProgress] = useState(1);
    return { answers, setAnswers, progress, setProgress };
  }

  function getQuestions(progress) {
    const displayedQuestions = getDisplayedQuestions();
    return {
      displayedQuestions: displayedQuestions,
      skippedQuestions: getSkippedQuestions(),
      finished: progress > displayedQuestions.length,
    };
  }

  function getDisplayedQuestions() {
    const questionsToShow = getUnSkippedQuestions().filter(
      question => !question.condition || question.condition(answers),
    );

    return questionsToShow
      .slice(0, progress)
      .map((question, index) => getQuestionElement(question, index + 1));
  }

  function getSkippedQuestions() {
    return QUESTION_LIST.filter(shouldSkip);
  }

  function getUnSkippedQuestions() {
    return QUESTION_LIST.filter(question => !shouldSkip(question));
  }

  function getQuestionElement(
    question: IMultipleChoiceQuestion | IDoubleTextQuestion,
    questionNumber: number,
  ) {
    switch (question.type) {
      case QuestionTypes.MULTIPLE_CHOICE:
        return getChoiceQuestion(question, questionNumber);
      case QuestionTypes.DOUBLE_TEXT:
        return getDoubleTextQuestion(question, questionNumber);
    }
  }

  function shouldSkip(question: IDoubleTextQuestion | IMultipleChoiceQuestion) {
    const financingSkip = interestedInFinancing
      ? isFinancingQuestion(question)
      : false;
    const modelsSkip = oneClickWasUsed ? isModelsQuestion(question) : false;
    return financingSkip || modelsSkip;
  }

  function getChoiceQuestion(
    question: IMultipleChoiceQuestion,
    questionNumber,
  ) {
    const unconditionalQuestions = getUnSkippedAndUnconditionalQuestions().map(
      question => question.id,
    );
    const isUnconditionalQuestion = unconditionalQuestions.includes(
      question.id,
    );
    const progressText = isUnconditionalQuestion
      ? getProgressText(question, unconditionalQuestions)
      : null;
    return (
      <EnrichmentChoiceQuestion
        id={`question-${questionNumber}`}
        key={question.text}
        question={question.text}
        answers={question.answers}
        progress={progressText}
        onSelectedAnswer={answer => sendAnswer(question, answer)}
      />
    );
  }

  function getDoubleTextQuestion(
    question: IDoubleTextQuestion,
    questionNumber,
  ) {
    return (
      <EnrichmentDoubleQuestion
        id={`question-${questionNumber}`}
        key={question.text}
        question={question}
        onAnswerSubmitted={(firstAnswer, secondAnswer) =>
          sendDoubleAnswer(question, firstAnswer, secondAnswer)
        }
      />
    );
  }

  function getUnSkippedAndUnconditionalQuestions() {
    return getUnSkippedQuestions().filter(question => !question.condition);
  }

  function isFinancingQuestion(
    question: IDoubleTextQuestion | IMultipleChoiceQuestion,
  ) {
    return question.id === EnrichmentQuestionId.V1_INTERESTED_IN_FINANCIAL_INFO;
  }

  function isModelsQuestion(
    question: IDoubleTextQuestion | IMultipleChoiceQuestion,
  ) {
    return question.id === EnrichmentQuestionId.V1_INTERESTED_IN_SIMILAR_MODELS;
  }

  function getProgressText(
    question: IMultipleChoiceQuestion | IDoubleTextQuestion,
    questions: EnrichmentQuestionId[],
  ) {
    return `${questions.indexOf(question.id) + 1}/${questions.length}`;
  }

  function scrollToNextElement() {
    const nextElementId = finished
      ? `enrichment-thank-you`
      : `question-${progress}`;
    scrollIntoView(nextElementId);
  }

  function automaticSendQuestionsToSkip() {
    skippedQuestions.forEach(question => {
      switch (question.type) {
        case QuestionTypes.MULTIPLE_CHOICE:
          skipQuestion(question);
          break;
        case QuestionTypes.DOUBLE_TEXT:
          skipDoubleQuestion(question);
          break;
        default:
          break;
      }
    });
  }

  function skipQuestion(question: IMultipleChoiceQuestion) {
    const skipAnswer = question.skipAnswer;
    storeAnswer(question.id, skipAnswer);
    trackAnswer(question.id, getMultipleChoiceTrackValue(skipAnswer));
    apiSend(question.id, translateAnswer(skipAnswer)).catch(err =>
      console.warn('One automatic question answer failed to be sent', err),
    );
  }

  function skipDoubleQuestion(question: IDoubleTextQuestion) {
    const firstSkipAnswer = question.firstInput.skipAnswer;
    const secondSkipAnswer = question.secondInput.skipAnswer;
    storeAnswer(question.id, firstSkipAnswer);
    storeAnswer(question.secondId, secondSkipAnswer);
    trackDoubleAnswer(question, firstSkipAnswer, secondSkipAnswer);
    apiSend(question.id, firstSkipAnswer).catch(err =>
      console.warn('One automatic question answer failed to be sent', err),
    );
    apiSend(question.secondId, secondSkipAnswer).catch(err =>
      console.warn('One automatic question answer failed to be sent', err),
    );
  }

  function sendAnswer(question: IMultipleChoiceQuestion, givenAnswer: string) {
    trackAnswer(question.id, getMultipleChoiceTrackValue(givenAnswer));
    return apiSend(question.id, translateAnswer(givenAnswer)).then(() => {
      storeAnswer(question.id, givenAnswer);
      setProgress(p => p + 1);
    });
  }

  function sendDoubleAnswer(
    question: IDoubleTextQuestion,
    firstAnswer: string,
    secondAnswer: string,
  ) {
    const apiCalls = [];
    if (firstAnswer) {
      apiCalls.push(apiSend(question.id, firstAnswer));
    }
    if (secondAnswer) {
      apiCalls.push(apiSend(question.secondId, secondAnswer));
    }
    trackDoubleAnswer(question, firstAnswer, secondAnswer);
    return Promise.all(apiCalls).then(() => {
      storeAnswer(question.id, firstAnswer);
      storeAnswer(question.secondId, secondAnswer);
      setProgress(p => p + 1);
    });
  }

  function storeAnswer(questionId: EnrichmentQuestionId, answer: string) {
    setAnswers(oldAnswers => ({ ...oldAnswers, [questionId]: answer }));
  }

  function getMultipleChoiceTrackValue(answer: string) {
    return ANSWERS_MULTIPLE_CHOICE_TRACK_VALUE[answer];
  }

  function translateAnswer(answer: string) {
    return plainTranslate({ id: answer });
  }

  function apiSend(questionId: EnrichmentQuestionId, translatedAnswer: string) {
    return enrichLeads(createdLeads, questionId, translatedAnswer).catch(() => {
      throw 'lead.enrichment.questions.sendError';
    });
  }

  function trackAnswer(questionId: EnrichmentQuestionId, value: string) {
    const eventName = EnrichmentQuestionEvents[questionId];
    trackAction(eventName, { value });
  }

  function trackDoubleAnswer(
    question: IDoubleTextQuestion,
    firstAnswer: string,
    secondAnswer: string,
  ) {
    if (firstAnswer) {
      trackAnswer(
        question.id,
        `${firstAnswer}${question.firstInput.unit || ''}`,
      );
    }
    if (secondAnswer) {
      trackAnswer(
        question.secondId,
        `${secondAnswer}${question.secondInput.unit || ''}`,
      );
    }
  }
};

export { EnrichmentForm };
