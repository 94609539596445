import { Modal } from 'app/shared/components/Modal/Modal';
import {
  TitleSt,
  ContentSt,
  ButtonsSt,
  ContainerSt,
} from './PostalCodePrompterModal.css';

interface IPostalCodePrompterModalProps {
  title: JSX.Element;
  content: JSX.Element;
  buttons: JSX.Element;
  width: number;
  onClose: () => void;
}

const PostalCodePrompterModal = ({
  title,
  content,
  buttons,
  width,
  onClose,
}: IPostalCodePrompterModalProps) => {
  return (
    <Modal onClose={onClose} width={width} show>
      <ContainerSt>
        <TitleSt>{title}</TitleSt>
        <ContentSt>{content}</ContentSt>
        <ButtonsSt>{buttons}</ButtonsSt>
      </ContainerSt>
    </Modal>
  );
};

export default PostalCodePrompterModal;
