import { PageTemplateSt } from 'app/shared/components/PageWrapper/PageWrapper.css';
import { Content } from 'app/shared/components/PageWrapper/components/PageTemplate/components/Content/Content';
import { Footer } from 'app/shared/components/PageWrapper/components/PageTemplate/components/Footer/Footer';
import { ReactElement, ReactNode } from 'react';
import { NewHeader } from './components/NewHeader/NewHeader';

interface IPageTemplateProps {
  children?: ReactNode;
  hasFooter?: boolean;
  isLoading?: boolean;
  isMobileFullWidth?: boolean;
  noPageWrapper?: boolean;
  url?: string;
  preFooterComponents?: ReactElement;
  pageId?: string;
  withAuth?: boolean;
}

const PageTemplate = ({
  children,
  isLoading,
  hasFooter = true,
  preFooterComponents,
  noPageWrapper,
  withAuth = false,
}: IPageTemplateProps) => {
  return (
    <PageTemplateSt>
      <NewHeader />
      {/* <NavBar /> */}
      <Content noPageWrapper={noPageWrapper} withAuth={withAuth}>
        {children}
      </Content>
      {!isLoading && hasFooter && (
        <Footer preFooterComponents={preFooterComponents} />
      )}
    </PageTemplateSt>
  );
};

export { PageTemplate };
