import { ReactElement, useCallback } from 'react';
import {
  LottieAnimationSt,
  MessageSt,
} from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentThankMessage/EnrichmentThankMessage.css';
import { Button } from 'app/shared/components/Button/Button';
import { useModalContext } from 'app/providers/ModalProvider';
import { EnrichmentAnimatedContainer } from 'app/shared/components/ContactForm/components/EnrichmentForm/EnrichmentAnimatedContainer/EnrichmentAnimatedContainer';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import { useTrackingPageContext } from 'app/shared/components/PageWrapper/providers/TrackingPageProvider/TrackingPageProvider';

const EnrichmentThankMessage = (): ReactElement => {
  const plainTranslate = usePlainTranslate();
  const { closeModal } = useModalContext();
  const { trackAction } = useTrackingPageContext();
  const onOkButtonClicked = useCallback(
    e => {
      trackAction('backbutton_lead_enrichment_form');
      closeModal(e);
    },
    [trackAction],
  );
  return (
    <EnrichmentAnimatedContainer id="enrichment-thank-you">
      <LottieAnimationSt
        url="/assets/icons/checked-icon.json"
        height={40}
        width={40}
      />
      <MessageSt>
        {plainTranslate({ id: 'lead.enrichment.thankYou.message' })}
      </MessageSt>
      <Button alwaysFullWidth onClick={onOkButtonClicked}>
        {plainTranslate({ id: 'lead.enrichment.thankYou.okButton' })}
      </Button>
    </EnrichmentAnimatedContainer>
  );
};

export { EnrichmentThankMessage };
