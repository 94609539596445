import styled from 'styled-components';
import { Typography } from 'app/shared/components/Typography/Typography';
import { SpanTypography } from 'app/shared/components/Typography/SpanTypography';

interface IFieldStProps {
  error?: Error;
}

export const FieldSt = styled.div<IFieldStProps>`
  position: relative;
  display: flex;
  flex-flow: column-reverse;
  justify-content: flex-end;
`;

export const ErrorMessageSt = styled.div`
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.error500};
  font-size: 0.75rem;
`;

export const UnitSt = styled(Typography).attrs({
  variant: 'caption2',
  Component: 'div',
})`
  position: absolute;
  right: 0;
  top: 1.6125rem;
  color: ${({ theme }) => theme.colors.neutral500};
`;

interface ILabelStProps {
  error?: Error;
}

export const LabelSt = styled.label<ILabelStProps>`
  height: 1.25rem;
  margin-left: 1rem;
  transition: all 0.2s;
  touch-action: manipulation;
  color: ${({ error, theme }) =>
    error ? theme.colors.error500 : theme.colors.neutral500};
  cursor: text;
  white-space: nowrap;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 1.5rem) scale(1);
  pointer-events: none;
`;

export const LabelTextSt = styled(SpanTypography).attrs({
  variant: 'body4',
})``;
