import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';
import { Image } from 'app/shared/components/Image/Image';
import { Button } from 'app/shared/components/Button/Button';
import { NonGutterDialogContentSt } from 'app/shared/components/Modal/Modal.css';
import { IconWrapper } from 'app/shared/components/IconWrapper/IconWrapper';

interface IContactFormWrapperProps {
  interestedInOCA?: boolean;
}

export const ContactFormWrapperSt = styled.div`
  ${media.sm(css`
    width: 100%;
    align-self: center;
  `)};
`;

export const FormSt = styled.form<IContactFormWrapperProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: calc(1.25rem - 8px) 1.25rem;
  grid-template-areas:
    'firstName firstName firstName firstName'
    'lastName lastName lastName lastName'
    'email email email email'
    'phoneNumber phoneNumber phoneNumber phoneNumber';
  & > :first-child {
    grid-area: firstName;
  }

  & > :nth-child(2) {
    grid-area: lastName;
  }

  & > :nth-child(3) {
    grid-area: email;
  }

  & > :nth-child(4) {
    grid-area: phoneNumber;
    ${({ interestedInOCA }) =>
      interestedInOCA &&
      css`
        margin-bottom: 1rem;
      `}
  }

  & > :nth-child(n + 5) {
    grid-column: 1/5;
  }

  ${media.sm(css`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'firstName firstName lastName lastName'
      'email email phoneNumber phoneNumber';
    grid-gap: 1.25rem 1.5rem;

    & > :last-child {
      position: static;
      width: auto;
    }
  `)};
`;

export const IconWrapperSt = styled(IconWrapper)`
  margin: 0.25rem 0.25rem -2px 0.25rem;
  border-bottom-color: ${({ theme }) => theme.colors.secondary};
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding: 0 2px 1px 2px;
  box-sizing: content-box;
`;

export const ImageSt = styled(Image)`
  margin: 0.25rem 0.25rem -2px 0.25rem;
  border-bottom-color: ${({ theme }) => theme.colors.secondary};
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding: 0 2px 2px 2px;
  box-sizing: content-box;
`;

export const ButtonSt = styled(Button)`
  display: flex;
`;

export const DealerOpeningHoursTableSt = styled.div`
  display: table;
  margin-top: 0.5rem;
  line-height: 1.71;
  font-size: 0.875rem;
`;

export const DealerOpeningHoursTableRowSt = styled.div`
  display: table-row;
  line-height: 2rem;
  width: 100%;
`;

export const DealerOpeningHoursTableCellSt = styled.div<{ color: string }>`
  display: table-cell;
  padding: 0.125rem 0.75rem;
  color: ${({ color: fontColor, theme }) => theme.colors[fontColor]};
`;

export const DealerNameTitleSt = styled.div`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.black};
`;

export const CallDealerLinkSt = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-decoration: none;
  &:hover,
  &:active,
  &:visited {
    color: white;
  }
  ${media.sm(css`
    color: ${({ theme }) => theme.colors.neutral800};

    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.neutral800};
    }
  `)};
`;

export const DealerPhoneErrorSt = styled.div`
  line-height: ${({ theme }) => theme.lineHeights.h6};
  color: ${({ theme }) => theme.colors.error500};
`;

export const SubTitleSt = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 1.5rem;
  margin: 1rem auto 0 auto;
  color: ${({ theme }) => theme.colors.black};
  max-width: fit-content;
`;

export const TitleSt = styled.span<IContactFormWrapperProps>`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  ${({ interestedInOCA, theme }) =>
    interestedInOCA &&
    css`
      font-size: ${theme.fontSizes.h4};
    `}
`;

export const ContactDealerTabButtonSt = styled.div`
  margin-bottom: 1rem;
`;

export const SuccessSecondaryTextSt = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  ${media.sm(css`
    margin-bottom: 1.25rem;
    line-height: 2rem;
  `)};
`;

export const SuccessTextSt = styled.div`
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  ${media.sm(css`
    margin: 0.5rem 0 1.25rem;
  `)};
`;

export const DesktopContactView = styled.div`
  display: none;
  margin: 1rem 0;
  line-height: 1rem;
  ${media.sm(css`
    display: flex;
  `)};
`;

export const MobileContactView = styled.div`
  display: flex;
  margin: 0.5rem 0;

  ${media.sm(css`
    display: none;
  `)};
`;

export const DateTimeContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
`;

export const TimeContainer = styled(DateContainer)``;

export const SubmitFormStickyWrapperSt = styled(NonGutterDialogContentSt)`
  display: grid;
  position: sticky;
  text-align: center;
  bottom: 0.5rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.white};
  margin-top: 0.5rem;
`;
