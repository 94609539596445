import { ReactElement, useEffect } from 'react';
import Translation from 'app/shared/components/Translation/Translation';
import {
  Divider,
  ExtraFormCheckBoxesSt,
  LinksContainerCheckboxSt,
  LinksContainerSt,
  PrivacyPolicyLinkSt,
  SpecialsFilterCheckboxLabelSt,
  SpecialsFilterInfoIconSt,
  PopupHeaderTextSt,
  PopupSubHeaderTextSt,
  SpecialsFilterTextSt,
  FinancialPopupContentSt,
  FinancialPopupDisclaimerSt,
  CheckboxSt,
} from './ExtraFormCheckBoxes.css';
import Link from 'app/shared/components/Link/Link';
import { useUrl } from 'app/hooks/useUrl/useUrl';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { useOneClickListings } from '../OneClickListings/OneClickListings.dataQuery';
import { useListings } from 'app/providers/RecommendationsListingsProvider';
import { useNewtonRecEnabled } from 'app/hooks/featureToggles/enableNewtonRecommendations';
import { useRecommendationSecondStepDisabled } from 'app/hooks/featureToggles/useRecommendationSecondStepDisabled';
import { useTranslate } from 'app/shared/components/Translation/hooks/useTranslate/useTranslate';
import { useTrackingPageContext } from 'app/shared/components/PageWrapper/providers/TrackingPageProvider/TrackingPageProvider';

interface ExtraFormCheckBoxesProps {
  className?: string;
  interestedInFinancing?: boolean;
  privacyConsent: boolean;
  marketingConsent: boolean;
  highlightPrivacyLink: boolean;
  handleSetPrivacyConsent?: Function;
  handleSetMarketingConsent?: Function;
  handleInterestedInFinancing?: Function;
  handleSetNewtonRecom?: Function;
  showFinancingCheckbox?: boolean;
  messageSplitPerLine?: any[];
  isFromOnlineReservationForm?: boolean;
  newtonRecommendations?: boolean;
  vehicle?: VehicleListingData;
  interestedInOCA?: boolean;
}

const ExtraFormCheckBoxes = ({
  className,
  privacyConsent = false,
  marketingConsent = false,
  highlightPrivacyLink = false,
  handleSetPrivacyConsent,
  handleSetMarketingConsent,
  interestedInFinancing,
  handleInterestedInFinancing,
  showFinancingCheckbox,
  messageSplitPerLine = [],
  isFromOnlineReservationForm = false,
  newtonRecommendations = false,
  handleSetNewtonRecom,
  vehicle,
  interestedInOCA,
}: ExtraFormCheckBoxesProps): ReactElement => {
  const translate = useTranslate();
  const privacyPolicyUrl = useUrl('privacy');
  const classifiedJumpLink = '#clasificados';

  const { setRecommendedList } = useListings();
  const newtonRecomEnabled = useNewtonRecEnabled();
  const isRecommendationsSecondStepDisabled =
    useRecommendationSecondStepDisabled();
  const { trackAction } = useTrackingPageContext();

  const { data: listings } = useOneClickListings(
    vehicle,
    !isFromOnlineReservationForm,
    isRecommendationsSecondStepDisabled || null,
  );

  useEffect(() => {
    if (listings) setRecommendedList(listings?.vehicles);
  }, [listings]);

  const newtonsRecommendationLabel = (
    translate({
      id: `shared.components.details.newtonRecommendations.interest`,
      defaultMessage: 'I want to send info request for similar cars.',
    }) as string
  ).split('|');

  const labelLinkBuilder = (
    <>
      {newtonsRecommendationLabel[0]}
      <Link href={`${newtonsRecommendationLabel[2]}`} blank>
        {newtonsRecommendationLabel[1]}
      </Link>
    </>
  );

  const newtonsRecomPopup = (
    <>
      <PopupHeaderTextSt>
        <Translation
          id="shared.components.filter.popup.specials.newton.header"
          defaultMessage="I want to send info request for similar cars."
        />
      </PopupHeaderTextSt>
      <PopupSubHeaderTextSt>
        <Translation
          id="shared.components.filter.popup.specials.newton.subheader"
          defaultMessage="I give my consent for heycar to find up to 5 cars that match my search criteria."
        />
      </PopupSubHeaderTextSt>
    </>
  );

  const calculationSummary = (
    <>
      <PopupHeaderTextSt>
        <Translation
          id="shared.components.filter.popup.specials.finance.header"
          defaultMessage="Im interested in Financing"
        />
      </PopupHeaderTextSt>
      {messageSplitPerLine.map((item, index) =>
        index == 0 ? (
          <FinancialPopupDisclaimerSt key={index}>
            {item}
          </FinancialPopupDisclaimerSt>
        ) : (
          <FinancialPopupContentSt key={index}>{item}</FinancialPopupContentSt>
        ),
      )}
    </>
  );

  const newtonsRecommCheck =
    listings?.vehicles?.length > 0 &&
    !isFromOnlineReservationForm &&
    newtonRecomEnabled;

  return (
    <ExtraFormCheckBoxesSt
      className={className}
      isFromOnlineReservationForm={isFromOnlineReservationForm}
    >
      {newtonsRecommCheck && !interestedInOCA && (
        <LinksContainerSt backgroundType="sunbeam">
          <CheckboxSt
            name={'newtonRecommendations'}
            label={
              <SpecialsFilterCheckboxLabelSt>
                <SpecialsFilterTextSt>{labelLinkBuilder}</SpecialsFilterTextSt>
              </SpecialsFilterCheckboxLabelSt>
            }
            info={
              <SpecialsFilterInfoIconSt
                text={newtonsRecomPopup}
                handleOpenInfoPopup={() =>
                  trackAction('clp_dealer_filter_info', {
                    description:
                      'User clicks specials filter info icon for Newtons Recommendations',
                    value: 'newton_recommendations',
                  })
                }
                specialFilters
                leadForm
              />
            }
            isLeadForm
            qaID="contactform-newton-recommendations-input"
            checked={newtonRecommendations}
            onChange={value => {
              handleSetNewtonRecom(!value);
            }}
          />
        </LinksContainerSt>
      )}
      {showFinancingCheckbox && (
        <>
          <LinksContainerCheckboxSt
            isFromOnlineReservationForm={isFromOnlineReservationForm}
            backgroundType="mint3"
          >
            <CheckboxSt
              name={'financingOption'}
              label={
                <SpecialsFilterCheckboxLabelSt>
                  <Translation
                    id="containers.pdp.details.financing.interest"
                    defaultMessage="Interested in financing for this car"
                  />
                </SpecialsFilterCheckboxLabelSt>
              }
              info={
                <SpecialsFilterInfoIconSt
                  text={calculationSummary}
                  handleOpenInfoPopup={() =>
                    trackAction('financing_interest_info', {
                      description: 'User clicks financing interest info icon',
                      value: 'financing_interest',
                    })
                  }
                  specialFilters
                  leadForm
                />
              }
              isLeadForm
              qaID="contactform-interesting-in-finance-input"
              checked={interestedInFinancing}
              onChange={value => {
                handleInterestedInFinancing(!value);
              }}
              isFromOnlineReservationForm={isFromOnlineReservationForm}
            />
          </LinksContainerCheckboxSt>
        </>
      )}
      {!isFromOnlineReservationForm &&
        !interestedInOCA &&
        (showFinancingCheckbox || newtonsRecommCheck) && <Divider />}
      <LinksContainerSt>
        <CheckboxSt
          name={'marketingConsent'}
          label={
            <Translation
              id="shared.components.contactForm.message.marketingPolicy"
              defaultMessage="Autorizo a heycar al envío de comunicaciones comerciales."
            />
          }
          qaID="contactform-marketing-consent-input"
          checked={marketingConsent}
          onChange={value => {
            handleSetMarketingConsent(!value);
          }}
          isFromOnlineReservationForm={isFromOnlineReservationForm}
        />
      </LinksContainerSt>
      <LinksContainerSt>
        <PrivacyPolicyLinkSt id="error-privacy-consent">
          <CheckboxSt
            name={'privacyConsent'}
            danger={highlightPrivacyLink}
            label={
              <Translation
                id="shared.components.contactForm.message.privacyPolicy"
                values={{
                  link: (
                    <Link
                      href={`${privacyPolicyUrl}${classifiedJumpLink}`}
                      blank
                    >
                      <Translation id="shared.components.contactForm.message.privacyPolicyLink" />
                    </Link>
                  ),
                  d2cTermsLink: '',
                }}
              />
            }
            qaID="contactform-privacy-consent-input"
            checked={privacyConsent}
            onChange={value => {
              handleSetPrivacyConsent(!value);
            }}
          />
        </PrivacyPolicyLinkSt>
      </LinksContainerSt>
    </ExtraFormCheckBoxesSt>
  );
};

export { ExtraFormCheckBoxes };
