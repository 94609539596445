import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IProviderComponent } from 'app/providers/provider.interface';
import { authLogin, authLogout, authRefreshToken } from 'app/apiCalls/auth';
import { useLocalStorage } from 'app/hooks/useLocalStorage/useLocalStorage';
import { createProfile, getProfile, updateProfile } from 'app/apiCalls/profile';
import { unixTimeSeconds } from 'app/utils/dates';

export interface IContextType {
  login?: (email: string, password: string) => Promise<IAuthData>;
  logout?: Function;
  register?: (email: string, password: string) => Promise<IAuthData>;
  update?: (userInfo: IAuthDataUserInfo) => Promise<void>;
  isLogged?: () => boolean;
  authData?: IAuthData | null;
}

interface IAuthData {
  token: IAuthDataToken;
  userInfo?: IAuthDataUserInfo;
}

interface IAuthDataToken {
  accessToken: string;
  refreshToken: string;
  expiresOn: number;
  refreshExpiresOn: number;
}

export interface IAuthDataUserInfo {
  email: string;
  firstName: string;
  lastName: string;
  secondLastName?: string;
  phoneNumber?: string;
}

export interface IAuthStorage extends IAuthData {}

export const AuthContext = createContext<IContextType>({
  login: () => Promise.resolve(null),
  logout: () => {},
  register: () => Promise.resolve(null),
  update: () => Promise.resolve(null),
  isLogged: () => false,
  authData: null,
});

const emptyAuthData: IAuthData = null;

interface IAuthProviderProps extends IProviderComponent {}

const AuthProvider = ({ children }: IAuthProviderProps): ReactElement => {
  const storage = useLocalStorage<IAuthStorage>({ id: 'persist:auth' });
  const [authData, setAuthData] = useState<IAuthData>();

  useEffect(() => {
    const currentAuthData = storage.init(emptyAuthData);
    setAuthData(currentAuthData);
    checkLoginStatus(currentAuthData);
  }, []);

  useEffect(() => {
    const listener = () => setAuthData(storage.get() || emptyAuthData);
    return storage.addStorageEventListener(listener);
  }, []);

  useEffect(() => {
    if (isRefreshTokenValid(authData)) {
      return scheduleTokenRefresh();
    }
  }, [authData]);

  const login = async (email: string, password: string) => {
    const loginResult = await performLogin(email, password);
    const authData: IAuthData = saveTokenData(loginResult);
    const userInfo = await performRetrieveUserInfo();
    const authDataWithUserInfo: IAuthData = {
      ...authData,
      userInfo: { ...userInfo },
    };
    updateAuthData(authDataWithUserInfo);
    return authDataWithUserInfo;
  };

  const logout = async (tokenData?) => {
    // If for some reason the logout fails we ignore the error and clear the auth data
    await performLogout(tokenData).catch(() => {});
    clearAuthData();
  };

  const register = async (email: string, password: string) => {
    await performRegister(email, password);
    return login(email, password);
  };

  const update = useCallback(
    async (userInfo: IAuthDataUserInfo) => {
      await performUpdateUserInfo(userInfo);
      updateUserInfo(userInfo);
    },
    [authData],
  );

  const isLogged = useCallback(() => {
    return !!authData?.token?.accessToken && !!authData?.userInfo;
  }, [authData]);

  return (
    <AuthContext.Provider
      value={{ login, logout, register, update, isLogged, authData }}
    >
      {children}
    </AuthContext.Provider>
  );

  async function checkLoginStatus(authData) {
    if (!hasTokenData()) {
      return;
    }
    if (!isTokenValid() && !isRefreshTokenValid(authData)) {
      await logout(authData.token);
    }

    function hasTokenData() {
      return !!authData?.token;
    }
    function isTokenValid() {
      return authData?.token?.expiresOn > unixTimeSeconds();
    }
  }

  function isRefreshTokenValid(authData) {
    return authData?.token?.refreshExpiresOn > unixTimeSeconds();
  }

  function scheduleTokenRefresh() {
    const expiresIn = authData.token.expiresOn - unixTimeSeconds();
    const updateIn = Math.max((expiresIn - 30) * 1000, 0);
    const timer = setTimeout(refreshToken, updateIn);
    return () => clearTimeout(timer);

    async function refreshToken() {
      try {
        const refreshResult = await authRefreshToken(
          authData.token.refreshToken,
        );
        saveTokenData(refreshResult);
      } catch (error) {
        console.warn('AuthProvider: unable to refresh token', error);
        await logout();
      }
    }
  }

  function clearAuthData() {
    updateAuthData(emptyAuthData);
  }

  function saveTokenData({
    accessToken,
    refreshToken,
    expiresIn,
    refreshExpiresIn,
  }) {
    const expiresOn = unixTimeSeconds() + expiresIn;
    const refreshExpiresOn = unixTimeSeconds() + refreshExpiresIn;
    const newAuthData: IAuthData = {
      ...authData,
      token: { accessToken, refreshToken, expiresOn, refreshExpiresOn },
    };
    updateAuthData(newAuthData);
    return newAuthData;
  }

  function updateUserInfo(userInfo: IAuthDataUserInfo) {
    const updatedAuthDataWithUserInfo = {
      ...authData,
      userInfo: { ...authData.userInfo, ...userInfo },
    };
    updateAuthData(updatedAuthDataWithUserInfo);
  }

  function updateAuthData(authData: IAuthData) {
    setAuthData(authData);
    storage.set(authData);
  }

  async function performLogin(email: string, password: string) {
    try {
      return authLogin(email, password);
    } catch (e) {
      handleCommonAuthErrors(`Couldn't login with the user ${email} due to`, e);
    }
  }

  async function performLogout(tokenData = authData.token) {
    try {
      return authLogout(tokenData.accessToken, tokenData.refreshToken);
    } catch (e) {
      handleCommonAuthErrors(`Couldn't logout due to`, e);
    }
  }

  async function performRegister(email: string, password: string) {
    try {
      await createProfile({ email, password });
    } catch (e) {
      handleCommonAuthErrors(
        `Couldn't register the user with ${email} due to`,
        e,
      );
    }
  }

  async function performRetrieveUserInfo() {
    try {
      return getProfile();
    } catch (e) {
      handleCommonAuthErrors(`Couldn't retrieve the user info due to`, e);
    }
  }

  async function performUpdateUserInfo(data) {
    try {
      await updateProfile(data);
    } catch (e) {
      handleCommonAuthErrors(`Couldn't update the user info due to`, e);
    }
  }

  function handleCommonAuthErrors(message: string, e: Error) {
    console.error(message, e);
    // TODO: Maybe we can throw a custom error message for the UI
    // Rethrow the exception to show proper error message in UI
    throw e;
  }
};

const useAuthContext = (): IContextType => useContext(AuthContext);

export { AuthProvider, useAuthContext };
