import PostalCodePrompterModal from 'app/providers/PostalCodePrompterProvider/components/PostalCodePrompterModal/PostalCodePrompterModal';
import Translation from 'app/shared/components/Translation/Translation';
import { Button } from 'app/shared/components/Button/Button';
import { Typography } from 'app/shared/components/Typography/Typography';
import { OutlinedType } from 'app/shared/components/Button/constants';

interface IPostalCodePrompterDecisionModalProps {
  onSaveCanaryIslandsPostalCode: Function;
  onClose: () => void;
}

const PostalCodePrompterDecisionModal = ({
  onSaveCanaryIslandsPostalCode,
  onClose,
}: IPostalCodePrompterDecisionModalProps) => {
  return (
    <PostalCodePrompterModal
      width={455}
      onClose={onClose}
      title={
        <Typography variant="body3">
          <Translation
            id="postalCodePrompter.decision.title"
            defaultMessage="We have noticed that you're searching for a Canary Island's vehicle"
          />
        </Typography>
      }
      content={
        <Typography variant="h6">
          <Translation
            id="postalCodePrompter.decision.content"
            defaultMessage="Would you like to see all the vehicles from Canary Islands?"
          />
        </Typography>
      }
      buttons={
        <>
          <CancelButton onClick={onClose} />
          <ConfirmButton onClick={onSaveCanaryIslandsPostalCode} />
        </>
      }
    />
  );
};

const CancelButton = props => {
  return (
    <Button {...props} variant={OutlinedType} alwaysFullWidth>
      <Translation
        id="postalCodePrompter.decision.cancel"
        defaultMessage="No, thanks"
      />
    </Button>
  );
};

const ConfirmButton = props => {
  return (
    <Button {...props} alwaysFullWidth>
      <Translation
        id="postalCodePrompter.decision.confirm"
        defaultMessage="Yes, show me"
      />
    </Button>
  );
};

export default PostalCodePrompterDecisionModal;
