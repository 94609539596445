import { useRouter } from 'next/router';
import { useCountContext } from 'app/shared/components/CLPPage/providers/CountProvider/CountProvider';
import { Helmet } from 'react-helmet';
import { BASE_URL, FB_CONTENT } from 'next-app/app/config';
import { BASE_ROUTES } from 'app/hooks/useUrl/useUrl';
import { resolvePathParameters } from 'app/utils/path';

const validParams = [
  'make',
  'model',
  'province',
  'bodyType',
  'fuelType',
  'gearType',
];

const exceptions = [
  ['bodyType', 'fuelType'],
  ['bodyType', 'gearType'],
  ['fuelType', 'gearType'],
  ['bodyType', 'fuelType', 'gearType'],
];

const maxNumberOfFilters = 3;

interface IHtmlHeaderMetasProps {
  url?: string;
  path: string;
  index?: string;
  imageUrl?: string;
  locale?: string;
  title: string;
  description: string;
}

const HtmlHeaderMetas = ({
  locale = 'es',
  imageUrl = '/assets/pwa/apple-touch-icon.png',
  url = BASE_URL,
  index = 'noindex,nofollow',
  path,
  title,
  description,
}: IHtmlHeaderMetasProps) => {
  const router = useRouter();

  const { count } = useCountContext();

  const metarobotsIndex = getMetarobotsIndex(path, index, count);
  const metaImage = getImageUrl(url, imageUrl);

  return (
    <Helmet>
      <meta name="description" content={description} />

      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="heycar" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image:url" content={metaImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@heycar_official" />
      <meta name="twitter:creator" content="@heycar_official" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={url} />
      <meta name="facebook-domain-verification" content={FB_CONTENT} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:description" content={description} />

      <meta name="robots" content={metarobotsIndex} />
    </Helmet>
  );

  function getMetarobotsIndex(
    path: string,
    defaultIndex: string,
    numberOfCars: number,
  ): string {
    if (isFromClp(path)) {
      return getClpMetaRobotsIndex(numberOfCars);
    } else if (isFromPdp(path)) {
      return 'noindex,follow';
    } else if (isFromCookieDashboard(path)) {
      return 'index,follow';
    }
    return defaultIndex;
  }

  function getClpMetaRobotsIndex(numberOfCars): string {
    if (numberOfCars !== null && numberOfCars <= 0) {
      return 'noindex,follow';
    }

    const params = resolvePathParameters(router.query, router.asPath);

    const onlyValidParams = Object.keys(params).filter(
      key => params[key] && validParams.includes(key),
    );

    if (onlyValidParams.length > maxNumberOfFilters) {
      return 'noindex,follow';
    }

    if (isException(onlyValidParams)) {
      return 'noindex,follow';
    }

    return 'index,follow';
  }

  function isException(params): boolean {
    return exceptions.some(exception => exception.every(key => !!params[key]));
  }

  function getImageUrl(url: string, imageUrl: string): string {
    const isRelative = imageUrl.startsWith('/');
    return isRelative ? `${url}${imageUrl}` : imageUrl;
  }

  function isFromCookieDashboard(path: string) {
    return path?.startsWith(BASE_ROUTES.cookiedashboard);
  }

  function isFromPdp(path: string) {
    return path?.startsWith(BASE_ROUTES.pdp);
  }

  function isFromClp(path: string) {
    return (
      path?.startsWith(BASE_ROUTES.clp) || path?.startsWith(BASE_ROUTES.km0)
    );
  }
};

export default HtmlHeaderMetas;
