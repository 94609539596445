import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';
import { Image } from 'app/shared/components/Image/Image';
import { Button } from 'app/shared/components/Button/Button';
import { SpanTypography } from 'app/shared/components/Typography/SpanTypography';

export const NotAvailableOverlaySt = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  z-index: 9;
`;

interface IContainerWrapperSt {
  isOneClickListingTile?: boolean;
}

export const ContainerWrapperSt = styled.div<IContainerWrapperSt>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: ${({ isOneClickListingTile }) =>
    isOneClickListingTile ? '0' : '0 1.5rem'};
`;

interface IImageSt {
  isOneClickListingTile?: boolean;
}

export const ImageSt = styled(Image)<IImageSt>`
  max-height: ${({ isOneClickListingTile }) =>
    isOneClickListingTile ? '50px' : '100%'};
  ${media.sm(css<IImageSt>`
    max-height: 100%;
  `)};
`;

interface ITextStProps {
  isOneClickListingTile?: boolean;
}

export const TextSt = styled(SpanTypography).attrs({
  variant: 'body4',
})<ITextStProps>`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral800};
  ${media.sm(css<ITextStProps>`
    padding: ${({ isOneClickListingTile }) =>
      isOneClickListingTile ? 0 : '0.5rem 0'};
  `)};
`;

export const ButtonSt = styled(Button).attrs({ size: 'small' })``;
