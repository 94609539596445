import {
  FAVORITES_AMOUNT,
  useFavorites,
} from 'app/providers/FavoritesProvider/FavoritesProvider';
import MassEmailDealerModal from 'app/shared/components/LeadModals/components/MassEmailDealerModal/MassEmailDealerModal';
import { useEffect, useState } from 'react';
import {
  searchByIdsRespectSort,
  VehicleListingData,
} from 'app/apiCalls/vehicle';
import { getStatus } from 'app/utils/vehicle';

interface IFavoritesOneClickPopupProps {
  onClose: () => void;
}

const FavoritesOneClickModal = ({ onClose }: IFavoritesOneClickPopupProps) => {
  const { oneClickFavoritesIds } = useFavorites();
  const [vehicles, setVehicles] = useState<VehicleListingData[]>(null);

  useEffect(() => {
    searchByIdsRespectSort(oneClickFavoritesIds).then(vehicles => {
      setVehicles(
        vehicles
          .filter(v => getStatus(v).available)
          .slice(0, FAVORITES_AMOUNT.MAX_BATCH_SIZE),
      );
    });
  }, [oneClickFavoritesIds]);

  return (
    <MassEmailDealerModal isVisible onClose={onClose} vehicles={vehicles} />
  );
};

export { FavoritesOneClickModal };
