import { memo } from 'react';
import { BASE_URL } from 'next-app/app/config';
import HtmlHeaderTitle from 'app/shared/components/HtmlHeader/components/HtmlHeaderTitle/HtmlHeaderTitle';
import HtmlHeaderLinks from 'app/shared/components/HtmlHeader/components/HtmlHeaderLinks/HtmlHeaderLinks';
import HtmlHeaderMetas from 'app/shared/components/HtmlHeader/components/HtmlHeaderMetas/HtmlHeaderMetas';
import HtmlHeaderScripts from 'app/shared/components/HtmlHeader/components/HtmlHeaderScripts/HtmlHeaderScripts';

interface IHtmlHeaderComponentProps {
  title?: string;
  description?: string;
  locale?: string;
  imageUrl?: string;
  url?: string;
  path?: string;
  index?: string;
}

const HtmlHeaderComponent = ({
  locale = 'es',
  imageUrl = '/assets/pwa/apple-touch-icon.png',
  url = BASE_URL,
  index = 'noindex,nofollow',
  path,
  title,
  description,
}: IHtmlHeaderComponentProps) => {
  return (
    <>
      <HtmlHeaderTitle title={title} />
      <HtmlHeaderLinks url={url} path={path} />
      <HtmlHeaderMetas
        url={url}
        path={path}
        index={index}
        imageUrl={imageUrl}
        locale={locale}
        title={title}
        description={description}
      />
      <HtmlHeaderScripts />
    </>
  );
};

export const HtmlHeader = memo(HtmlHeaderComponent);
