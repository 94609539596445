import { ReactElement } from 'react';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { SeparatorSt, WrapperSt } from './SimpleOneClickListings.css';
import { LeadSuccessHeader } from './LeadSuccessHeader';
import { ListingsTile } from 'app/shared/components/ContactForm/components/ListingsTile/ListingsTile';
import { PROMISE } from 'app/shared/components/ContactForm/components/ListingsTile/utils';

export interface IListingWithStatus {
  listing: VehicleListingData;
  requested: boolean;
}

interface SimpleOneClickListingsProps {
  vehicles: IListingWithStatus[];
  onRequest: (listing: VehicleListingData) => Promise<VehicleListingData>;
  translations: 'newton' | 'favorites' | '';
  subHeader?: string;
}

const SimpleOneClickListings = ({
  vehicles,
  onRequest,
  translations,
  subHeader,
}: SimpleOneClickListingsProps): ReactElement => {
  return (
    <>
      <SeparatorSt />
      <WrapperSt data-qa="one-click-listings">
        <LeadSuccessHeader translations={translations} subHeader={subHeader} />
        {vehicles.map(({ listing, requested }, index) => {
          return (
            <ListingsTile
              index={index}
              status={requested ? PROMISE.FULFILLED : PROMISE.REJECTED}
              key={listing?.id || index}
              vehicle={listing}
              onRequest={onRequest}
              showOneClickButton
            />
          );
        })}
      </WrapperSt>
    </>
  );
};

export default SimpleOneClickListings;
