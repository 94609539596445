import { existsEcommerceOrder } from 'app/apiCalls/leads';
import {
  D2C_DATASOURCE,
  DEALER_ECOMMERCE_DATASOURCE,
} from 'next-app/app/config';
import { VehicleListingData } from 'app/apiCalls/vehicle';
import { DisplayableError } from 'app/utils/utils';
import Translation from 'app/shared/components/Translation/Translation';

export const useCheckEcommerceOrderExists = async (
  vehicle: VehicleListingData,
  email: string,
  executeRecaptcha: (action?: string) => Promise<string>,
): Promise<void> => {
  const { dataSource, id } = vehicle;
  const isEligible = [DEALER_ECOMMERCE_DATASOURCE, D2C_DATASOURCE].includes(
    dataSource,
  );

  if (!isEligible) {
    // dataSource=TSP shouldn't have ecommerce orders, so we can say that no order exists
    return Promise.resolve();
  }

  const token = await executeRecaptcha('findByListingIdAndEmail');
  const existEcommerceOrder = await existsEcommerceOrder(id, email, token);
  return existEcommerceOrder
    ? Promise.reject(
        new DisplayableError(
          (
            <Translation
              id="lead.ecommerceOrderExists.error"
              defaultMessage="Already contacted"
            />
          ),
        ),
      )
    : Promise.resolve();
};
