import { TextInput } from 'app/shared/components/TextInput/TextInput';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { usePlainTranslate } from 'app/shared/components/Translation/hooks/usePlainTranslate/usePlainTranslate';
import EMAIL_VALIDATION_REGEXP from './EmailInput.validator';
import { useMemo } from 'react';

interface IEmailInputProps {
  name?: string;
  defaultValue?: string;
  rules?: RegisterOptions;
}

const EmailInput = ({
  name = 'email',
  defaultValue = '',
  rules = {},
}: IEmailInputProps) => {
  const plainTranslate = usePlainTranslate();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const rulesInt = {
    ...rules,
    required: {
      value: !!rules.required,
      message: plainTranslate({
        id: 'shared.components.contactForm.email.error.empty',
      }),
    },
    pattern: {
      value: EMAIL_VALIDATION_REGEXP,
      message: plainTranslate({
        id: 'shared.components.contactForm.email.error.invalid',
      }),
    },
  };

  const label = useMemo(
    () =>
      `${plainTranslate({
        id: 'shared.components.contactForm.email',
      })} ${rules.required ? '*' : ''}`,
    [rules],
  );

  return (
    <TextInput
      name={name}
      value={defaultValue}
      qaID={`contactform-input-${name}`}
      label={label}
      error={errors[name]}
      register={register(name, rulesInt)}
    />
  );
};

export default EmailInput;
