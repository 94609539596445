import apiClient from '../utils/apiClient';
import { COMMON_API_URL } from 'next-app/app/config';
import { IFavoriteData } from 'app/providers/FavoritesProvider/FavoritesProvider';

export interface VehicleInfo {
  id: string;
  make: string;
  model: string;
  price: string;
  mileage: string;
  year: string;
  gearBox: string;
}

interface ID2CUser {
  firstName: string;
  lastName: string;
  secondLastName?: string;
  marketingConsent?: boolean;
  contact: {
    email: string;
    phoneNumber: string;
  };
  address: {
    postalCode?: string;
  };
  dateOfBirth?: string;
}

interface IOnlineCreditResponse {
  transactionId: string;
  redirectUrl: string;
}

export interface NewLeadRequest {
  type: string;
  vehicleListingId: string;
  trackingId?: string;
  eventId: string;
  email: string;
  firstName: string;
  lastName: string;
  secondLastName?: string;
  phoneNumber: string; // we're using phoneNumber here because this API requires it
  phone: string;
  leadSource: string;
  lastViewedVehicles: VehicleInfo[];
  favouriteVehicles: IFavoriteData[];
  marketingConsent: boolean;
  optedPrivacyPolicy: boolean;
  financingCalculationId: string;
  interestedInFinancing: boolean;
  leadCreationConsent: boolean;
  message?: string;
  originFlow?: string;
}

export interface INewD2CRequest
  extends Pick<
    NewLeadRequest,
    | 'type'
    | 'leadSource'
    | 'vehicleListingId'
    | 'optedPrivacyPolicy'
    | 'eventId'
  > {
  optedTermsAndConditions: boolean;
  optedGeneralConditions?: boolean;
  displayPrice: number;
  calculationId?: string;
  interestedInTradeIn?: boolean;
  interestedInInsurance?: boolean;
  user: ID2CUser;
  appointmentsWithRangeInHours?: IAppointmentWithRange[];
}

export interface IPhoneLeadResult {
  leadId: string;
  phoneNumber: string;
  displayNumber: string;
}

const apiUrl = COMMON_API_URL;

export const DEALER_PHONE_NUMBER_ERROR =
  "Error generating retrieving dealer's number";

export const SENT_FORM_MESSAGE_ERROR = 'Error sending form message to dealer';
export const SENT_FORM_MESSAGE_SUCCESS = 'Email Sent Successfully';

export const getDealerPhoneNumber = (
  formData: NewLeadRequest,
  recaptchaToken?: string,
): Promise<IPhoneLeadResult> => {
  return apiClient({
    apiUrl,
    path: '/leads/phone-call',
    data: formData,
    headers: {
      'Recaptcha-Token': recaptchaToken,
    },
  }).then(data => {
    if (!data.leadId || !data.phoneNumber || !data.displayNumber) {
      throw new Error(DEALER_PHONE_NUMBER_ERROR);
    }
    return data;
  });
};

export interface IWhatsappLeadResult {
  leadId: string;
  conversation: IWhatsappConversation;
}

export interface IWhatsappConversation {
  conversationId: string;
  link: string;
  qrCode: string;
}

export interface IWhatsappLeadResponse {
  leadId: string;
  whatsAppConversationResponse: IWhatsappConversation;
}

export const createNewWhatsappLeadRequest = (
  formData: NewLeadRequest,
  recaptchaToken?: string,
): Promise<IWhatsappLeadResult> => {
  return createNewLeadRequestWithPath<IWhatsappLeadResponse>(
    {
      ...formData,
      type: 'whatsapp',
    },
    '/leads/freespee/whatsapp',
    recaptchaToken,
  ).then(response => ({
    leadId: response.leadId,
    conversation: response.whatsAppConversationResponse,
  }));
};

export interface ILeadResult {
  leadId: string;
}

interface ILeadResponse {
  customerId: string;
  customerReference: string;
  leadId: string;
}

export const createNewLeadRequest = (
  formData: NewLeadRequest,
  recaptchaToken?: string,
): Promise<ILeadResult> =>
  createNewLeadRequestWithPath<ILeadResponse>(
    formData,
    '/leads/customerEntry',
    recaptchaToken,
  );

const createNewLeadRequestWithPath = <T>(
  formData: NewLeadRequest,
  path: string,
  recaptchaToken?: string,
): Promise<T> =>
  apiClient({
    apiUrl,
    path,
    data: addPhoneAndLeadSource(formData),
    headers: {
      'Recaptcha-Token': recaptchaToken,
    },
  });

export const createNewBatchLeadRequest = (
  formData: NewLeadRequest[],
  recaptchaToken?: string,
): Promise<ILeadResult[]> =>
  createNewBatchLeadRequestWithPath<ILeadResponse[]>(
    formData,
    '/leads/customerEntry/batch',
    recaptchaToken,
  );

const createNewBatchLeadRequestWithPath = <T>(
  formData: NewLeadRequest[],
  path: string,
  recaptchaToken?: string,
): Promise<T> =>
  apiClient({
    apiUrl,
    path,
    data: formData.map(leadRequest => addPhoneAndLeadSource(leadRequest)),
    headers: {
      'Recaptcha-Token': recaptchaToken,
    },
  });

function addPhoneAndLeadSource(leadRequest: NewLeadRequest): NewLeadRequest {
  return {
    ...leadRequest,
    phone: leadRequest.phoneNumber,
    leadSource: 'WEB',
  };
}

interface IOrderResult {
  trackingId: string;
  message: string;
}

export const createNewD2CRequest = (
  formData: INewD2CRequest,
  recaptchaToken?: string,
  interestedInFinancing?: boolean,
): Promise<IOrderResult> =>
  interestedInFinancing
    ? createEcommerceFinancingApplication(formData, recaptchaToken)
    : createEcommerceCashOrder(formData, recaptchaToken);

const createEcommerceFinancingApplication = (
  formData: INewD2CRequest,
  recaptchaToken?: string,
): Promise<IOrderResult> =>
  ecommerceRequest(
    '/ecommerce/financing/applications',
    data => data.financingApplicationId,
    formData,
    recaptchaToken,
  );

export const createEcommerceCashOrder = (
  formData: INewD2CRequest,
  recaptchaToken?: string,
): Promise<IOrderResult> =>
  ecommerceRequest(
    '/ecommerce/cash/order',
    data => data.orderId,
    formData,
    recaptchaToken,
  );

const ecommerceRequest = (path, getTrackingIdFn, data, recaptchaToken) =>
  apiClient({
    apiUrl: COMMON_API_URL,
    path,
    data,
    headers: recaptchaHeader(recaptchaToken),
  }).then(data => ({
    trackingId: getTrackingIdFn(data),
    message: SENT_FORM_MESSAGE_SUCCESS,
  }));

const recaptchaHeader = token => ({ 'Recaptcha-Token': token });

export const createOnlineCreditRequest = (
  financingApplicationId: string,
  recaptchaToken?: string,
): Promise<IOnlineCreditResponse> =>
  apiClient({
    apiUrl: COMMON_API_URL,
    path: `/ecommerce/financing/applications/${financingApplicationId}/sessions`,
    headers: {
      'Recaptcha-Token': recaptchaToken,
    },
  });

export const createNewCallbackLead = (
  formData: NewLeadRequest,
  recaptchaToken?: string,
): Promise<ILeadResult> => {
  return apiClient({
    apiUrl,
    path: '/leads/phone-callback',
    data: formData,
    headers: {
      'Recaptcha-Token': recaptchaToken,
    },
  });
};

export const existsEcommerceOrder = (
  listingId: string,
  email: string,
  recaptchaToken?: string,
): Promise<boolean> =>
  apiClient({
    apiUrl: COMMON_API_URL,
    path: `/ecommerce/orders/findByListingIdAndEmail`,
    method: 'GET',
    params: { listingId, email },
    headers: { 'Recaptcha-Token': recaptchaToken },
  }).then(data => data.length > 0);

export interface IAppointmentWithRange {
  appointmentStartsAt: string;
  rangeInHours: number;
}
