import { sanitize } from 'isomorphic-dompurify';

interface IDangerousElementProps {
  html?: string;
  className?: string;
}

const DangerousElement = ({ html = '', className }: IDangerousElementProps) => {
  const sanitizedHtml = sanitize(html, { USE_PROFILES: { html: true } });
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: sanitizedHtml,
      }}
    />
  );
};

export { DangerousElement };
