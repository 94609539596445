import styled, { css } from 'styled-components';
import { GutterDialogContentSt } from 'app/shared/components/Modal/Modal.css';
import { media } from 'app/styles/variables';

export const SeparatorSt = styled.div`
  height: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral50};
  flex-shrink: 0;
`;

export const WrapperSt = styled(GutterDialogContentSt)`
  margin-bottom: 1.5rem;
  ${media.sm(css`
    max-width: 554px;
  `)};
`;

export const HeaderSt = styled.div`
  margin: 1rem 0 0.5rem;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
`;

export const SubheaderSt = styled.div`
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
`;
