import styled, { css } from 'styled-components';
import { breakpoints, media } from 'app/styles/variables';

export const PageTemplateSt = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
`;

export const generatePageContainer = (isMobileFullWidth = false) => css`
  width: 100%;
  max-width: ${breakpoints.xxl}px;

  ${isMobileFullWidth ? '' : 'padding: 0 1rem;'}

  ${media.sm(css`
    padding: 0 1.5rem;
  `)};

  ${media.lg(
    css`
      margin-left: auto;
      margin-right: auto;
      padding: 0 4rem;
    `,
  )};
`;
