import Translation from 'app/shared/components/Translation/Translation';

const DefaultRegisterHeaderSubTitle = () => {
  return (
    <Translation
      id="auth.loginForm.registerSubtitle"
      defaultMessage="Welcome to heycar! Create an user account in less than 30 seconds."
    />
  );
};

export default DefaultRegisterHeaderSubTitle;
