import { useAuthContext } from 'app/shared/components/PageWrapper/providers/AuthProvider/AuthProvider';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useMemo } from 'react';
import { BASE_ROUTES } from 'app/hooks/useUrl/useUrl';
import {
  AuthView,
  LoginRegisterModal,
} from 'app/shared/components/LoginRegisterModal/LoginRegisterModal';
import { ContentWrapperSt } from './Content.css';
import { ContentLoading } from './components/ContentLoading/ContentLoading';

interface IContentProps {
  children?: ReactNode;
  noPageWrapper?: boolean;
  withAuth?: boolean;
}

const Content = ({
  children,
  noPageWrapper,
  withAuth = false,
}: IContentProps) => {
  const router = useRouter();

  const { isLogged } = useAuthContext();

  const isLoginVisible = useMemo(
    () => (withAuth ? !isLogged() : false),
    [withAuth, isLogged],
  );

  const onClose = useCallback(() => {
    return router.push(BASE_ROUTES.homepage);
  }, [router]);

  if (isLoginVisible) {
    return (
      <ContentWrapperSt data-testid="login">
        <ContentLoading />
        <LoginRegisterModal onClose={onClose} initialView={AuthView.LOGIN} />
      </ContentWrapperSt>
    );
  }

  if (!noPageWrapper) {
    return <ContentWrapperSt>{children}</ContentWrapperSt>;
  }

  return <>{children}</>;
};

export { Content };
