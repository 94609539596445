import { ReactNode, memo, ReactElement } from 'react';
import { PageProvider } from 'app/shared/components/PageWrapper/providers/PageProvider/PageProvider';
import { TrackingPageProvider } from 'app/shared/components/PageWrapper/providers/TrackingPageProvider/TrackingPageProvider';
import { FavoritesProvider } from 'app/providers/FavoritesProvider/FavoritesProvider';
import { ContactedVehiclesProvider } from 'app/providers/ContactedVehiclesProvider/ContactedVehiclesProvider';
import { PostalCodePrompterProvider } from 'app/providers/PostalCodePrompterProvider/PostalCodePrompterProvider';
import { PageTemplate } from 'app/shared/components/PageWrapper/components/PageTemplate/PageTemplate';
import { AuthProvider } from 'app/shared/components/PageWrapper/providers/AuthProvider/AuthProvider';

interface IPageWrapperProps {
  children?: ReactNode;
  hasFooter?: boolean;
  isLoading?: boolean;
  isMobileFullWidth?: boolean;
  noPageWrapper?: boolean;
  url?: string;
  preFooterComponents?: ReactElement;
  pageId?: string;
  withAuth?: boolean;
}

const PageWrapper = ({ pageId, children, ...props }: IPageWrapperProps) => {
  const pageTemplate = <PageTemplate {...props}>{children}</PageTemplate>;

  if (!pageId) {
    return pageTemplate;
  }

  return (
    <PageProvider defaultPageId={pageId}>
      <AuthProvider>
        <TrackingPageProvider>
          <ContactedVehiclesProvider>
            <FavoritesProvider>
              <PostalCodePrompterProvider>
                {pageTemplate}
              </PostalCodePrompterProvider>
            </FavoritesProvider>
          </ContactedVehiclesProvider>
        </TrackingPageProvider>
      </AuthProvider>
    </PageProvider>
  );
};

export default memo(PageWrapper);
