import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';

export const WrapperSt = styled.div`
  text-align: center;
`;

export const ParagraphSt = styled.p`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.75rem;

  ${media.sm(css`
    max-width: 414px;
  `)};
`;
