import { useRouter } from 'next/router';
import { useCountContext } from 'app/shared/components/CLPPage/providers/CountProvider/CountProvider';
import { Helmet } from 'react-helmet';
import { BASE_ROUTES } from 'app/hooks/useUrl/useUrl';
import { BASE_URL } from 'next-app/app/config';

interface hrefLangAlternate {
  hrefLang: locales;
  href: string;
}

const deDeLocale = 'de-de';
const enGbLocale = 'en-gb';
const esEsLocale = 'es-es';
const frFrLocale = 'fr-fr';

type locales = typeof deDeLocale | typeof enGbLocale | typeof esEsLocale | typeof frFrLocale;

const defaultHrefLangPerPath: Record<string, hrefLangAlternate[]> = {
  [BASE_ROUTES.homepage]: [
    {
      hrefLang: esEsLocale,
      href: 'https://heycar.com/es',
    },
    {
      hrefLang: deDeLocale,
      href: 'https://hey.car',
    },
    {
      hrefLang: enGbLocale,
      href: 'https://heycar.com/uk',
    },
    {
      hrefLang: frFrLocale,
      href: 'https://heycar.com/fr',
    },
  ],
  [BASE_ROUTES.clp]: [
    {
      hrefLang: esEsLocale,
      href: 'https://heycar.com/coches-segunda-mano',
    },
    {
      hrefLang: deDeLocale,
      href: 'https://hey.car/gebrauchtwagen',
    },
    {
      hrefLang: enGbLocale,
      href: 'https://heycar.com/uk/search',
    },
  ],
};

const appleTouchIconPrefix = '/assets/pwa/apple-touch-icon';
const appleTouchIconSizes = [
  '57x57',
  '60x60',
  '72x72',
  '76x76',
  '114x114',
  '120x120',
  '128x128',
  '144x144',
  '152x152',
  '180x180',
];

const bannedCanonicalPathItems = ['compra-online'];

interface IHtmlHeaderLinksProps {
  url?: string;
  path: string;
}

const HtmlHeaderLinks = ({ url = BASE_URL, path }: IHtmlHeaderLinksProps) => {
  const router = useRouter();
  const { count } = useCountContext();

  const fullCanonicalPath = getCanonicalFullPath(url, path, count);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={fullCanonicalPath} />
        <link rel="apple-touch-icon" href={`${appleTouchIconPrefix}.png`} />
        {appleTouchIconSizes.map(sizes => (
          <link
            key={sizes}
            rel="apple-touch-icon"
            href={`${appleTouchIconPrefix}-${sizes}.png`}
            sizes={sizes}
          />
        ))}
        <link
          rel="apple-touch-icon"
          href={`${appleTouchIconPrefix}-precomposed.png`}
        />
      </Helmet>
      <HtmlHeaderHrefLangLinks path={path} />
    </>
  );

  function getCanonicalFullPath(
    url: string,
    path: string,
    numberOfCars,
  ): string {
    const finalPath = isFromClp(path)
      ? getClpCanonicalPathWithoutParams(path, numberOfCars)
      : path;
    return [url, finalPath].filter(item => item).join('');
  }

  function getClpCanonicalPathWithoutParams(
    path: string,
    numberOfCars,
  ): string {
    const paths = ((router.query?.path as []) || []).filter(
      p => !bannedCanonicalPathItems.includes(p),
    );
    if (numberOfCars !== null && numberOfCars <= 0 && paths?.length >= 1) {
      const newPath = (paths || []).slice(0, paths.length - 1);
      return [path, ...newPath].join('/');
    }
    return [path, ...(paths || [])].join('/');
  }

  function isFromClp(path: string) {
    return (
      path?.startsWith(BASE_ROUTES.clp) || path?.startsWith(BASE_ROUTES.km0)
    );
  }
};

interface IHtmlHeaderHrefLangLinksProps {
  path: string;
}

const HtmlHeaderHrefLangLinks = ({ path }: IHtmlHeaderHrefLangLinksProps) => {
  const router = useRouter();

  if (isFromClp(path) && (hasQueryParams() || hasPathParams())) {
    return null;
  }

  const selectedHrefLang = defaultHrefLangPerPath[path];

  if (!selectedHrefLang?.length) {
    return null;
  }

  return (
    <Helmet>
      {selectedHrefLang.map(item => (
        <link
          key={`${item.href}_${item.hrefLang}`}
          rel="alternate"
          href={item.href}
          hrefLang={item.hrefLang}
        />
      ))}
    </Helmet>
  );

  function isFromClp(path: string) {
    return (
      path?.startsWith(BASE_ROUTES.clp) || path?.startsWith(BASE_ROUTES.km0)
    );
  }

  function hasQueryParams() {
    return router.asPath?.indexOf('?') >= 0;
  }

  function hasPathParams() {
    return router.query.path && router.query.path.length > 0;
  }
};

export default HtmlHeaderLinks;
