import apiClient, { APIParams } from './apiClient';
import { wrapLocalStorage } from 'app/utils/localStorage';

interface IAuthAPIParams extends APIParams {
  bearer?: boolean;
}

export default function authClient(params: IAuthAPIParams) {
  const storage = wrapLocalStorage<any>('persist:auth');
  const authData = storage.get();
  const headers = { ...params.headers, ...getAuthHeader() };
  return apiClient({
    ...params,
    headers,
  }).catch(err => {
    if (err.status === 401) {
      // TODO: Use the refresh token to get another access token from keycloak
      // TODO: once retrieved the access token, store the token and call again authClient with the same params
      // TODO: Maybe put a max retries mechanism to avoid infinite looping.
      // return authClient(props);
      return;
    }
    // Rethrow the error in other case
    throw err;
  });

  function getAuthHeader() {
    let authHeaders = {};
    if (authData?.token.accessToken) {
      if (params.bearer) {
        authHeaders['X-Heycar-Bearer'] = authData?.token.accessToken;
      } else {
        authHeaders['Authorization'] = `Bearer ${authData?.token.accessToken}`;
      }
    }
    return authHeaders;
  }
}
