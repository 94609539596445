import styled, { css } from 'styled-components';
import { media } from 'app/styles/variables';
import { Button } from 'app/shared/components/Button/Button';
import { Location } from '@heycar-uikit/icons';
import { Typography } from 'app/shared/components/Typography/Typography';

interface IisOnlineReservationTileProps {
  isOnlineReservationTile?: boolean;
  isFromOnlineReservationForm?: boolean;
  showMonthlyInstalmentRate?: boolean;
  width?: number;
}

export const OneClickListingsTileSt = styled.div<IisOnlineReservationTileProps>`
  display: flex;
  padding: ${({ isOnlineReservationTile }) =>
    !isOnlineReservationTile && '0.5rem 0'};
  position: relative;
  margin: ${({ isFromOnlineReservationForm, isOnlineReservationTile }) =>
    isFromOnlineReservationForm && isOnlineReservationTile ? '1rem 0 0 0' : ''};
  align-items: ${({ isOnlineReservationTile }) =>
    isOnlineReservationTile && 'stretch'};
`;

export const ImageSt = styled.div<IisOnlineReservationTileProps>`
  position: ${({ isOnlineReservationTile }) =>
    isOnlineReservationTile && 'relative'};
  width: ${({ width }) => width}px;
  display: ${({ isOnlineReservationTile }) =>
    isOnlineReservationTile && 'flex'};
  align-items: ${({ isOnlineReservationTile }) =>
    isOnlineReservationTile && 'start'};
  flex-shrink: ${({ isOnlineReservationTile }) =>
    isOnlineReservationTile && '0'};
`;

export const InfoContainerSt = styled.div<IisOnlineReservationTileProps>`
  margin: ${({ isFromOnlineReservationForm }) =>
    isFromOnlineReservationForm ? '0 0.5rem' : '0 0 0 1rem'};
  width: ${({ isOnlineReservationTile }) =>
    !isOnlineReservationTile && `calc(100% - 6.375rem)`};
  min-width: ${({ isOnlineReservationTile }) => isOnlineReservationTile && `0`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: ${({ isOnlineReservationTile }) => isOnlineReservationTile && `1`}; ;
`;

export const NameSt = styled(Typography).attrs({
  variant: 'caption2',
  Component: 'div',
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BrandBold = styled.b`
  color: ${({ theme }) => theme.colors.primary500};
`;

export const PriceSt = styled(Typography).attrs({
  variant: 'body4',
  Component: 'div',
})``;

export const MonthlyInstalmentRateSt = styled.div`
  display: inline-flex;
`;

export const PriceSuffixSt = styled(Typography).attrs({
  variant: 'caption5',
  Component: 'span',
})`
  color: ${({ theme }) => theme.colors.neutral500};
`;

export const OneClickButtonContainerSt = styled.div`
  position: static;
  grid-column: 2;
  ${media.md(css`
    right: 2.5rem;
    top: 50%;
    transform: translate(0, -50%);
    margin-top: 0px;
  `)};
`;

export const RequestedButtonSt = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.secondary};
  width: 6.875rem;
  font-weight: bold;
  font-size: 0.75rem;
  ${media.md(css`
    text-align: center;
  `)};
`;

export const RequestButtonSt = styled(Button)`
  margin-top: 5px;
`;

export const OneClickAdditionalInfoSt = styled.div<IisOnlineReservationTileProps>`
  display: ${({ isOnlineReservationTile, showMonthlyInstalmentRate }) =>
    isOnlineReservationTile && !showMonthlyInstalmentRate && 'grid'};
  margin-top: ${({ isOnlineReservationTile }) =>
    isOnlineReservationTile && '0.25rem'};
  grid-template-columns: ${({ isOnlineReservationTile }) =>
    isOnlineReservationTile ? '1fr 1fr' : `repeat(2, calc(100% / 2))`};
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.125rem;
  font-size: 0.75rem;

  & > :nth-child(6) {
    line-height: 1.375rem;

    & > div {
      font-size: 0.75rem;
    }
  }
  ${media.md(css`
    grid-template-columns: repeat(2, calc(100% / 3));
  `)};
`;

export const ShortAddressSt = styled.div`
  display: flex;
  align-items: center;
  margin-left: -4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AdditionalInfoItemSt = styled(Typography).attrs({
  variant: 'caption2',
  Component: 'div',
})`
  display: flex;
  padding-left: 0.2rem;
`;

export const PriceWrapperSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LocationSt = styled(Location)`
  margin-right: 0.25rem;
`;
