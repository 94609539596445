import { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import { useRouter } from 'next/router';
import {
  updateQueryParams,
  toggleListItemSelection,
  isFilterListVisible,
  QueryParamsMap,
} from './utils';
import { getFilterList } from 'app/hooks/filters/utils';
import { resolvePathParameters } from 'app/utils/path';

export const FILTER_LIST = [];

interface IUseFilterListResult {
  filterList: string[];
  setFilterList: (
    listData: string | string[],
    queryParamsToAdd?: QueryParamsMap,
    queryParamsToRemove?: string[],
  ) => void;
  clearFilterList: (queryParamsToRemove?: string[]) => void;
  isFilterListVisible: boolean;
}

export const useFilterList = (
  filterName: string,
  isOptimistic = false,
  keysToRemove?: string[],
  canonicalPosition?: string,
  toItem?: (urlItem: string) => string,
  toUrlItem?: (item: string) => string,
  fromUrl?: (item: string | string[]) => string | string[],
): IUseFilterListResult => {
  const router = useRouter();
  let filterList = getFilterList(router, filterName, FILTER_LIST);

  // Check if the filter can have a canonical position, if so, extract it
  if (canonicalPosition) {
    const pathParameters = resolvePathParameters(router.query, router.asPath);

    if (pathParameters && pathParameters[canonicalPosition]) {
      filterList = [...filterList, pathParameters[canonicalPosition]];
    }
  }

  // Some filters needs to be mapped internal after extracting from the url
  if (toItem) {
    filterList = filterList.map(toItem);
  }

  // We store the filterListItems for 'optimistic response' for the CLP SidebarFilters
  const [filterListItems, setFilterListItems] = useState(filterList);
  const filterListToUse = isOptimistic ? filterListItems : filterList;

  // We have to synchronise the filterListItems with the query params, so that clearing
  // in the topbar will also clear the items in the sidebar
  const [prevList, setPrevList] = useState([]);
  useEffect(() => {
    if (isOptimistic && !isEqual(prevList, filterList)) {
      setFilterListItems(filterList);
      setPrevList(filterList);
    }
  }, [filterList, prevList]);

  const clearFilterList = useCallback(
    (queryParamsToRemove: string[]) => {
      setFilterListItems([]);
      updateQueryParams(router, {}, [
        filterName,
        ...(queryParamsToRemove || []),
      ]);
    },
    [router, router.pathname, router.query],
  );

  const setFilterList = useCallback(
    (
      listData: string | string[],
      queryParamsToAdd?: QueryParamsMap,
      queryParamsToRemove?: string[],
    ) => {
      const mappedListData = fromUrl ? fromUrl(listData) : listData;
      const updatedFilterList = Array.isArray(listData)
        ? listData.filter((item, index, self) => self.indexOf(item) === index) // Makes sure there are no duplicate values in array
        : toggleListItemSelection(mappedListData as string, filterListToUse);

      setFilterListItems(updatedFilterList);
      if (!isFilterListVisible(updatedFilterList)) {
        clearFilterList(queryParamsToRemove);
      } else {
        const newQueryParams: QueryParamsMap = {
          ...(queryParamsToAdd || {}),
          [filterName]: toUrlItem
            ? updatedFilterList.map(toUrlItem)
            : updatedFilterList,
        };
        updateQueryParams(router, newQueryParams, [
          ...(keysToRemove || []),
          ...(queryParamsToRemove || []),
        ]);
      }
    },
    [router.query, router.pathname, filterListToUse, filterList],
  );

  return {
    filterList: filterListToUse,
    setFilterList,
    clearFilterList,
    isFilterListVisible: isFilterListVisible(filterListToUse),
  };
};
