import apiClient from 'app/utils/apiClient';
import { IAM_URL } from 'next-app/app/config';

const removedHeaders = { 'X-Heycar-Tenant': null, 'X-Tracking-Guid': null };

export function authLogin(email: string, password: string) {
  return requestToken(
    buildURLSearchParams({
      grant_type: 'password',
      client_id: 'web-app',
      username: email,
      password,
    }),
  );
}

export function authRefreshToken(refreshToken: string) {
  return requestToken(
    buildURLSearchParams({
      grant_type: 'refresh_token',
      client_id: 'web-app',
      refresh_token: refreshToken,
    }),
  );
}

export async function authLogout(
  userAccessToken: string,
  refreshToken: string,
) {
  return apiClient({
    apiUrl: IAM_URL,
    path: '/auth/realms/heycar-es/protocol/openid-connect/logout',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${userAccessToken}`,
      ...removedHeaders,
    },
    data: buildURLSearchParams({
      grant_type: 'refresh_token',
      client_id: 'web-app',
      refresh_token: refreshToken,
    }),
  });
}

interface ITokenData {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  refreshExpiresIn: number;
}

function requestToken(data): Promise<ITokenData> {
  return apiClient({
    apiUrl: IAM_URL,
    path: '/auth/realms/heycar-es/protocol/openid-connect/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...removedHeaders,
    },
    data,
  }).then(data => ({
    accessToken: data?.access_token,
    refreshToken: data?.refresh_token,
    expiresIn: data?.expires_in,
    refreshExpiresIn: data?.refresh_expires_in,
  }));
}

function buildURLSearchParams(data): string {
  return new URLSearchParams(data).toString();
}
