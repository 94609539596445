import { useState, ReactElement } from 'react';
import get from 'lodash.get';
import { generateResponsiveUrl } from 'app/utils/imageProxy';
import { Image } from 'app/shared/components/Image/Image';
import {
  ImageSt,
  InfoContainerSt,
  NameSt,
  OneClickListingsTileSt,
  PriceSt,
  PriceSuffixSt,
  OneClickButtonContainerSt,
  RequestButtonSt,
  RequestedButtonSt,
  MonthlyInstalmentRateSt,
  OneClickAdditionalInfoSt,
  BrandBold,
  AdditionalInfoItemSt,
  PriceWrapperSt,
} from './ListingsTile.css';
import { FormattedNumber } from 'react-intl';
import Translation from 'app/shared/components/Translation/Translation';
import { MonthlyRateLink } from 'app/shared/components/MonthlyRateLink/MonthlyRateLink';
import NotAvailableOverlay from 'app/shared/components/NotAvailableOverlay/NotAvailableOverlay';
import { useServices } from 'app/providers/ServicesProvider';
import { D2C_DATASOURCE } from 'next-app/app/config';
import { VEHICLE_STATE } from 'app/apiCalls/vehicle';
import { getAdditionalInfo } from './additional-info-util';
import { IListingsTile, PROMISE } from './utils';

const ListingsTile = ({
  index,
  vehicle,
  status,
  onRequest,
  monthlyRate,
  isFromOnlineReservationForm = false,
  showMonthlyInstalmentRate,
  showOneClickButton,
  showAdditionalInfo,
  isOnlineReservationTile = false,
  imageWidth = 86,
}: IListingsTile): ReactElement => {
  const { state, dataSource, booked } = vehicle;
  const isD2C = dataSource && dataSource === D2C_DATASOURCE;
  const disabled = state !== VEHICLE_STATE.PUBLISHED;

  const [requested, setRequested] = useState(false);

  const handleOneClick = async () => {
    setRequested(true);
    onRequest(vehicle).catch(e => {
      setRequested(false);
      throw e;
    });
  };

  const imageUrl = get(vehicle, 'images[0].url', '');
  const { src: imageSrc, srcSet: imageSrcSet } = generateResponsiveUrl(
    imageUrl,
    86,
    undefined,
    {
      quality: 80,
    },
  );

  const additionalInfo = getAdditionalInfo(
    vehicle,
    showAdditionalInfo,
    isD2C,
    isOnlineReservationTile,
  );

  const monthlyRateState =
    monthlyRate ||
    get(vehicle, 'financingPrecalc.calculationResult.monthlyRate', {});
  const { interestedInFinancing } = useServices();
  const requestId = get(vehicle, 'financingPrecalc.requestId', '');

  const monthlyRateContent = (
    <MonthlyRateLink
      vehicle={vehicle}
      value={monthlyRateState}
      href={`${vehicle.seoUrl}#financing-calculator`}
    />
  );

  return (
    <OneClickListingsTileSt
      isFromOnlineReservationForm
      isOnlineReservationTile={isOnlineReservationTile}
    >
      {(disabled || booked) && (
        <NotAvailableOverlay vehicle={vehicle} isOneClickListingTile />
      )}
      <ImageSt
        width={imageWidth}
        isOnlineReservationTile={isOnlineReservationTile}
      >
        <Image
          lazy
          rootMargin={`${imageWidth}px`}
          src={imageSrc}
          srcSet={imageSrcSet}
        />
      </ImageSt>
      <InfoContainerSt>
        <NameSt>
          <b>
            {isOnlineReservationTile ? (
              <BrandBold>
                {vehicle.make.displayName} {vehicle.model.displayName}
              </BrandBold>
            ) : (
              <>
                {vehicle.make.displayName} {vehicle.model.displayName}
              </>
            )}
          </b>{' '}
          {vehicle.model.source}
        </NameSt>
        <OneClickAdditionalInfoSt
          isOnlineReservationTile={isOnlineReservationTile}
          showMonthlyInstalmentRate={showMonthlyInstalmentRate}
        >
          {additionalInfo.map((item, index) => (
            <AdditionalInfoItemSt key={index}>{item}</AdditionalInfoItemSt>
          ))}
          <PriceWrapperSt>
            <PriceSt>
              {vehicle.price ? (
                <FormattedNumber value={vehicle.price} useGrouping />
              ) : null}{' '}
              €{' '}
              <PriceSuffixSt>
                <Translation id={'shared.components.vehicle.inclVat'} />
              </PriceSuffixSt>
            </PriceSt>
            {showMonthlyInstalmentRate && (
              <MonthlyInstalmentRateSt>
                {isFromOnlineReservationForm
                  ? isD2C && requestId && interestedInFinancing
                    ? monthlyRateContent
                    : null
                  : monthlyRateContent}
              </MonthlyInstalmentRateSt>
            )}

            {showOneClickButton && (
              <OneClickButtonContainerSt>
                {status === PROMISE.FULFILLED || requested ? (
                  <RequestedButtonSt
                    data-qa={`one-click-listing-requested-btn-${index}`}
                    data-testid={`one-click-listing-requested-btn-${index}`}
                  >
                    <Translation id="shared.components.oneClickListings.requested" />
                  </RequestedButtonSt>
                ) : (
                  <RequestButtonSt
                    size="small"
                    qaID={`one-click-listing-request-btn-${index}`}
                    onClick={handleOneClick}
                  >
                    <Translation id="shared.components.oneClickListings.request" />
                  </RequestButtonSt>
                )}
              </OneClickButtonContainerSt>
            )}
          </PriceWrapperSt>
        </OneClickAdditionalInfoSt>
      </InfoContainerSt>
    </OneClickListingsTileSt>
  );
};

export { ListingsTile };
